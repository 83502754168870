<template>
    <fieldset>
        <div class="wrap_line_heading">
        <div class="data_prev_cont">
            <div class="row">
            <div class="col-md-12">
                <table class="table table-sm  table-bordered">
                <thead>
                    <tr>
                    <td width="250" class="text-center">
                        <img :src="isParent.$parent.assetLocal('global_assets/images/logo-rsh-mark.png')"
                        style="max-width: 100%; width:50px; height:50px;" alt="" />
                    </td>
                    <td>
                        <h3 class="mb-0 text-center font-weight-semibold">
                        Pemberian Informasi Edukasi Pasien dan Keluarga Terintegrasi
                        </h3>
                    </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <th colspan="2" class="bg-primary">PERSIAPAN EDUKASI</th>
                    </tr>
                    <tr>
                    <td width="250">Bahasa <strong class="text-danger">*</strong></td>
                    <td>
                        <div class="row">
                            <div class="col-md-7">
                                <b-form-group>
                                    <b-form-checkbox-group
                                        v-model="isParent.row.aranres_bahasa"
                                        :options="Config.mr.statusBahasaRanap"
                                        name="bahasa"
                                    ></b-form-checkbox-group>
                                </b-form-group>
                            </div>
                            <div class="col-md-4" v-if="(isParent.row.aranres_bahasa||[]).indexOf('Lainnya') != -1">
                                <b-form-input v-model="isParent.row.aranres_bahasa_lainnya" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="Bahasa Lainnya" />
                                <VValidate 
                                    name="Bahasa Lainnya" 
                                    v-model="isParent.row.aranres_bahasa_lainnya" 
                                    :rules="toValidate(isParent.mrValidation.aranres_bahasa_lainnya)"
                                />
                            </div>
                        </div>
                    

                    </td>
                    </tr>
                    <tr>
                    <td>Kebutuhan Penerjemah <strong class="text-danger">*</strong></td>
                    <td>
                        <b-form-radio-group
                            :options="Config.mr.yesNoOptV2"
                            v-model="isParent.row.aranres_keb_penerjemah"
                        />
                        <VValidate 
                            name="Kebutuhan Penerjemah" 
                            v-model="isParent.row.aranres_keb_penerjemah" 
                            :rules="toValidate(isParent.mrValidation.aranres_keb_penerjemah)"
                        />
                    </td>   
                    </tr>
                    <tr>
                    <td>Baca dan Tulis <strong class="text-danger">*</strong></td>
                    <td>
                        <b-form-radio-group
                            :options="Config.mr.statusBacaTulisRanap"
                            v-model="isParent.row.aranres_baca_tulis"
                        />
                        <VValidate 
                            name="Baca dan Tulis" 
                            v-model="isParent.row.aranres_baca_tulis" 
                            :rules="toValidate(isParent.mrValidation.aranres_baca_tulis)"
                        />
                    </td>
                    </tr>

                    <tr>
                    <td>Pilih Tipe Pembelajaran <strong class="text-danger">*</strong></td>
                    <td>
                        <b-form-group>
                            <b-form-checkbox-group
                                v-model="isParent.row.aranres_tipe_pembelajaran"
                                :options="Config.mr.statusTipePembelajaran"
                                name="tipe-pembelajaran"
                            ></b-form-checkbox-group>
                        </b-form-group>
                    </td>
                    </tr>

                    <tr>
                    <td>Pendidikan <strong class="text-danger">*</strong></td>
                    <td class="d-flex">
                        <b-form-radio-group
                            class="custom-block"
                            :options="isParent.mPendidikan"
                            v-model="isParent.row.aranres_pendidikan"
                        />
                        <div v-if="isParent.row.aranres_pendidikan == 99999">
                            <b-form-input v-model="isParent.row.aranres_pendidikan_lainnya" type="text" name="pendidikan" id="pendidikan" class="form-control" placeholder="Pendidikan Lainnya" />
                            <VValidate 
                                name="Pendidikan Lainnya" 
                                v-model="isParent.row.aranres_pendidikan_lainnya" 
                                :rules="toValidate(isParent.mrValidation.aranres_pendidikan_lainnya)"
                            />
                        </div>
                        
                        <VValidate 
                            name="Pendidikan" 
                            v-model="isParent.row.aranres_pendidikan" 
                            :rules="toValidate(isParent.mrValidation.aranres_pendidikan)"
                        />
                    </td>
                    </tr>
                    <tr>
                    <td>Agama <strong class="text-danger">*</strong></td>
                    <td class="d-flex">
                        <b-form-radio-group
                            class="custom-block"
                            :options="isParent.mAgama"
                            v-model="isParent.row.aranres_agama"
                        />
                        <div v-if="isParent.row.aranres_agama == 99999">
                            <b-form-input v-model="isParent.row.aranres_agama_lainnya" type="text" name="agama" id="agama" class="form-control" placeholder="Agama Lainnya" />
                            
                            <VValidate 
                                name="Agama Lainnya" 
                                v-model="isParent.row.aranres_agama_lainnya" 
                                :rules="toValidate(isParent.mrValidation.aranres_agama_lainnya)"
                            />
                        </div>
                        
                        <VValidate 
                            name="Agama" 
                            v-model="isParent.row.aranres_agama" 
                            :rules="toValidate(isParent.mrValidation.aranres_agama)"
                        />
                    </td>
                    </tr>

                    <tr>
                    <td>Keyakinan dan Budaya</td>
                    <td class="d-flex">
                        <b-form-checkbox
                            v-model="isParent.row.aranres_dis_makanan"
                            name="keyakinan"
                            value="Y"
                            unchecked-value="N"
                        > Makanan yang dipantang...
                        </b-form-checkbox>
                        <div class="" v-if="isParent.row.aranres_dis_makanan == 'Y'">
                            <b-form-input type="text" name="Makanan" id="Makanan" class="form-control ml-2" v-model="isParent.row.aranres_dis_makanan_lainnya" />
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td>Hambatan Edukasi</td>
                    <td>
                       <b-form-group>
                            <b-form-checkbox-group
                                class="checkbox-block"
                                v-model="isParent.row.aranres_hambatan_edukasi"
                                :options="Config.mr.hambatanEdukasi"
                                name="hambatan-edukasi"
                            ></b-form-checkbox-group>
                        </b-form-group>
                        <template v-if="(isParent.row.aranres_hambatan_edukasi||[]).indexOf('Pertanyaan Tambahan') != - 1">
                            <b-form-textarea v-model="isParent.row.aranres_hambatan_edukasi_text" id="keluhan" placeholder="e.g. nyeri di paha kanan, ngilu di bagian lutut" rows="4" />
                        </template>

                        
                    </td>
                    </tr>

                    <tr>
                    <td>Ketersediaan Menerima Edukasi<strong class="text-danger">*</strong></td>
                    <td>
                        <b-form-radio-group
                            :options="Config.mr.reservasiKetersediaan"
                            v-model="isParent.row.aranres_ketersediaan_edukasi"
                        />

                        <VValidate 
                            name="Ketersediaan Edukasi" 
                            v-model="isParent.row.aranres_ketersediaan_edukasi" 
                            :rules="toValidate(isParent.mrValidation.aranres_ketersediaan_edukasi)"
                        />
                    </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                    <th colspan="2" class="bg-primary">PEMBERIAN EDUKASI</th>
                    </tr>

                    <template v-if="isParent.row.aranres_ketersediaan_edukasi == 'Y'">                    
                    <tr>
                    <td colspan="2">
                        <div class="row g-2">
                        <div class="col-md-3">
                            <div>
                            <label for="">Tanggal Edukasi<strong class="text-danger">*</strong></label>
                            <div class="input-group mb-3">
                                <datepicker input-class="form-control transparent"
                                placeholder="Pilih Tanggal" class="my-datepicker"
                                calendar-class="my-datepicker_calendar" v-model="isParent.row.aranres_tanggal_edukasi">
                                </datepicker>
                                <div class="input-group-append calendar-group">
                                <span class="input-group-text" id="basic-addon2"><i
                                    class="icon-calendar"></i></span>
                                </div>
                            </div>

                            <VValidate 
                                name="Tanggal Edukasi" 
                                v-model="isParent.row.aranres_tanggal_edukasi" 
                                :rules="toValidate(isParent.mrValidation.aranres_tanggal_edukasi)"
                            />

                            </div>
                        </div>
                        <div class="col-md-3">
                            <div>
                            <label for="">Durasi Waktu<strong class="text-danger">*</strong></label>
                            <div class="input-group">
                                <b-form-input type="text" class="form-control" v-model="isParent.row.aranres_durasi_waktu" :formatter="isParent.$parent.numberOnly" />
                                <div class="input-group-append"><span class="input-group-text">menit</span></div>
                            </div>
                            
                            <VValidate 
                                name="Durasi Waktu" 
                                v-model="isParent.row.aranres_durasi_waktu" 
                                :rules="toValidate(isParent.mrValidation.aranres_durasi_waktu)"
                            />
                            </div>
                        </div>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colspan="2">
                        <div class="row g-2">
                        <div class="col-md-3">
                            <div>
                            <label for="">Materi Edukasi <strong class="text-danger">*</strong></label>
                            <b-form-checkbox-group
                                class="checkbox-block"
                                v-model="isParent.row.aranres_materi_edukasi"
                                :options="Config.mr.materiEdukasi"
                            ></b-form-checkbox-group>
                            <VValidate 
                                name="Materi Edukasi" 
                                v-model="isParent.row.aranres_materi_edukasi" 
                                :rules="toValidate(isParent.mrValidation.aranres_materi_edukasi)"
                            />
                            </div>

                            <div class="mt-2" v-if="(isParent.row.aranres_materi_edukasi||[]).indexOf('Pertanyaan Tambahan') != -1">
                                <b-form-textarea type="text" class="form-control" v-model="isParent.row.aranres_materi_edukasi_text" placeholder="Materi Edukasi"/>
                                <VValidate 
                                    name="Pertanyaan Tambahan" 
                                    v-model="isParent.row.aranres_materi_edukasi_text" 
                                    :rules="toValidate(isParent.mrValidation.aranres_materi_edukasi_text)"
                                />

                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group">
                                <label for="">TIngkat Pemahaman Awal</label>
                                <b-form-radio-group
                                    :options="Config.mr.resRanapTingkatPemahaman"
                                    v-model="isParent.row.aranres_tingkat_pemahaman_awal"
                                />
                            </div>
                            <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                <label for="">Metode Edukasi <strong class="text-danger">*</strong></label>
                                <div>
                                    <b-form-checkbox-group
                                        class="checkbox-block"
                                        v-model="isParent.row.aranres_metode_edukasi"
                                        :options="Config.mr.resRanapMetodeEdukasi"
                                    ></b-form-checkbox-group>
                                    <VValidate 
                                        name="Metode Edukasi" 
                                        v-model="isParent.row.aranres_metode_edukasi" 
                                        :rules="toValidate(isParent.mrValidation.aranres_metode_edukasi)"
                                    />
                                </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                <label for="">Sarana Edukasi <strong class="text-danger">*</strong></label>
                                <div>
                                    <b-form-checkbox-group
                                        class="checkbox-block"
                                        v-model="isParent.row.aranres_sarana_edukasi"
                                        :options="Config.mr.resRanapSaranaEdukasi"
                                    ></b-form-checkbox-group>
                                    <VValidate 
                                        name="Sarana Edukasi" 
                                        v-model="isParent.row.aranres_sarana_edukasi" 
                                        :rules="toValidate(isParent.mrValidation.aranres_sarana_edukasi)"
                                    />
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                            <label for="">Evaluasi <strong class="text-danger">*</strong></label>
                            <b-form-checkbox-group
                                class="checkbox-block"
                                v-model="isParent.row.aranres_evaluasi"
                                :options="Config.mr.resRanapSaranaEdukator"
                            ></b-form-checkbox-group>
                            <VValidate 
                                name="Evaluasi" 
                                v-model="isParent.row.aranres_evaluasi" 
                                :rules="toValidate(isParent.mrValidation.aranres_evaluasi)"
                            />
                            </div>
                            <div class="form-group" v-if="(isParent.row.aranres_evaluasi||[]).indexOf('Re-edukasi') != -1">
                            <label for="">Tanggal Re-Edukasi<strong class="text-danger">*</strong></label>
                            
                            <div class="input-group mb-3">
                                <datepicker input-class="form-control transparent"
                                placeholder="Pilih Tanggal" class="my-datepicker"
                                calendar-class="my-datepicker_calendar" v-model="isParent.row.aranres_tanggal_re_edukasi">
                                </datepicker>
                                <div class="input-group-append calendar-group">
                                <span class="input-group-text" id="basic-addon2"><i
                                    class="icon-calendar"></i></span>
                                </div>
                            </div>

                            <VValidate 
                                name="Tanggal Edukasi" 
                                v-model="isParent.row.aranres_tanggal_re_edukasi" 
                                :rules="toValidate(isParent.mrValidation.aranres_tanggal_re_edukasi)"
                            />

                            </div>
                        </div>
                        </div>
                    </td>
                    </tr>
                    </template>

                    <tr>
                    <td colspan="2">
                        <div class="row g-2">
                        <div class="col-md-4">
                            <div>
                            <label for="">Sasaran (Pasien/Keluarga/Lain-lain) <strong class="text-danger">*</strong></label>
                            <b-form-input type="text" class="form-control" v-model="isParent.row.aranres_sasaran" placeholder="Nama Sasaran"/>

                            <div class="signing-element mt-1" data-target-id="ttdSasaran">
                                <VueSignaturePad
                                    class="sign-container"
                                    width="100%"
                                    height="160px"
                                    ref="ttdSasaran"
                                />
                                <div class="mt-2">
                                <button type="button" @click="undoTTD('ttdSasaran','aranres_sasaran_ttd')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                                    <b><i class="icon-cross3"></i></b>
                                    <span>Clear</span>
                                </button>
                                
                                <button type="button" @click="output('ttdSasaran','aranres_sasaran_ttd')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                                    <b><i class="icon-checkmark2"></i></b>
                                <span>Simpan</span>
                                </button>
                                </div>
                            </div>
                            
                            <VValidate 
                                name="Sasaran TTD" 
                                v-model="isParent.row.aranres_sasaran_ttd" 
                                :rules="toValidate(isParent.mrValidation.aranres_sasaran_ttd)"
                            />

                            <small class="form-text text-secondary">Isi nama dan bubuhkan TTD Digital</small>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div>
                            <label for="">Edukator <strong class="text-danger">*</strong></label>
                            <b-form-input type="text" class="form-control" v-model="isParent.row.aranres_edukator" placeholder="Edukator"/>
                            <VValidate 
                                name="Edukator" 
                                v-model="isParent.row.aranres_edukator" 
                                :rules="toValidate(isParent.mrValidation.aranres_edukator)"
                            />

                            <div class="signing-element mt-1" data-target-id="ttdEdukator">
                                <VueSignaturePad
                                    class="sign-container"
                                    width="100%"
                                    height="160px"
                                    ref="ttdEdukator"
                                />
                                <div class="mt-2">
                                <button type="button" @click="undoTTD('ttdEdukator','aranres_edukator_ttd')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                                    <b><i class="icon-cross3"></i></b>
                                    <span>Clear</span>
                                </button>
                                
                                <button type="button" @click="output('ttdEdukator','aranres_edukator_ttd')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                                    <b><i class="icon-checkmark2"></i></b>
                                <span>Simpan</span>
                                </button>
                                </div>
                            </div>

                            <VValidate 
                                name="Edukator TTD" 
                                v-model="isParent.row.aranres_edukator_ttd" 
                                :rules="toValidate(isParent.mrValidation.aranres_edukator_ttd)"
                            />
                            <small class="form-text text-secondary">Isi nama dan bubuhkan TTD Digital</small>
                            </div>
                        </div>
                        </div>
                    </td>
                    </tr>

                </tbody>
                </table>
            </div>
            </div>
        </div>
        </div>
    </fieldset>
</template>

<script>
import Config from '@/libs/Config'
import Gen from '@/libs/Gen.js'
const _ = global._
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'


export default {
    components: {
      Datepicker
    },
    computed: {    
        Config(){ return Config },
        isParent(){
            return this.$parent.$parent.$parent.$parent
        },
        disabledDates(){
            return {
                to: new Date(moment().format('YYYY-MM-DD'))
            }
        },
    }, 
    mounted() {
        this.isParent.row.aranres_pendidikan = this.isParent.row.aranres_pendidikan||this.isParent.row.ap_pendidikan
        this.isParent.row.aranres_agama = this.isParent.row.aranres_agama||this.isParent.row.ap_agama
        this.isParent.row.aranres_agama_lainnya = this.isParent.row.aranres_agama_lainnya||this.isParent.row.ap_agama_text

        setTimeout(()=>{
            if(this.$refs['ttdSasaran']){
                this.$refs['ttdSasaran'].fromDataURL(this.isParent.row.aranres_sasaran_ttd)
            }
            if(this.$refs['ttdEdukator']){
                this.$refs['ttdEdukator'].fromDataURL(this.isParent.row.aranres_edukator_ttd)
            }
            this.isParent.row.aranres_edukator = this.isParent.$parent.user.fullName
        },1500)
    },
    methods: {
        toValidate(val){
            return {...val}
        },
        getConfigDynamic(master, value, val = 'text') {
            let text = ''
            if (value) {
                let index = (master||[]).findIndex(x => x.value == value)
                if (index !== -1) {
                text = master[index][val]
                }
            }
            return text
        },
        
        undoTTD(refs,field){
            this.$refs[refs].clearSignature()
            this.isParent.row[field] = null
            this.isParent.autoSave(this.isParent.row)
        },
        output(refs,field) {
          let { isEmpty, data } = this.$refs[refs].saveSignature()
          if(!isEmpty){
            this.isParent.row[field] = data
            this.isParent.autoSave(this.isParent.row)
          }
        },
    }
}

</script>

