<template>
    <div class="content">
        <b-form @submit.prevent="handleSubmit(doSubmit())">
        <div class="card">
            <div class="card-header bg-white" style="border-bottom: 1px solid rgba(0,0,0,.125);">
                <h6 class="card-title font-weight-semibold">Reservasi Pasien</h6>
            </div>
            <b-card-body>
                <VStepTab :step.sync="stepTab" :last="Steps.length" :config="stepValidate">
                    <template slot="link">
                        <template v-for="(v,k) in Steps">
                            <VStepLink :step.sync="stepTab" :option="k + 1" :key="k" :clickable="(k+1) < stepTab">
                                {{ v.subtitle }}
                            </VStepLink>
                        </template>
                    </template>
                    <template slot="content">
                        <template v-for="(v,k) in Steps">
                            <VStepContent :step.sync="stepTab" :option="k+1" :key="k">
                                <template slot="title">
                                    {{ v.subtitle }}
                                </template>
                                <template slot="fieldset">
                                    <div>
                                        <validation-observer
                                            ref="VFormCaraBayar"
                                        >
                                            <CaraBayar v-if="stepTab == 1" />
                                        </validation-observer>
                                        <validation-observer
                                            ref="VFormDataRanap"
                                        >
                                            <DataRanap v-if="stepTab == 2" />
                                        </validation-observer>
                                        <validation-observer
                                            ref="VFormEdukasiPasien"
                                        >
                                            <EdukasiPasien v-if="stepTab == 3" />
                                        </validation-observer>
                                        
                                        <validation-observer
                                            ref="VFormPersetujuanPasien"
                                        >
                                            <PersetujuanPasien v-if="stepTab == 4" />
                                        </validation-observer>

                                        <validation-observer
                                            ref="VFormDokumenPersetujuan"
                                        >
                                            <DokumenPersetujuan v-if="stepTab == 5" />
                                        </validation-observer>
                                        
                                    </div>
                                </template>
                            </VStepContent>
                        </template>
                    </template>
                    <template slot="action">
                        <ul role="menu" aria-label="Pagination">
                            
                            <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>

                            <li :class="(stepTab==1?'disabled':'')" v-bind:aria-disabled="(stepTab==1?true:false)" @click="doPrev"><a href="javascript:" :class="'btn btn-light '+(stepTab==1?'disabled':'')" role="menuitem"><i class="icon-arrow-left13 mr-2"></i> Previous</a></li>

                            <li aria-hidden="false" aria-disabled="false" @click="doNext"><a href="javascript:" :class="'btn btn-primary '+(stepTab!=stepLast?'':'disabled')" role="menuitem">Next <i class="icon-arrow-right14 ml-2"></i></a></li>
                            
                            <li v-if="stepTab == stepLast" aria-hidden="false" aria-disabled="false"><button @click="doSubmit()"  type="button" class="btn btn-primary" role="menuitem">Submit<i class="icon-checkmark3 ml-2"></i></button></li>
                        </ul>
                    </template>
                </VStepTab> 
            </b-card-body>
        </div>
        </b-form>       
    </div>
</template>

<script>
import $ from 'jquery'
const _ = global._

import VStepTab from '@/components/VStep/VStepTab.vue'
import VStepLink from '@/components/VStep/VStepLink.vue'
import VStepContent from '@/components/VStep/VStepContent.vue'

import CaraBayar from './CaraBayar.vue'
import DataRanap from './DataRanap.vue'
import EdukasiPasien from './EdukasiPasien.vue'
import PersetujuanPasien from './PersetujuanPasien.vue'
import DokumenPersetujuan from './DokumenPersetujuan.vue'


import Gen from '@/libs/Gen.js'

export default{
    props:{
        row:Object,
        mrValidation:Object,
        rowPasien:Object,
        listRujukan: Array,
        isShowBangsal: Boolean,
        infoBangsal: Array,
        katalogBangsal: Array,
    },
    components: {
      VStepTab, VStepLink, VStepContent, CaraBayar, DataRanap, EdukasiPasien, PersetujuanPasien, DokumenPersetujuan
    },
    computed: {
        stepValidate(){
            return {
                disabled:false,
                validate:true,
                refs:this.$children,
            }
        },
    },
    data(){
        return {
            stepTab: 1,
            stepLast: 5,
            Steps: [
                {
                    icon: 'create',
                    name: 'cara-bayar',
                    subtitle: 'Cara Bayar',
                    component: CaraBayar,
                    completed: false
                },
                {
                    icon: 'create',
                    name: 'data-ranap',
                    subtitle: 'Data Rawat Inap',
                    component: DataRanap,
                    completed: false
                },
                {
                    icon: 'create',
                    name: 'edukasi-pasien',
                    subtitle: 'Edukasi Pasien',
                    component: EdukasiPasien,
                    completed: false
                },
                
                {
                    icon: 'create',
                    name: 'persetujuan-pasien',
                    subtitle: 'Persetujuan Pasien',
                    component: PersetujuanPasien,
                    completed: false
                },

                {
                    icon: 'create',
                    name: 'dokumen-persetujuan',
                    subtitle: 'Dokumen Pasien',
                    component: DokumenPersetujuan,
                    completed: false
                },

                
            ],
            
            mPasien: [],
            mAsuransi: [],
            mDPJPUtama: [],
            mDPJPRaber: [],
            mJenisRanap: [],
            mJenisRanapNormal: [
                {
                    "text": "Obsgyn",
                    "value": 1
                },
                {
                    "text": "Umum",
                    "value": 3
                },
                {
                    "text": "Perawatan Kritis",
                    "value": 4
                }
            ],
            mUnitLayan: [],
            mRanapBangsal: [],
            mPendidikan: [],
            mPekerjaan: [],
            mAgama: [],
            isBPJS: true,
        }
    },
    methods: {
        back(){
            this.$router.back()
        },
        doPrev(){
            if(this.stepTab !== 1){
                this.stepTab = this.stepTab -1
                this.row.aranres_last_step = this.stepTab
                this.autoSave(this.row)
                window.scrollTo(0,0)
            }
        },
        doNext(){
            if(this.stepTab < this.stepLast){
                if(this.stepTab == 1){
                    if((this.row.apbd_status == 'MENINGGAL'|| this.row.apbd_status == 'KELUAR KEMAUAN SENDIRI'|| this.row.apbd_status == 'TIDAK DITANGGUNG' || this.row.apbd_status == 'PENANGGUHAN PESERTA' || this.row.apbd_status == 'NON AKTIF DIAKHIR BULAN' || this.row.apbd_status == 'PENANGGUHAN PEMBAYARAN' || this.row.apbd_status == 'NON AKTIF KARNA DATA GANDA' || this.row.apbd_status == 'USIA ANAK PPU > 21 TH DAN 25 TH' || this.row.apbd_status == 'NON AKTIF KARNA PREMI') && this.row.aranres_jaminan == 3){
                        return this.$swal({
                            icon: 'error',
                            title: 'Mohon maaf Anda tidak bisa melanjutkan pendaftaran, karena status peserta BPJS Anda ' + this.row.apbd_status, 
                            text : 'Silakan konfirmasi ke cabang bpjs terkedat.'
                        })
                    }

                    if(!this.isBPJS && this.row.aranres_jaminan == 3){
                        return this.$swal({
                            icon: 'error',
                            title: 'Tidak Bisa Lanjut', 
                            text : 'Data BPJS Tidak Ditemukan'
                        })
                    }
                    this.$refs['VFormCaraBayar'][0].validate().then(success=>{
                        if(success){
                            this.stepTab = this.stepTab + 1
                            this.row.aranres_last_step = this.stepTab
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                this.$parent.autoScrollValidate()
                            },500)
                        }
                    })
                }
                else if(this.stepTab == 2){
                    if(!this.row.aranres_bed){
                        return this.$swal({
                            icon: 'error',
                            title: 'Tidak Bisa Lanjut', 
                            text : 'Belum ada Kamar yang Dipilih'
                        })
                    }
                    this.$refs['VFormDataRanap'][0].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab + 1
                            this.row.aranres_last_step = this.stepTab        
                            this.autoSave(this.row)
                            // this.cekPasienNaikTurun(this.row)
                        }else{
                            setTimeout(()=>{
                                this.$parent.autoScrollValidate()                                
                            },500)
                        }
                    })
                }else if(this.stepTab == 3){

                    if(this.row.aranres_ketersediaan_edukasi == 'Y' && !this.row.aranres_sasaran){
                        return this.$swal({
                            icon: 'error',
                            title: 'Tidak Bisa Lanjut', 
                            text : 'Tanda Tangan Belum Diisi'
                        })
                    }
                    
                    if(this.row.aranres_ketersediaan_edukasi == 'Y' && !this.row.aranres_edukator_ttd){
                        return this.$swal({
                            icon: 'error',
                            title: 'Tidak Bisa Lanjut', 
                            text : 'Tanda Tangan Belum Diisi'
                        })
                    }

                    if(!(this.row.aranres_bahasa||[]).length){
                        return this.$swal({
                            icon: 'error',
                            title: 'Tidak Bisa Lanjut', 
                            text : 'Minimal Pilih Satu Bahasa'
                        })
                    }

                    if(!(this.row.aranres_tipe_pembelajaran||[]).length){
                        return this.$swal({
                            icon: 'error',
                            title: 'Tidak Bisa Lanjut', 
                            text : 'Minimal Pilih Satu Tipe Pembelajaran'
                        })
                    }
                                        
                    this.$refs['VFormEdukasiPasien'][0].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab + 1
                            this.row.aranres_last_step = this.stepTab                
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                this.$parent.autoScrollValidate()
                            },500)
                        }
                    })
                }else if(this.stepTab == 4){

                    this.$refs['VFormPersetujuanPasien'][0].validate().then(success => {
                        if(success){
                            if((!this.row.aranres_general_consent_pj_nama_petugas || !this.row.aranres_general_consent_pj_nama)){
                                return this.$swal({
                                    icon: 'error',
                                    title: 'Tidak Bisa Lanjut', 
                                    text : 'Tanda Tangan Persetujuan Umum Belum Diisi'
                                })
                            }

                            if(this.row.aranres_ttd == 'Digital' && (!this.row.aranres_general_consent_ttd_petugas ||!this.row.aranres_general_consent_ttd_pembuat_pernyataan)){
                                return this.$swal({
                                    icon: 'error',
                                    title: 'Tidak Bisa Lanjut', 
                                    text : 'Tanda Tangan Persetujuan Umum Belum Diisi'
                                })
                            }
                             
                            
                            if(this.row.aranres_pd_ttd == 'Digital' &&(!this.row.aranres_pd_nama_petugas || !this.row.aranres_pd_nama_pembuat_pernyataan)){
                                return this.$swal({
                                    icon: 'error',
                                    title: 'Tidak Bisa Lanjut', 
                                    text : 'Tanda Tangan Pasien Rawat Inap Belum Diisi'
                                })
                            }
                            
                            if(this.row.aranres_pd_ttd == 'Digital' && (!this.row.aranres_pd_ttd_petugas ||!this.row.aranres_pd_ttd_pembuat_pernyataan)){
                                return this.$swal({
                                    icon: 'error',
                                    title: 'Tidak Bisa Lanjut', 
                                    text : 'Tanda Tangan Pasien Rawat Inap Belum Diisi'
                                })
                            }

                            this.stepTab = this.stepTab + 1
                            this.row.aranres_last_step = this.stepTab                
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }

                                let PR = ['Nama','No. KTP / NIK','No. Telp Penandatangan','Pekerjaan Pasien','Isi Pekerjaan Lainnya','Alamat']
                                
                                if((inv||[]).length){                      
                                    for(let i = 0; i < (inv||[]).length; i++){
                                        if(PR.indexOf(inv[i]) != -1){
                                            this.row.aranres_tab_pp = 'PR'
                                            if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                                            return
                                        }else{
                                            this.row.aranres_tab_pp = 'PU'
                                            if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                                            return
                                        }
                                    }
                                }  
                            },500)
                        }
                    })
                }else if(this.stepTab == 5){
                    this.$refs['VFormDokumenPersetujuan'][0].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab + 1
                            this.row.aranres_last_step = this.stepTab                
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                this.$parent.autoScrollValidate()
                            },500)
                        }
                    })
                }else{
                    console.log("no action")
                }
                window.scrollTo(0,0)
            }
        },
        doSubmit(){
            let inval = 0
            this.$refs['VFormCaraBayar'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 1
                    inval = inval + 1
                    this.row.aranres_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        this.$parent.autoScrollValidate()
                    },500)
                }
            })
            this.$refs['VFormDataRanap'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 2
                    inval = inval + 1
                    this.row.aranres_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        this.$parent.autoScrollValidate()
                    },500)
                    
                }
            })
            this.$refs['VFormEdukasiPasien'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 3
                    inval = inval + 1
                    this.row.aranres_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        this.$parent.autoScrollValidate()
                    },500)
                }
            })
            
            this.$refs['VFormPersetujuanPasien'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 4
                    inval = inval + 1
                    this.row.aranres_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        this.$parent.autoScrollValidate()
                    },500)
                }
            })
            
            this.$refs['VFormDokumenPersetujuan'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 5
                    inval = inval + 1
                    this.row.aranres_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        this.$parent.autoScrollValidate()
                    },500)
                }
            })

            if(this.row.aranres_pj_is_ttd == 'Digital' && !this.row.aranres_pj_ttd){
                return this.$swal({
                    icon: 'error',
                    title: 'Tanda Tangan Pernyataan Pasien Belum Diisi'
                })
            }
            
            setTimeout(()=>{
                if(inval){
                    return this.$swal({
                        icon: 'error',
                        title: 'Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }
                
                if(!inval){
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan menyimpan data ini?',
                        text: "Pastikan Data Yang Telah Diinput Telah Sesuai",
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(isOK => {
                        if(isOK.value){
                            // this.autoSave(this.row,true)
                            let data = this.row
                            data.type = 'submit-data'
                            this.$parent.loadingOverlay = true
                            Gen.apiRest(
                                "/do/"+'RanapReservasi',
                                {data:data}, 
                                "POST"
                            ).then(res=>{
                                this.$socket.emit('refresh_data_bed', {})
                                this.$parent.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                }).then(result => {
                                    if (result.value) {
                                        this.$router.push(
                                            { 
                                                name: 'RanapReservasi', 
                                                params: {pageSlug: this.$route.params.pageSlug},
                                                query: {isDetail: true}
                                            })
                                        .catch(()=>{})
                                    }
                                })
                            }).catch(err=>{
                                this.$parent.loadingOverlay = false
                                if(err){
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message

                                    if(err.title == "Terjadi gangguan koneksi pada server saat mengirim data"){
                                        this.$router.push({name : 'RanapReservasi'}).catch(()=>{})
                                    }
                                }
                                this.$parent.doSetAlertForm(err)
                            
                            })
                        }
                    })
                }
            },100)

            
        },

        cekPasienNaikTurun: _.debounce(function (data,isSave=false) {
            if(data.aranres_jaminan == 3){
                data.type = 'cek-naik-turun'
                Gen.apiRest(
                    "/do/"+this.$parent.modulePage,
                    {data:data}, 
                    "POST"
                ).then(res=>{
                    let resp = res.data
                    this.row.aranres_is_naik  = resp.isNaikKelas
                })
            }

        },1000),
        
        autoSave: _.debounce(function (data,isSave=false) {
            data.type = 'auto-save'
            if(data.aranres_general_consent_pj_nama){
                this.row.aranres_pj_nama = this.row.aranres_pj_nama||data.aranres_general_consent_pj_nama
            }
            if(data.aranres_general_consent_pj_no_telp){
                this.row.aranres_pj_telp = this.row.aranres_pj_telp||data.aranres_general_consent_pj_no_telp
            }
            if(data.aranres_general_consent_pj_alamat){
                this.row.aranres_pj_alamat = this.row.aranres_pj_alamat||data.aranres_general_consent_pj_alamat
            }
            if(!this.row.aranres_pd_nama_petugas){
                this.row.aranres_pd_nama_petugas = this.row.aranres_pd_nama_petugas||this.$parent.user.fullName
            }
            if(!this.row.aranres_general_consent_pj_nama_petugas){
                this.row.aranres_general_consent_pj_nama_petugas = this.row.aranres_general_consent_pj_nama_petugas||this.$parent.user.fullName
            }
            if(!this.row.aranres_pd_nama){
                this.row.aranres_pd_nama = this.row.aranres_pd_nama||this.row.aranres_general_consent_pj_nama
            }

            if(!this.row.aranres_kron_nama){
                this.row.aranres_kron_nama = this.row.aranres_kron_nama||this.row.aranres_pd_nama
            }
            if(!this.row.aranres_kron_no_telp){
                this.row.aranres_kron_no_telp = this.row.aranres_kron_no_telp||this.row.aranres_pd_no_telp
            }
            if(!this.row.aranres_kron_alamat){
                this.row.aranres_kron_alamat = this.row.aranres_kron_alamat||this.row.aranres_pd_alamat
            }


            

            if(!data.aranr_reg_code || isSave){
                data.aranres_last_step = this.row.aranres_last_step
                Gen.apiRest(
                    "/do/"+this.$parent.modulePage,
                    {data:data}, 
                    "POST"
                ).then(res=>{
                    let resp = res.data
                    this.row.aranres_general_consent_temp = resp.row.aranres_general_consent_temp
                    this.row.aranres_pd_temp = resp.row.aranres_pd_temp
                    this.row.aranres_pj_template = resp.row.aranres_pj_template

                    this.row.aranres_bm_temp = resp.row.aranres_bm_temp
                    this.row.aranres_kn_temp = resp.row.aranres_kn_temp
                    this.row.aranres_kron_temp = resp.row.aranres_kron_temp

                })
            }
        },1000), 
        apiGetMaster(){
            let data = {
                poli_id : this.row.aranres_poli,
            }
            Gen.apiRest(
                "/get/"+this.$parent.modulePage+'/master', 
                {
                    params: data
                }
            ).then(res=>{
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
            })
        },   
    },
    mounted() {
        setTimeout(()=>{
            this.apiGetMaster()
            this.stepTab = this.row.aranres_last_step||1
        },1500)
    },
    watch: {
        row: {
            handler(v) {
                this.autoSave(v)
                this.cekPasienNaikTurun(v)
            },
            deep: true
        },
        'row.aranres_general_consent_pj_no_telp'(v){
            if(v){
                console.log(v)
                this.row.aranres_pd_no_telp = this.row.aranres_general_consent_pj_no_telp
            }
        },
        'row.aranres_general_consent_pj_alamat'(v){
            if(v){
                console.log(v)
                this.row.aranres_pd_alamat = this.row.aranres_general_consent_pj_alamat
            }
        }
    }
}
</script>