<template>
    <div>
        
        <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
            <li class="nav-item"><a href="javascript:;" @click="changeTab('PU')"
                  :class="isParent.row.aranres_tab_pp == 'PU' ?'nav-link active' : 'nav-link'">Persetujuan Umum</a></li>
            <li class="nav-item"><a href="javascript:;" @click="changeTab('PR')"
                  :class="isParent.row.aranres_tab_pp == 'PR' ?'nav-link active' : 'nav-link'">Persetujuan Pasien Rawat Inap</a></li>
        </ul>
        <div class="tab-content">
            <div :class="isParent.row.aranres_tab_pp == 'PU'?'tab-pane fade show active':'tab-pane fade'">
                <div class="wrap_line">
                    <h3>Persetujuan Umum (General Consent)</h3>
                    <div class="form-group">
                    <label for="">Persetujuan dilakukan oleh <strong class="text-danger">*</strong></label>
                    <div>
                        <b-form-radio-group :options="Config.mr.statusPersetujuanPasien" v-model="isParent.row.aranres_persetujuan_umum" />
                        <VValidate name="Persetujuan Umum" v-model="isParent.row.aranres_persetujuan_umum"
                          :rules="toValidate(isParent.mrValidation.aranres_persetujuan_umum)" />
                    </div>
                    </div>
                    <div class="agreement_content">
                    <p>Selaku pasien RS Harapan Magelang dengan ini menyatakan persetujuan:</p>
                    <h2>1. PERSETUJUAN UNTUK PERAWATAN DAN PENGOBATAN</h2>
                    <ol type="a">
                        <li>Saya menyetujui untuk perawatan di Rumah Sakit Harapan Magelang sebagai pasien rawat jalan
                        atau rawat inap tergantung kepada kebutuhan medis. Pengobatan dapat meliputi: X-Ray/Radiologi,
                        Electrocardiogram, Pemberian dan atau tindakan medis pemasangan infus serta penyuntikan
                        (intramuskular, intravena, dan prosedur invasif lainnya), Perawatan rutin, Pemberian produk
                        farmasi dan obat-obatan, Pemasangan alat kesehatan (kecuali yang membutuhkan persetujuan
                        khusus/tertulis), Pengambilan darah untuk pemeriksaan laboratorium atau patologi yang
                        dibutuhkan
                        untuk pengobatan dan evaluasi (contohnya wawancara dan pemeriksaan fisik)</li>
                        <li>Saya sadar bahwa praktik kedokteran dan bedah bukanlah ilmu pasti dan saya mengakui bahwa
                        tidak ada jaminan atas hasil apapun, terhadap perawatan prosedur atau pemeriksaan apapun yang
                        dilakukan pada saya.</li>
                        <li>
                        Saya mengerti dan memahami bahwa:
                        <ol type="i">
                            <li>Saya memiliki hak untuk mengajukan pertanyaan tentang pengobatan yang diusulkan
                            (termasuk
                            identitas setiap orang yang memberikan atau mengamati pengobatan) setiap saat.</li>
                            <li>Saya mengetahui bahwa persetujuan yang saya berikan tidak termasuk persetujuan untuk
                            prosedur / tindakan invasif (misalnya operasi) atau tindakan yang mempunyai resiko tinggi
                            </li>
                            <li>Saya mengetahui bahwa jika saya memutuskan untuk menghentikan perawatan medis untuk diri
                            saya sendiri, saya memahami bahwa Rumah Sakit Harapan dan Dokter tidak akan
                            bertanggungjawab
                            atas hasil yang merugikan saya.</li>
                        </ol>
                        </li>
                    </ol>

                    <h2>2. BARANG PRIBADI</h2>
                    <p>Saya tidak diperkenankan untuk membawa barang-barang berharga ke ruang rawat inap, jika ada
                        anggota keluarga atau teman harus diminta untuk membawa pulang barang berharga tersebut.</p>
                    <p>Saya telah memahami bahwa rumah sakit tidak bertanggungjawab atas semua kehilangan
                        barang-barang
                        milik saya dan saya secara pribadi bertanggungjawab atas barang-barang berharga yang saya
                        miliki,
                        termasuk uang, perhiasan buku cek, kartu kredit, handphone atau barang lainnya. Dan apabila saa
                        membutuhkan maka saya dapat menitipkan barang pada tempat penitipan barang di tempat resmi yang
                        telah disediakan Rumah Sakit.</p>
                    <p>Saya juga mengerti bahwa saya harus memberitahu / menitipkan pada RS jika memiliki gigi palsu,
                        kacamata, lensa kontak, prosthetic atau barang lainnya yang saya butuhkan untuk diamankan.</p>

                    <h2>3. PERSETUJUAN PELEPASAN INFORMASI</h2>
                    <p>Saya memahami informasi yang ada didalam diri saya, termasuk diagnosis, hasil laboratorium, dan
                        hasil tes diagnostik yang akan digunakan untuk perawatan medis, Rumah Sakit Harapan akan
                        menjamin
                        kerahasiaannya.</p>
                    <p>Saya memberi wewenang kepada Rumah Sakit untuk memberikan informasi tentang diagnosis, hasil
                        pelayanan dan pengobatan bila diperlukan untuk memproses klaim asuransi / Jamkesmas / Jamkesda /
                        perusahaan dan atau lembaga pemerintah.</p>
                    <p>Saya memberi wewenang kepada Rumah Sakit untuk memberikan informasi tentang diagnosis, hasil
                        pelayanan dan pengobatan saya kepada anggota keluarga saya kepada:</p>
                    <table class="table table-sm table-bordered">
                        <thead>
                          <tr>
                            <th width="24">#</th>
                            <th>Nama Penerima Informasi</th>
                            <th>Hubungan Dengan Pasien</th>
                            <th>Nomor Telepon</th>
                            <th>Aksi</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(v,k) in (isParent.row.aranres_pelepasan_informasi||[]).length" :key="k">
                            <td>{{k+1}}</td>

                            <td>
                              <b-form-input v-model="isParent.row.aranres_pelepasan_informasi[k]['nama']" :formatter="isParent.$parent.normalText"
                                type="text" name="bahasa" id="bahasa" class="form-control"
                                placeholder="Nama Penerima informasi" />

                              <VValidate :name="'Nama Penerima informasi '+(k+1)"
                                v-model="isParent.row.aranres_pelepasan_informasi[k]['nama']" :rules="{required : 1}" />
                            </td>

                            <td>
                              <b-form-input v-model="isParent.row.aranres_pelepasan_informasi[k]['hub']" :formatter="isParent.$parent.normalText"
                                type="text" name="bahasa" id="bahasa" class="form-control"
                                placeholder="Hubungan Penerima informasi" />

                              <VValidate :name="'Hubungan Penerima informasi '+(k+1)"
                                v-model="isParent.row.aranres_pelepasan_informasi[k]['hub']" :rules="{required : 1}" />
                            </td>

                            <td>
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text" id="basic-addon2">+62</span>
                                </div>
                                <b-form-input v-model="isParent.row.aranres_pelepasan_informasi[k]['tel']" :formatter="isParent.$parent.number"
                                  type="text" name="bahasa" id="bahasa" class="form-control" placeholder="No Telepon" />
                              </div>
                              <VValidate :name="'No Telepon Penerima informasi '+(k+1)"
                                v-model="isParent.row.aranres_pelepasan_informasi[k]['tel']" :rules="{required : 1}" />
                            </td>

                            <td>
                              <a href="javascript:;" @click="(isParent.row.aranres_pelepasan_informasi||[]).splice(k,1)"
                                class="btn btn-sm btn-icon border-danger alpha-danger text-danger rounded-circle"
                                data-popup="tooltip" title="Hapus">
                                <i class="icon-trash-alt"></i>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colspan="5" class="text-center">
                              <a href="javascript:;" @click="addPenerimaInfo()"
                                class="btn btn-info btn-sm btn-labeled btn-labeled-left">
                                <b><i class="icon-plus2"></i></b>
                                <span>Tambah</span>
                              </a>
                            </td>
                          </tr>
                        </tfoot>
                    </table>

                    <h2>4. HAK DAN KEWAJIBAN PASIEN</h2>
                    <p>Saya memiliki hak untuk mengambil bagian dalam keputusan mengenani penyakit saya dan dalam hal
                        perawatan medis dan rencana pengobatan.</p>
                    <p>Saya telah mendapat informasi tentang hak dan kewajiban pasien di Rumah Sakit Harapan Magelang
                        melalui formulir dan banner yang disediakan oleh petugas.</p>
                    <table class="table table-bordered">
                        <thead>
                        <tr>
                            <th width="50%"><span class="text-uppercase">Hak - hak Pasien</span></th>
                            <th width="50%"><span class="text-uppercase">Kewajiban Pasien</span></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td class="align-top">
                            <ol class="pl-3">
                                <li>Memperoleh informasi mengenai tata tertib dan peraturan yang berlaku di rumah sakit
                                </li>
                                <li>Memperoleh informasi terkait hak dan kewajiban pasien</li>
                                <li>Memperoleh layanan yang manusiawi, adil, jujur dan tanpa diskriminasi</li>
                                <li>Memperoleh layanan kesehatan yang bermutu sesuai dengan kebutuhan medis, standar
                                profesi dan standar produsen operasional</li>
                                <li>Memperoleh layanan yang efektif dan efisien sehingga pasien terhindar dari kerugian
                                fisik dan materi</li>
                                <li>Mengajukan pengaduan atas kualitas pelayanan yang didapatkan</li>
                                <li>Memilih dokter dan kelas perawatan sesuai dengan keinginannya dna peraturan yang
                                berlaku di rumah sakit</li>
                                <li>Meminta konsultasi tentang penyakit yang dideritanya kepada dokter lain yang
                                mempunyai
                                Surat Izin Praktek (SIP) baik didalam maupun diluar rumah sakit</li>
                                <li>Mendapatkan privasi dan kerahasiaan penyakit yang diderita termasuk data-data
                                medisnya
                                (isi rekam medis)</li>
                                <li>Mendapat informasi yang meliputi diagnosis dan tata cara tindakan medis, tujuan
                                tindakan medis, alternatif tindakan, resiko dan komplikasi yang mungkin terjadi dan
                                prognosis terhadap tindakan yang dilakukan serta perkiraan biaya pengobatan/tindakan
                                medis yang akan dilakukan terhadap dirinya</li>
                                <li>Memberikan persetujuan atau penolakan sebagian atau seluruh tindakan yang akan
                                diberikan oleh tenaga kesehatan terhadap penyakit yang dideritanya setelah menerima
                                dan
                                memahami informasi mengenai tindakan tersebut secara lengkap dengan pengecualian yang
                                diatur dalam ketentuan peraturan perundang-undangan</li>
                                <li>Didampingi keluarganya dan atau penasehatnya dalam keadaan kritis atau menjelang
                                kematian</li>
                                <li>Menjalankan ibadah sesuai agama atau kepercayaan yang dianutnya selama hal itu tidak
                                mengganggu pasien lainnya</li>
                                <li>Memperoleh keamanan dan keselamatan dirinya selama dalam perawatan di rumah sakit
                                </li>
                                <li>Mengajukan usul, saran, perbaikan atas perlakuan rumah sakit terhadap dirinya</li>
                                <li>Menolak pelayanan bimbingan rohani yang tidak sesuai dengan agama dan kepercayaan
                                yang
                                dianutnya</li>
                                <li>Menggugat dan/atau menuntut Rumah Sakit apabila Rumah Sakit diduga memberikan
                                pelayanan yang tidak sesuai dengan standar baik secara perdata ataupun pidana; dan
                                </li>
                                <li>Mengeluhkan pelayanan Rumah Sakit yang tidak sesuai dengan standar pelayanan melalui
                                media cetak dan elektronik sesuai dengan ketentuan peraturan perundang-undangan</li>
                            </ol>
                            </td>
                            <td class="align-top">
                            <ol class="pl-3">
                                <li>Mematuhi peraturan yang berlaku di Rumah Sakit</li>
                                <li>Menggunakan fasilitas Rumah Sakit secara bertanggungjawab</li>
                                <li>Menghormati hak-hak pasien lain, pengunjung dan hak tenaga kesehatan serta petugas
                                lainnya yang bekerja di Rumah Sakit</li>
                                <li>Mmeberikan informasi yang jujur, lengkap dan akurat sesuai kemampuan dan
                                pengetahuannya tentang masalah kesehatannya</li>
                                <li>Memberikan informasi mengenai kemampuan finansial dan jaminan kesehatan yang
                                dimilikinya</li>
                                <li>Mematuhi rencana terapi yang direkomendasikan oleh tenaga kesehatan di Rumah Sakit
                                dan
                                disetujui oleh pasien yang bersangkutan setelah mendapatkan penjelasan sesuai
                                ketentuan
                                peraturan perundang-undangan</li>
                                <li>Menerima segala konsekuensi atas keputusan pribadinya untuk menolak rencana terapi
                                yang direkomendasikan oleh tenaga kesehatan dan/atau tidak mematuhi petunjuk yang
                                diberikan oleh tenaga kesehatan dalam rangka penyembuhan penyakit atas masalah
                                kesehatannya</li>
                                <li>Memberikan imbalan jasa atas pelayanan yang diterima</li>
                            </ol>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <h2>5. INFORMASI RAWAT INAP</h2>
                    <p>Saya telah menerima informasi tentang peraturan yang diberlakukan oleh Rumah Sakit dan saya
                        beserta keluarga bersedia untuk mematuhinya termasuk termasuk akan mematuhi jam berkunjung
                        pasien
                        sesuai dengan aturan di Rumah Sakit.</p>
                    <p>Anggota keluarga saya yang menunggu saya bersedia untuk selalu memakai tanda pengenal khusus
                        yang
                        diberikan oleh Rumah Sakit dan demi keamanan seluruh pasien setiap keluarga dan siapapun yang
                        akan
                        mengunjungi saya diluar jam berkunjung bersedia untuk dimintai/diperiksa identitasnya dan
                        bersedia
                        memakai tanda pengenal khusus yang diberikan oleh Rumah Sakit.</p>
                    <p>Saya mengetahui bahwa Rumah Sakit Harapan Magelang merupakan Rumah Sakit yang menjadi tempat
                        praktek klinik bagi mahasiswa keperawatan dan profesi-profesi kesehatan lainnya. Karena itu
                        mereka
                        mungkin berpartisipasi dan atau terlibat dalam perawatan saya. Saya <strong>menyetujui / tidak
                        menyetujui</strong> (coret salah satu) bahwa mahasiswa keperawatan dan profesi kesehatan lain
                        berpartisipasi dalam perawatan saya, sepanjang dibawah supervise Dokter Penanggung Jawab
                        Pelayanan.</p>
                    <div class="form-group">
                        <label for="">Partisipasi Mahasiswa dalam perawatan pasien <strong class="text-danger">*</strong></label>
                        <div>
                            <b-form-radio-group :options="Config.mr.pasienSetuju" v-model="isParent.row.aranres_partisipasi" />

                            <VValidate name="Partisipasi Mahasiswa" v-model="isParent.row.aranres_partisipasi"
                            :rules="toValidate(isParent.mrValidation.aranres_partisipasi)" />
                        </div>
                    </div>

                    <h2>6. PRIVASI</h2>
                    <p>Saya <strong>mengizinkan/tidak mengizinkan</strong> Rumah Sakit memberi akses bagi: keluarga
                        dan
                        handai taulan serta orang-orang yang akan menengok saya (sebutkan nama bila ada permintaan
                        khusus
                        yang tidak diizinkan)</p>
                    <div class="form-group">
                        <label for="">Akses menengok pasien <strong class="text-danger">*</strong></label>
                        <div>
                            <b-form-radio-group :options="Config.mr.izinYesNo" v-model="isParent.row.aranres_privasi" />
                            <VValidate name="Akses Menengok Pasien" v-model="isParent.row.aranres_privasi"
                            :rules="toValidate(isParent.mrValidation.aranres_privasi)" />
                        </div>
                    </div>
                    <div class="form-group" v-if="isParent.row.aranres_privasi == 'N'">
                        <label for="">Nama yang tidak diizinkan (bila ada)</label>
                        <textarea v-model="isParent.row.aranres_nama_yang_diizinkan" class="form-control" rows="3"></textarea>
                        <VValidate name="Nama yang Tidak Diizinkan" v-model="isParent.row.aranres_nama_yang_diizinkan"
                            :rules="toValidate(isParent.mrValidation.aranres_nama_yang_diizinkan)" />
                    </div>

                    <h2>7. INFORMASI BIAYA</h2>
                    <p>Saya memahami tentang informasi biaya pengobatan atau biaya tindakan yang dijelaskan oleh
                        petugas
                        Rumah Sakit.</p>
                    </div>
                </div>

                <hr>
                <div class="form-group">
                <label for="">Penandatanganan Dokumen <strong class="text-danger">*</strong></label>
                <div>
                    <b-form-radio-group :options="Config.mr.ttdCreatedBy" @change="isParent.autoSave(isParent.row,true)" v-model="isParent.row.aranres_ttd" />
                    <VValidate name="Penandatanganan Dokumen" v-model="isParent.row.aranres_ttd"
                    :rules="toValidate(isParent.mrValidation.aranres_ttd)" />
                </div>
                </div>

                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="">Nama Penanggung Jawab <strong class="text-danger">*</strong></label>
                            <b-form-input v-model="isParent.row.aranres_general_consent_pj_nama" type="text" class="form-control" placeholder="Nama Penanggung Jawab"/>
                            <VValidate name="Nama Penanggung Jawab" v-model="isParent.row.aranres_general_consent_pj_nama"
                            :rules="toValidate(isParent.mrValidation.aranres_general_consent_pj_nama)" />
                        </div>
                    </div>
                    
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="ttdTelp">No. Telp Penanggung Jawab<strong class="text-danger">*</strong></label>
                            <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">+62</span>
                            </div>
                            <b-form-input @input="autofill('aranres_general_consent_pj_no_telp','aranres_pd_no_telp')" v-model="isParent.row.aranres_general_consent_pj_no_telp" :formatter="isParent.$parent.number" type="text" class="form-control" placeholder="No. Telp Penanggung Jawab"/>
                            </div>
                            
                            <VValidate name="No. Telp Penanggung Jawab" v-model="isParent.row.aranres_general_consent_pj_no_telp"
                            :rules="toValidate(isParent.mrValidation.aranres_general_consent_pj_no_telp)" />
                                
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="perHpht">Tanggal Lahir</label>
                            <div class="input-group mb-3">
                                <datepicker input-class="form-control transparent" 
                                placeholder="Pilih Tanggal" class="my-datepicker"
                                calendar-class="my-datepicker_calendar" v-model="isParent.row.aranres_general_consent_pj_tanggal_lahir">
                                </datepicker>
                                <div class="input-group-append calendar-group">
                                <span class="input-group-text" id="basic-addon2"><i
                                    class="icon-calendar"></i></span>
                                </div>

                            </div>

                            <VValidate name="Tanggal Lahir Penanggung Jawwab" v-model="isParent.row.aranres_general_consent_pj_tanggal_lahir"
                            :rules="toValidate(isParent.mrValidation.aranres_general_consent_pj_tanggal_lahir)" />
                        </div>
                    </div>
                    
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="ttdAlamat">Alamat Penanggung Jawab<strong class="text-danger">*</strong></label>
                            <textarea @input="autofill('aranres_general_consent_pj_alamat','aranres_pd_alamat')" class="form-control" v-model="isParent.row.aranres_general_consent_pj_alamat" id="ttdAlamat" name="ttdAlamat" rows="3"></textarea>
                            <VValidate name="Alamat Penanggung Jawab" v-model="isParent.row.aranres_general_consent_pj_alamat"
                            :rules="toValidate(isParent.mrValidation.aranres_general_consent_pj_alamat)" />
                        </div>
                    </div>
                </div>

                
                <div v-if="isParent.row.aranres_ttd == 'Digital'" id="genDigitalSigning" class="genConsentBlock">
                    <a
                        @click="openPersetujuanUmum()"
                        href="javascript:;"
                        class="btn btn-labeled btn-labeled-left btn-warning"
                        data-toggle="modal"
                    >
                        <b><i class="icon-pencil5"></i></b>
                        <span>Tandatangani Persetujuan Umum</span>
                    </a>
                </div>

                <div v-if="isParent.row.aranres_ttd == 'Manual'" id="genManualSigning" class="genConsentBlock">
                    <div class="alert alert-info d-flex align-items-center">
                        <i class="icon-info22"></i>
                        <span class="ml-1">Simpan hasil reservasi, lalu unduh, cetak dan tandatangani dokumen. Unggah kembali dokumen yang telah ditandatangani.</span>
                    </div>
                </div>



            </div>
            <div :class="isParent.row.aranres_tab_pp == 'PR'?'tab-pane fade show active':'tab-pane fade'">
                <div class="text-blue-800 font-weight-semibold mb-2">Informasi Penandatangan</div>
                <div class="row">
                    <div class="col-md-4">
                    <div class="form-group">
                        <label for="ttdNama">Nama <strong class="text-danger">*</strong></label>
                        <b-form-input v-model="isParent.row.aranres_pd_nama" type="text" class="form-control" placeholder="Nama"/>
                        <VValidate name="Nama" v-model="isParent.row.aranres_pd_nama"
                          :rules="toValidate(isParent.mrValidation.aranres_pd_nama)" />
                    </div>
                    </div>
                    <div class="col-md-3">
                    <div class="form-group">
                        <label for="ttdNik">No. KTP / NIK <strong class="text-danger">*</strong></label>
                        <b-form-input v-model="isParent.row.aranres_pd_no_ktp" :formatter="isParent.$parent.number" type="text" class="form-control" placeholder="No. KTP / NIK"/>
                        <VValidate name="No. KTP / NIK" v-model="isParent.row.aranres_pd_no_ktp"
                          :rules="toValidate(isParent.mrValidation.aranres_pd_no_ktp)" />
                    </div>
                    </div>
                    <div class="col-md-3">
                    <div class="form-group">
                        <label for="ttdTelp">No. Telp. <strong class="text-danger">*</strong></label>
                        <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">+62</span>
                        </div>
                        <b-form-input v-model="isParent.row.aranres_pd_no_telp" :formatter="isParent.$parent.number" type="text" class="form-control" placeholder="No. Telp Penandatangan"/>
                        <VValidate name="No. Telp Penandatangan" v-model="isParent.row.aranres_pd_no_telp"
                          :rules="toValidate(isParent.mrValidation.aranres_pd_no_telp)" />
                        </div>
                    </div>
                    </div>
                    <div class="w-100"></div>
                    <div class="col-md-3 col-lg-3">
                        <div>
                            <label for="perHamil">Pekerjaan Pasien<strong class="text-danger">*</strong></label>
                            <v-select placeholder="Pilih Pekerjaan" v-model="isParent.row.aranres_pd_pekerjaan" :options="isParent.mPekerjaan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        </div>
                        
                        <VValidate 
                            name="Pekerjaan Pasien" 
                            v-model="isParent.row.aranres_pd_pekerjaan" 
                            :rules="toValidate(isParent.mrValidation.aranres_pd_pekerjaan)" />
                    </div>

                    <div class="col-lg-3" v-if="isParent.row.aranres_pd_pekerjaan == 99999">
                        <label for="perHamil">Pekerjaan Lainnya<strong class="text-danger">*</strong></label>
                        <b-form-input :formatter="isParent.$parent.normalText" v-model="isParent.row.aranres_pd_pekerjaan_lainnya"  type="text" class="form-control" placeholder="Isi Pekerjaan Lainnya"/>
                        <VValidate 
                            name="Isi Pekerjaan Lainnya" 
                            v-model="isParent.row.aranres_pd_pekerjaan_lainnya" 
                            :rules="toValidate(isParent.mrValidation.aranres_pd_pekerjaan_lainnya)" />
                    </div>

                    <div class="col-md-4">
                    <div class="form-group">
                        <label for="ttdAlamat">Alamat <strong class="text-danger">*</strong></label>
                        <textarea class="form-control" v-model="isParent.row.aranres_pd_alamat" id="ttdAlamat" name="ttdAlamat" rows="3"></textarea>
                        <VValidate name="Alamat" v-model="isParent.row.aranres_pd_alamat"
                          :rules="toValidate(isParent.mrValidation.aranres_pd_alamat)" />
                       
                    </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="">Penandatanganan Dokumen <strong class="text-danger">*</strong></label>
                    <div>
                        <b-form-radio-group :options="Config.mr.ttdCreatedBy" @change="isParent.autoSave(isParent.row,true)" v-model="isParent.row.aranres_pd_ttd" />
                        <VValidate name="Penandatanganan Dokumen Persetujuan Pasien" v-model="isParent.row.aranres_pd_ttd"
                        :rules="toValidate(isParent.mrValidation.aranres_pd_ttd)" />
                    </div>
                </div>

                <div v-if="isParent.row.aranres_pd_ttd == 'Digital'" id="genDigitalSigning" class="genConsentBlock">
                    <a
                        @click="openPersetujuanRanap()"
                        href="javascript:;"
                        class="btn btn-labeled btn-labeled-left btn-warning"
                        data-toggle="modal"
                    >
                        <b><i class="icon-pencil5"></i></b>
                        <span>Tandatangani Persetujuan Pasien Rawat Inap</span>
                    </a>
                </div>

                <div v-if="isParent.row.aranres_pd_ttd == 'Manual'" id="genManualSigning" class="genConsentBlock">
                    <div class="alert alert-info d-flex align-items-center">
                        <i class="icon-info22"></i>
                        <span class="ml-1">Simpan hasil reservasi, lalu unduh, cetak dan tandatangani dokumen. Unggah kembali dokumen yang telah ditandatangani.</span>
                    </div>
                </div>

            </div>
            
            
        </div>


        <b-modal v-model="modalPersetujuanUmum" :title="'Tanda tangani Dokumen'" size="xl" hide-footer>
            <validation-observer ref="VFormTTDPersetujuanPasien">
                <b-form @submit.prevent="doSaveTTDPersetujuan">            
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                        <label for="">Nama dan Tanda Tangan Petugas DISINI YA</label>
                        <b-form-input v-model="isParent.row.aranres_general_consent_pj_nama_petugas" type="text" class="form-control" placeholder="Nama Petugas"/>
                        <VValidate name="Nama Petugas" v-model="isParent.row.aranres_general_consent_pj_nama_petugas"
                          :rules="{required:1,min:3,max:128}" />

                        <div class="signing-element mt-1" data-target-id="ttdPetugas">
                            <VueSignaturePad
                                class="sign-container"
                                width="100%"
                                height="160px"
                                ref="ttdPetugas"
                            />
                            <div class="mt-2">
                            <button type="button" @click="undoTTD('ttdPetugas','aranres_general_consent_ttd_petugas')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                                <b><i class="icon-cross3"></i></b>
                                <span>Clear</span>
                            </button>
                            
                            <button type="button" @click="output('ttdPetugas','aranres_general_consent_ttd_petugas')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                                <b><i class="icon-checkmark2"></i></b>
                            <span>Simpan</span>
                            </button>
                            </div>
                        </div>
                        <VValidate name="Tanda Tangan Petugas" v-model="isParent.row.aranres_persetujuan_umum"
                          :rules="{required:1}" />
                        </div>

                        <div class="form-group">
                        <label for="">Nama dan Tanda Tangan Pembuat Pernyataan</label>
                        <b-form-input v-model="isParent.row.aranres_general_consent_pj_nama" type="text" class="form-control" placeholder="Nama Pembuat Pernyataan" />
                        <VValidate name="Nama Pembuat Pernyataan" v-model="isParent.row.aranres_general_consent_pj_nama"
                          :rules="{required:1,min:3,max:128}" />

                        <div class="signing-element mt-1" data-target-id="ttdPembuatPernyataan">
                            <VueSignaturePad
                                class="sign-container"
                                width="100%"
                                height="160px"
                                ref="ttdPembuatPernyataan"
                            />
                            <div class="mt-2">
                            <button type="button" @click="undoTTD('ttdPembuatPernyataan','aranres_general_consent_ttd_pembuat_pernyataan')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                                <b><i class="icon-cross3"></i></b>
                                <span>Clear</span>
                            </button>
                            
                            <button type="button" @click="output('ttdPembuatPernyataan','aranres_general_consent_ttd_pembuat_pernyataan')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                                <b><i class="icon-checkmark2"></i></b>
                            <span>Simpan</span>
                            </button>
                            </div>
                        </div>
                        <VValidate name="Tanda Tangan Pembuat Pernyataan" v-model="isParent.row.aranres_general_consent_pj_nama"
                          :rules="{required:1}" />

                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="document-framer">
                        <div class="df-content" v-html="isParent.row.aranres_general_consent_temp">
                        </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-12">
                        <div class="text-right">
                        <button type="button" class="btn" @click="modalPersetujuanUmum = false" data-dismiss="modal">Batal</button>
                        <button type="submit" class="btn btn-primary">Simpan <i class="icon-paperplane ml-2"></i></button>
                        </div>
                    </div>
                </div>
                </b-form>
            </validation-observer>
        </b-modal>

        <b-modal v-model="modalPersetujuanRanap" :title="'Tanda tangani Dokumen'" size="xl" hide-footer>
            <validation-observer ref="VFormTTDPersetujuanRanap">
                <b-form @submit.prevent="doSaveTTDPersetujuanRanap">            
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                        <label for="">Nama dan Tanda Tangan Petugas</label>
                        <b-form-input v-model="isParent.row.aranres_pd_nama_petugas" type="text" class="form-control" placeholder="Nama Petugas"/>
                        <VValidate name="Nama Petugas" v-model="isParent.row.aranres_pd_nama_petugas"
                          :rules="{required:1,min:3,max:128}" />
                        <div class="signing-element mt-1" data-target-id="ttdPetugasPD">
                            <VueSignaturePad
                                class="sign-container"
                                width="100%"
                                height="160px"
                                ref="ttdPetugasPD"
                            />
                            <div class="mt-2">
                            <button type="button" @click="undoTTD('ttdPetugasPD','aranres_pd_ttd_petugas')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                                <b><i class="icon-cross3"></i></b>
                                <span>Clear</span>
                            </button>
                            
                            <button type="button" @click="output('ttdPetugasPD','aranres_pd_ttd_petugas')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                                <b><i class="icon-checkmark2"></i></b>
                            <span>Simpan</span>
                            </button>
                            </div>
                            
                            <VValidate name="Tanda Tangan Pembuat Pernyataan" v-model="isParent.row.aranres_pd_ttd_petugas"
                            :rules="{required:1}" />
                        </div>
                        </div>

                        <div class="form-group">
                        <label for="">Nama dan Tanda Tangan Pembuat Pernyataan</label>
                        <b-form-input v-model="isParent.row.aranres_pd_nama_pembuat_pernyataan" type="text" class="form-control" placeholder="Nama Pembuat Pernyataan" />
                        <VValidate name="Nama Pembuat Pernyataan" v-model="isParent.row.aranres_pd_nama_pembuat_pernyataan"
                          :rules="{required:1,min:3,max:128}" />

                        <div class="signing-element mt-1" data-target-id="ttdPembuatPernyataanPD">
                            <VueSignaturePad
                                class="sign-container"
                                width="100%"
                                height="160px"
                                ref="ttdPembuatPernyataanPD"
                            />
                            <div class="mt-2">
                            <button type="button" @click="undoTTD('ttdPembuatPernyataanPD','aranres_pd_ttd_pembuat_pernyataan')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                                <b><i class="icon-cross3"></i></b>
                                <span>Clear</span>
                            </button>
                            
                            <button type="button" @click="output('ttdPembuatPernyataanPD','aranres_pd_ttd_pembuat_pernyataan')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                                <b><i class="icon-checkmark2"></i></b>
                            <span>Simpan</span>
                            </button>
                            </div>

                            <VValidate name="Tanda Tangan Pembuat Pernyataan" v-model="isParent.row.aranres_pd_ttd_pembuat_pernyataan"
                            :rules="{required:1}" />
                        </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="document-framer">
                        <div class="df-content" v-html="isParent.row.aranres_pd_temp">
                        </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-12">
                        <div class="text-right">
                        <button type="button" class="btn" @click="modalPersetujuanUmum = false" data-dismiss="modal">Batal</button>
                        <button type="submit" class="btn btn-primary">Simpan <i class="icon-paperplane ml-2"></i></button>
                        </div>
                    </div>
                </div>
                </b-form>
            </validation-observer>
        </b-modal>

    </div>
</template>


<script>
import Config from '@/libs/Config'
import Gen from '@/libs/Gen.js'
import Datepicker from 'vuejs-datepicker'  
import $ from 'jquery'


export default {
    components: {
      Datepicker
    },
    computed: {    
        Config(){ return Config },
        isParent(){
            return this.$parent.$parent.$parent.$parent
        },
    },
    data() {
        return {
            modalPersetujuanUmum: false,
            modalPersetujuanRanap: false
        }
    },
    mounted(){
        this.isParent.row.aranres_general_consent_pj_nama =  this.isParent.row.aranres_general_consent_pj_nama || this.isParent.rowPasien.arpj_fullname
        this.isParent.row.aranres_general_consent_pj_no_telp = this.isParent.row.aranres_general_consent_pj_no_telp || this.isParent.rowPasien.arpj_phone_number
        this.isParent.row.aranres_general_consent_pj_alamat = this.isParent.row.aranres_general_consent_pj_alamat ||this.isParent.rowPasien.arpj_address
        this.isParent.row.aranres_pd_pekerjaan = this.isParent.row.aranres_pd_pekerjaan ||this.isParent.rowPasien.arpj_pekerjaan
        this.isParent.row.aranres_pd_pekerjaan_lainnya = this.isParent.row.aranres_pd_pekerjaan_lainnya || this.isParent.rowPasien.arpj_pekerjaan_text

    },
    methods: {
        autofill(from,to){
            this.isParent.row[to] = this.isParent.row[from]
        },
        openPersetujuanUmum(){
            this.modalPersetujuanUmum = true            
            setTimeout(()=>{
                $(".sign-container canvas").attr("width",400)
                $(".sign-container canvas").attr("height",160)

                if(this.$refs['ttdPetugas']){
                    this.$refs['ttdPetugas'].fromDataURL(this.isParent.row.aranres_general_consent_ttd_petugas)
                }
                if(this.$refs['ttdPembuatPernyataan']){
                    this.$refs['ttdPembuatPernyataan'].fromDataURL(this.isParent.row.aranres_general_consent_ttd_pembuat_pernyataan)
                }        
                this.isParent.autoSave(this.isParent.row,true)
            },200)
        },
        openPersetujuanRanap(){
            this.modalPersetujuanRanap = true
            setTimeout(()=>{
                $(".sign-container canvas").attr("width",400)
                $(".sign-container canvas").attr("height",160)

                if(this.$refs['ttdPetugasPD']){
                    this.$refs['ttdPetugasPD'].fromDataURL(this.isParent.row.aranres_pd_ttd_petugas)
                }
                if(this.$refs['ttdPembuatPernyataanPD']){
                    this.$refs['ttdPembuatPernyataanPD'].fromDataURL(this.isParent.row.aranres_pd_ttd_pembuat_pernyataan)
                }         
                this.isParent.autoSave(this.isParent.row,true)
            },200)
        },
        toValidate(val){
            return {...val}
        },
        assetLocal(img){
          let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
          img = def+img
          return img
        },
        undoTTD(refs,field){
            this.$refs[refs].clearSignature()
            this.isParent.row[field] = null
            setTimeout(()=>{
                this.isParent.autoSave(this.isParent.row,true)
            },100)
        },
        output(refs,field) {
          let { isEmpty, data } = this.$refs[refs].saveSignature()
          if(!isEmpty){
            this.isParent.row[field] = data
            setTimeout(()=>{
                this.isParent.autoSave(this.isParent.row,true)
            },100)
          }
        },
        changeTab(e){
            this.isParent.row.aranres_tab_pp = e
        },
        doSaveTTDPersetujuan(){
            this.$refs['VFormTTDPersetujuanPasien'].validate().then(success => {
                if(!success){
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    })
                }else{
                    this.isParent.autoSave(this.isParent.row,true)
                    this.modalPersetujuanUmum = false
                }
            })
        },
        doSaveTTDPersetujuanRanap(){
            this.$refs['VFormTTDPersetujuanRanap'].validate().then(success => {
                if(!success){
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    })
                }else{
                    this.isParent.autoSave(this.isParent.row,true)
                    this.modalPersetujuanRanap = false
                }
            })
        },
        getConfigDynamic(master, value, val = 'text') {
            let text = ''
            if (value) {
                let index = (master||[]).findIndex(x => x.value == value)
                if (index !== -1) {
                text = master[index][val]
                }
            }
            return text
        },
        addPenerimaInfo() {
            this.isParent.row.aranres_pelepasan_informasi.push({
                'nama': null,
                'hub': null,
                'tel': null,
            })
        },
    }

} 

</script>