<template>    
    <div class="content-wrapper">
        
        <div v-if="isList" class="content pt-0">
            <bo-page-title />
            <div class="card">
                <div class="card-header bg-white">
                    <div class="row align-items-center">
                        <div class="col-md-5">
                            <div class="form-row align-items-center">
                                <div class="col-md-4">
                                    <h5 class="card-title font-weight-semibold">Daftar Pasien</h5>
                                </div>
                                <div class="col-md-8">
                                    <div class="form-group mb-0">
                                        <div class="input-group">
                                            <date-range-picker
                                                ref="picker"
                                                :locale-data="datePickerConfig.locale"
                                                :autoApply="datePickerConfig.autoApply"
                                                v-model="dateRange"
                                                :opens="'right'"
                                                :ranges="datePickerConfig.ranges"
                                                @update="updateValues"
                                            >
                                                <template v-slot:input="picker">
                                                    {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                                </template>
                                            </date-range-picker>
                                            <div class="input-group-append calendar-group">
                                                <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <b-form-group class="mb-0">
                                <v-select placeholder=" -- Pilih Bangsal -- " @input="doFill" v-model="filter.ranap_bangsal"  :options="mRanapBangsal" label="text" :reduce="v=>v.value" :clearable="true"></v-select>
                            </b-form-group>
                        </div>
                        <div class="col-md-3">
                            <b-form-group class="mb-0">
                                <v-select placeholder=" -- Pilih Kamar -- " @input="doFill" v-model="filter.ranap_kamar"  :options="mRanapKamar" label="text" :reduce="v=>v.value" :clearable="true"></v-select>
                            </b-form-group>
                        </div>
                        <div class="col-md-2 d-flex justify-content-end">
                            <a href="javascript:;" @click="downloadPasienPerawatan()" class="btn btn-success btn-labeled btn-labeled-left" v-b-tooltip.hover title='Download Data Pasien "Dalam Perawatan"'>
                            <b><i class="icon-download"></i></b>
                            <span>Download</span>
                            </a>
                        </div>
                    </div>
                </div>
                
                <div class="card-header">
                    <div class="row">
                        <div class="col-lg-9">
                            <div class="row gx-1">
                                <div class="col-md">
                                    <div class="row gx-1">
                                        <div class="col-md-auto">
                                            <div class="form-group mb-0">
                                                <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right ><i class="icon-spinner11"></i></button>
                                            </div>
                                        </div>
                                                        
                                        <div class="col-md-3">
                                            <b-form-group class="mb-0">
                                                <v-select placeholder=" -- Pilih Jaminan -- " @input="doFill" v-model="filter.status_jaminan"  :options="Config.mr.statusJaminan" label="text" :reduce="v=>v.value" :clearable="true"></v-select>
                                            </b-form-group>
                                        </div>

                                        <div class="col-md-3">
                                            <input type="text" placeholder="Cari Tanggal Lahir" @input="fillDOB($event)" class="form-control" v-mask="'##/##/####'" v-model="filter.dob_filter">
                                        </div>
                                                  
                                        <div class="col-md-3">
                                            <v-select placeholder=" -- Pilih DPJP -- " @input="doFill" v-model="filter.dpjp"  :options="mDPJPUtama" :clearable="true" label="text" :reduce="v=>v.value"></v-select>
                                        </div>

                                        <div class="col-md-2">
                                            <v-select placeholder=" -- Pilih Jenis Rawat Inap -- " @input="doFill" v-model="filter.jenis_ranap"  :options="mJenisRanap" :clearable="true" label="text" :reduce="v=>v.value"></v-select>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                                <input class="form-control"
                                    v-model="filter.search"
                                    @input="doSearch"
                                    placeholder="Ketik Nama/No. RM Pasien"
                                />
                                <div class="form-control-feedback">
                                    <i class="icon-search4 text-indigo"></i>
                                </div>
                                    
                                <b-button
                                    class="ml-1 d-inline-flex align-items-center"
                                    variant="outline-success"
                                    id="resetBtn"
                                    @click="doResetData()"
                                >Reset</b-button>
                            </div>
                        </div>
                    </div>
                </div>     

                     
                
                <div class="table-responsive">
                    <table
                        class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
                        <thead>
                            <tr>
                                <th>NO.</th>
                                <th>NO. KEDATANGAN</th>
                                <th>DIAGNOSA</th>
                                <th>TANGGAL MASUK</th>
                                <th>NAMA/NO KTP</th>
                                <th>DOKTER DPJP</th>                                
                                <th>NO RM</th>
                                <th>CARA BAYAR</th>
                                <th>TANGGAL LAHIR</th>
                                <th>JENIS RAWAT INAP</th>
                                <th>USIA</th>
                                <th>RUANG & KAMAR</th>
                                <th>STATUS</th>
                                <th>NAMA ADMISI</th>
                                <th>ASAL PASIEN</th>
                                <th>AKSI</th>
                            </tr>
                        </thead>
                        
                        <tbody v-if="(dataList||[]).length">
                            <tr v-for="(v,k) in (dataList||[])" :key="k" 
                            :class="v.aranr_is_pindah_ruang == 'Y' && v.aranr_is_pindah_by == 'ADMISI'? 'table-warning' : v.aranr_is_pindah_ruang == 'Y' && v.aranr_is_pindah_by == 'ADMINRUANG' ? 'table-success' : ''">
                                <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                                <td>
                                    <div class="text-success-700 font-weight-semibold">{{v.aranr_reg_code||"-"}}</div>
                                </td>
                                <td>
                                    <a href="javascript:;" @click="lihatDiagnosa(v,k)" 
                                    v-b-tooltip.click.html="v.diagnosa" class="font-weight-semibold border-bottom"> Lihat Diagnosa </a>
                                </td>
                                <td>
                                    <div>{{v.aranres_tanggal_periksa | moment("DD MMMM YYYY")}}</div>
                                    <div>{{v.aranres_jam_periksa||"-"}} WIB</div>
                                </td>
                                
                                <td>
                                    <p :class="isSameName(v.ap_fullname)+' mb-0'">
                                    {{uppercaseWord(v.ap_fullname)||"-"}}
                                    </p>
                                    <br/>
                                    <small class="text-danger" style="font-size: 10px;" v-if="v.ap_fullname && isSameName(v.ap_fullname)">Nama Pasien Sama</small>

                                    <div class="text-info">{{v.ap_nik||"-"}}</div>
                                    <span v-if="v.aranr_is_emergency == 'Y'" :class="`badge bg-danger mt-2`">Emergency</span>
                                </td>
                                   
                                <td>
                                    <div class="font-weight-semibold">{{v.bu_full_name||"-"}}</div>
                                </td>

                                <td>
                                    <div class="font-weight-semibold">{{v.ap_code||"-"}}</div>
                                    <small :class="v.ap_is_old_pasien == 'Y' ? 'font-weight-semibold text-danger' : 'font-weight-semibold text-primary'" style="font-size: 12px;">
                                    {{v.ap_is_old_pasien == 'Y' ? 'PASIEN LAMA' : 'PASIEN BARU'}}</small>
                                </td>

                                <td>
                                    <div class="font-weight-semibold">{{getConfigDynamic(Config.mr.statusJaminan,v.aranres_jaminan)||"-"}}</div>
                                </td>
                                     
                                <td>
                                    <div v-if="v.ap_dob" class="font-weight-semibold">
                                        {{v.ap_dob | moment("DD MMM YYYY")}}
                                    </div>
                                    <span v-else> - </span>
                                </td>

                                <td>
                                    <div class="font-weight-semibold">{{getConfigDynamic(Config.mr.jenisRawatInap,v.aranres_jenis_ranap)||"-"}}</div>
                                </td>
                            

                                <td>
                                    <div class="font-weight-semibold">
                                        {{v.ap_usia_with_ket || "-"}}
                                    </div>
                                </td>
                                
                                <td>
                                    <template v-if="v.mranb_name && v.mrank_name">
                                        <div class="font-weight-semibold">{{v.mranb_name || "-"}}</div>
                                        <span class="text-secondary">{{v.mrank_name || "-"}}</span>
                                        <span class="text-secondary" v-if="v.mranbed_name"> - {{v.mranbed_name || "-"}}</span>
                                    </template>
                                    <span v-else> - </span>
                                </td>

                                <td>
                                
                                    <div class="badge badge-dark mb-1" v-if="v.aranr_is_void == 'Y'">
                                        VOID
                                    </div>
                                    <template v-else>
                                        <div v-if="!v.aranr_status" :class="`badge badge bg-warning`">Pendaftaran Baru</div>
                                        
                                        <template v-else>
                                            <div v-if="(v.aranr_status == 1 || v.aranr_status == 2)" class="badge badge-info mb-1">
                                                Dalam Perawatan
                                            </div>
                                            <div v-else class="badge badge-success mb-1">
                                                Selesai Perawatan
                                            </div>                                        
                                        </template>
                                        
                                        <template>
                                            <div v-if="v.aranr_is_pindah_ruang == 'Y'" class="badge badge-info mb-1" v-b-tooltip.hover.right 
                                            :title="v.aranr_is_pindah_by == 'ADMISI'?'Pindah Ruang Dilakukan Oleh Admisi':'Pindah Ruang Dilakukan Oleh Admin Ruang'">
                                                Pindah Ruang
                                            </div>
                                        </template>

                                        <div class="badge badge-warning mb-1" v-if="!v.absd_no_sep && (v.aranres_jaminan == 3)"> Buatkan SEP </div>  
                                        
                                        <div class="badge badge-warning mb-1" v-if="v.aranr_is_finger_bpjs != 'Y' && (v.aranres_jaminan == 3)"> Belum Finger Print </div>  

                                        <div v-if="v.aranres_is_cara_bayar_bermasalah == 'Y'" :class="`badge badge bg-danger`">Jaminan Bermasalah</div>

                                    </template>
                                

                                </td>
                                <td>
                                    {{v.nama_admisi||"-"}}
                                </td>
                                
                                
                                <td>
                                    <span v-if="v.aranres_cara_masuk == 'RAJAL'">Rawat Jalan</span>
                                    <span v-else-if="v.aranres_cara_masuk == 'UGD'">UGD</span>
                                    <span v-else-if="v.aranres_cara_masuk == 'BBL'"  v-b-tooltip.hover.right :title="v.aranres_kondisi_bayi ? ('Kondisi Bayi : '+v.aranres_kondisi_bayi):''">Bayi Baru Lahir</span>
                                    <span v-else>LAINNYA</span>
                                    

                                    <div class="font-weight-semibold mt-1 text-info" v-b-tooltip.hover.right 
                                    :title="v.aranres_cara_masuk == 'UGD'?'Nomor Registrasi UGD':'Nomor Registrasi Rawat Jalan'">{{v.no_reg||"-"}}</div>
                                </td>

                                <td>
                                    <template v-if="v.aranr_id">
                                        <router-link class="btn btn-icon rounded-round btn-sm mr-1 alpha-info border-info"
                                        v-if="moduleRole('view') && v.aranres_is_draft !== 'Y'"
                                        :to="{name: $route.name, params: {pageSlug: v.aranres_id}, query: {isDetail: true}}"
                                        data-toggle="tooltip" v-b-tooltip.hover.right data-placement="top" title="View"><i class="icon-eye"></i></router-link>

                                        <a href="javascript:;" @click="toForm(v)" class="btn btn-icon rounded-round btn-sm mr-1 alpha-info border-info mt-1"
                                        data-toggle="tooltip" v-b-tooltip.hover.right data-placement="top" 
                                        :title="(v.ap_dob && v.ap_is_draft != 'Y') ? 'Edit Data':'Daftarkan Pasien'"><i :class="(v.ap_dob && v.ap_is_draft != 'Y') ? 'icon-pencil5': 'icon-file-plus'"></i></a>
                                        
                                        <!--
                                        <router-link class="btn btn-icon rounded-round btn-sm mr-1 alpha-info border-info"
                                        :to="{name: $route.name, params: {pageSlug: v.aranres_id}}"
                                        data-toggle="tooltip" v-b-tooltip.hover.right data-placement="top" title="Edit Data"><i class="icon-pencil5"></i></router-link>
                                        -->
                                        
                                        <a href="javascript:;" v-if="v.aranr_is_void != 'Y'" @click="voidData(v)" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800" data-popup="tooltip" data-toggle="modal" v-b-tooltip.hover.right title="Pasien VOID">
                                        <i class="icon-cancel-circle2"></i>
                                        </a>
                                        

                                        <a v-if="v.aranr_id && moduleRole('riwayat_dpjp_pindah_ruang')" href="javascript:;" @click="doOpenRiwayat(v)" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800" data-popup="tooltip" v-b-tooltip.hover.right title="Riwayat DPJP Pasien">
                                            <i class="fa-solid fa-users"></i>
                                        </a>
                                    </template>
                                    
                                    <template v-else>
                                        <router-link class="btn btn-icon rounded-round btn-sm mr-1 alpha-info border-info"
                                        v-if="moduleRole('view')"
                                        :to="{name: $route.name, params: {pageSlug: v.aranres_id}, query: {isDetail: true}}"
                                        data-toggle="tooltip" v-b-tooltip.hover.right data-placement="top" title="View"><i class="icon-eye"></i></router-link>

                                        <a href="javascript:;" @click="toForm(v)" class="btn btn-icon rounded-round btn-sm mr-1 alpha-info border-info mt-1"
                                        data-toggle="tooltip" v-b-tooltip.hover.right data-placement="top" title="Daftarkan Pasien"><i class="icon-file-plus"></i></a>

                                        <a href="javascript:;" @click="doDelete(k,v)" class="btn btn-icon rounded-round btn-sm mr-1 alpha-danger border-danger mt-1"
                                        data-toggle="tooltip" v-b-tooltip.hover.right data-placement="top" title="Hapus Data Pendaftaran"><i class="icon-bin"></i></a>
                                    </template>

                                    
                                    <router-link :to="{name: $route.name, params: {pageSlug: v.aranres_id}, query: {isDoc: true}}" class="btn btn-icon rounded-round btn-sm alpha-success border-success text-success-800 mt-1" data-popup="tooltip" data-toggle="modal" v-b-tooltip.hover.right title="Lengkapi Dokumen Persetujuan">
                                    <i class="icon-file-check"></i>
                                    </router-link>

                                    <a href="javascript:;" v-if="v.aranr_reg_code" @click="printGelang(v)" data-toggle="modal" class="btn btn-icon rounded-round btn-sm alpha-info border-info mt-1" v-b-tooltip.hover.right title="Cetak Sticker Gelang Pasien" ><i class="icon-printer"></i></a>

                                    
                                    <a href="javascript:;" v-if="v.aranr_reg_code" @click="printKartuTunggu(v)" data-toggle="modal" class="btn btn-icon rounded-round btn-sm alpha-success border-success mt-1" v-b-tooltip.hover.right title="Cetak Kartu Tunggu Pasien" ><i class="icon-printer"></i></a>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-if="data.data.length == 0">
                            <tr>
                                <td colspan="99">
                                <div class="text-center">
                                    <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                                    <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                                </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-if="!data.data">   
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                        </tbody>
                    </table>

                    
                    <div :class="(dataList||[]).length && !loadingTable && dataList ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
                        <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                        <i class="icon-chevron-left"></i>
                        </a>
                        <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                        <i class="icon-chevron-right"></i>
                        </a>
                    </div>
                </div>
                
                <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">     <b-pagination
                    class="mb-0"
                    v-model="pageNo"
                    :per-page="data.per_page"
                    :total-rows="data.total"
                />
                </b-card-footer>

            </div>

            <b-modal 
                v-model="openModalVoid"
                @ok.prevent="doVoid"
                size="sm"
                no-close-on-backdrop
                class="text-center"
                :title="'Konfirmasi VOID Pasien'" 
            >
                <div class="text-center mb-3">
                    <div class="modal-shout-icon d-inline-block alpha-danger text-danger rounded-circle">
                        <i class="icon-cancel-circle2"></i>
                    </div>
                    <h4 class="font-weight-semibold">Konfirmasi VOID Pasien?</h4>
                </div>
                <div class="form-group">
                    <label for="admisiVoidNote">Catatan VOID</label>
                    <textarea v-model="rowEdit.aranr_notes" name="admisiVoidNote" id="admisiVoidNote" rows="3" class="form-control"></textarea>
                </div>
            </b-modal>
        </div>

        
        <template v-else>
            <FormDetail v-if="isDetail && !isDoc" :row.sync="row" v-bind="passToSub" />
            <FormDoc v-else-if="!isDetail && isDoc" :row.sync="row" v-bind="passToSub" />
            <Form v-else :row.sync="row" v-bind="passToSub" />
        </template>

        

        <b-modal size="lg" v-model="openRiwayat" title="Riwayat DPJP & Pindah Ruangan Pasien" ok-only ok-title="Tutup">
            <b-tabs nav-class="nav-tabs-bottom nav-justified mb-0">
            <b-tab title="Riwayat DPJP">
                <div class="row mt-2">
                <div class="col-md-12 mb-2">
                    <table class="table table-sm table-bordered">
                    <thead>
                        <tr>
                        <th>Dari Dokter</th>
                        <th>Ke Dokter</th>
                        <th>Tanggal Pindah</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>-</td>
                        <td>{{rowEdit.bu_full_name||"-"}}</td>
                        <td>{{rowEdit.aranr_created_date | moment("DD MMMM YYYY, HH:mm")}}</td>
                        </tr>
                        <tr v-for="(v,k) in (logDPJP||[])" :key="k">
                        <td>{{v.dpjp_from||"-"}}</td>
                        <td>{{v.dpjp_to||"-"}}</td>
                        <td>{{v.aranlpd_date | moment("DD MMMM YYYY, HH:mm")}}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </b-tab>
            <b-tab title="Riwayat Pindah Ruang">
                <div class="row mt-2">
                <div class="col-md-12 mb-2">
                    <table class="table table-sm table-bordered">
                    <thead>
                        <tr>
                        <th>Jenis Rawat Inap</th>
                        <th>Bangsal Asal</th>
                        <th>Bangsal Tujuan</th>                  
                        <th>Tanggal Pindah</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(v,k) in (logPindahRuang||[])" :key="k">
                        <td>{{v.mranjr_name||"-"}}</td>
                        <td>{{getConfigDynamic(mRanapBangsal,v.aranpr_bangsal_asal)||"-"}}</td>
                        <td>{{getConfigDynamic(mRanapBangsal,v.aranpr_bangsal)||"-"}}</td>
                        <td>{{v.aranpr_created_date | moment("DD MMMM YYYY, HH:mm")}}</td>
                        </tr>
                        <tr v-if="!(logPindahRuang||[]).length">
                        <td class="text-center" colspan="99">Tidak ada data</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </b-tab>
            </b-tabs>
        </b-modal>
    </div>
</template>

<script>
    import GlobalVue from '@/libs/Global.vue'
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    const moment = require('moment')
    import Form from './Form.vue'
    import FormDetail from './FormDetail.vue'
    import FormDoc from './FormDoc.vue'

    // import Datepicker from 'vuejs-datepicker'


    const _ = global._
    import $ from 'jquery'
    import Gen from '@/libs/Gen.js'

    export default {
        extends: GlobalVue,
  	    components:{
            DateRangePicker,FormDetail,Form,FormDoc
        },
        computed:{
            isDetail(){
                return this.$route.query.isDetail
            },
            isDoc(){
                return this.$route.query.isDoc
            },
            passToSub(){
                return {
                    Config:this.Config,
                    mrValidation: this.mrValidation,
                    rowPasien: this.rowPasien,
                    isShowBangsal: this.isShowBangsal,
                    infoBangsal: this.infoBangsal,
                    katalogBangsal: this.katalogBangsal
                }
            },
        },
        data(){
            return {
                idKey:'aranres_id',
                datePickerConfig: {
                    startDate: new Date(),
                    endDate: new Date(),
                    autoApply: true,
                    ranges: {
                        'Hari Ini': [new Date(), new Date()],
                        '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                        '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                    },
                    applyClass: 'btn-sm btn-primary',
                    cancelClass: 'btn-sm btn-light',
                    locale: {
                        applyLabel: 'Terapkan',
                        cancelLabel: 'Batal',
                        direction: 'ltr',
                        format: 'mm/dd/yyyy',
                        separator: ' - ',
                    }
                },
                dateRange: {
                    startDate: new Date(moment().subtract(6, 'days')),
                    endDate: new Date(),
                },
                pasienLama: 0,
                pasienBaru: 0,

                rowEdit : {},
                rowPasien : {},
                openModalVoid : false,


                // for form
                isShowBangsal: false,
                infoBangsal: [],
                katalogBangsal: [],
                mDPJPUtama: [],
                mJenisRanap: [],

                openRiwayat: false,
                logDPJP: [],
                logPindahRuang: [],
                mRanapBangsal: [],
                mRanapKamar: [],
            }
        },
        mounted(){
            this.filter.status_pasien = 'ALL'
            this.filter.status_periksa = 'ALL'
            this.filter.status_jaminan = 'ALL'

            
            this.doConvertDate()      
            this.apiGet()
            
            if(this.isList){
                this.apiGetResume()
            }
                
            setTimeout(()=>{
                this.setSlide()
            },1000)
        },
        methods: {
            lihatDiagnosa(v,k){
                if(v.aranres_cara_masuk == 'UGD'){
                    let data = v
                    data.type = "lihat-diagnosa-ugd"
                    Gen.apiRest(
                        "/do/" + 'RanapReservasi', {
                            data: data
                        },
                        "POST"
                    ).then(res => {
                        let resp = res.data
                        this.data.data[k]['diagnosa'] = resp
                    })
                }else if(v.aranres_cara_masuk == 'RAJAL'){
                    let data = v
                    data.type = "lihat-diagnosa-rajal"
                    Gen.apiRest(
                        "/do/" + 'RanapReservasi', {
                            data: data
                        },
                        "POST"
                    ).then(res => {
                        let resp = res.data
                        this.data.data[k]['diagnosa'] = resp
                    })
                }else{
                    this.data.data[k]['diagnosa'] = "Belum ada Diagnosa"
                }
            },
            doOpenRiwayat(row) {
                this.rowEdit = row
                this.loadingOverlay = true
                let data = row
                data.type = "open-riwayat"
                Gen.apiRest(
                    "/do/" + 'RanapPasienPerawatan', {
                    data: data
                    },
                    "POST"
                ).then(res => {
                    let resp = res.data
                    this.loadingOverlay = false
                    this.openRiwayat = true

                    this.logDPJP = resp.logDPJP
                    this.logPindahRuang = resp.logPindahRuang
                })
            },
            toForm(v){
                if(!v.ap_dob || v.ap_is_draft == "Y"){
                    return this.$swal({
                        icon: 'error',
                        title: 'Mohon lengkapi data pasien sebelum melanjutkan pendaftaran.',
                        showCancelButton: true,
                        confirmButtonText: 'Lengkapi Data Pasien',
                        cancelButtonText: 'Lengkapi Nanti'
                    }).then(result => {
                        if (result.value) {
                            this.$router.push({name: 'RoPasienNew', params: {pageSlug: v.ap_id}})
                            .catch(()=>{})           
                        }
                    })
                }
                this.$router.push({name: this.$route.name, params: {pageSlug: v.aranres_id}})
                .catch(()=>{})
            },

            fillDOB(e){
                let val = e.target.value
                if(val.length == 10){
                    let dobdata = val.split("/")
                    let date = moment(dobdata[2]+"-"+dobdata[1]+"-"+dobdata[0]).format('DD/MM/YYYY')
                    let d = new Date()
                    if(date == 'Invalid date'){
                        return this.$swal({
                            icon: 'error',
                            title: 'Tanggal Tidak Valid'
                        })
                    }else{
                        this.filter.dob = dobdata[2]+"-"+dobdata[1]+"-"+dobdata[0]
                        this.doFill()
                    }
                }
            },
            isSameName(name){
                let index = this.dataList.findIndex(x=>x.ap_fullname == name)
                let count = 0
                for (let i = 0; i < this.dataList.length; i++) {
                    if (this.dataList[i].ap_fullname === name) {
                        count++
                    }
                }
                if(count > 1){
                    return "text-warning"
                }else{
                    return ""
                }
            },
            doVoid(){
                let data = this.rowEdit
                data.type = "void-reservasi"
                this.loadingOverlay = true

                Gen.apiRest(
                    "/do/"+this.modulePage,
                    {data:data}, 
                    "POST"
                ).then(res=>{
                    this.loadingOverlay = false
                    this.openModalVoid = false
                    let resp = res.data
                    resp.statusType = 200
                    this.$swal({
                        title: resp.message,
                        icon: resp.status,
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    })

                    this.apiGet()

                }).catch(err=>{
                    this.loadingOverlay = false
                    if (err) {
                        err.statusType = err.status
                        err.status = "error"
                        err.title = err.response?.data?.title
                        err.message = err.response?.data?.message
                        err.messageError = err.message
                    }
                    this.doSetAlertForm(err)
                })
            },
            setSlide(){
                const responsiveTable = document.querySelector('.table-responsive')
                if(responsiveTable){
                    const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                    const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
                    if(responsiveTableScroll){
                        // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                        Array.from(responsiveTableScrollActions).forEach(action => {
                            action.addEventListener('click', e => {
                                const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                                 
                                responsiveTable.scroll({
                                    left: scrollAmount,
                                    behavior: 'smooth'
                                })
                            })
                        })
                    }
                }
            },
            windowTableScroller(e){
                const responsiveTable = document.querySelector('.table-responsive')
                if(responsiveTable){
                    const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                    if(responsiveTableScroll){
                        // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                        this.tableScrollPoint = responsiveTable.getBoundingClientRect().top <= 64 ? (responsiveTable.offsetTop * 2) - 64 : null
                        const isTableScrollable = responsiveTable.scrollHeight > responsiveTable.clientHeight
                        if(isTableScrollable && responsiveTable.getBoundingClientRect().top <= 80 && this.tableScrollPoint){
                            this.onScrollPoint = true
                            document.documentElement.scroll(0, this.tableScrollPoint)
                            window.addEventListener('wheel', this.tableScrollFunction, false)
                        }
                    }
                }
            },
            doResetData(){
                this.doReset()
                this.dateRange =  {
                    startDate: new Date(),
                    endDate: new Date(),
                }
                this.doConvertDate()
            },
            doRefreshData(){
                this.apiGet()
                window.scrollTo(0,0)
            },
            apiGetResume(){
                let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
                Gen.apiRest(
                    "/get/"+this.modulePage+'/resume', 
                    {
                    params: Object.assign({}, paramsQuery||{})
                    }
                ).then(res=>{
                    _.forEach(res.data, (v,k)=>{
                        this.$set(this, k, v)
                    })
                })
            },
            toDetail(data){
                this.$router.push({ name: 'RanapReservasi', params: { pageSlug: data.aranres_id } })
                .catch(()=>{})
            },
            doConvertDate(){
                this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
                this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
            },
            doPageOne(){
                this.filter.page = 1
            },
            doFill(){
                this.doPageOne()
                this.doFilter()
            },
            updateValues(e){
                this.doConvertDate()
                this.doFill()
            },
            doSearch: _.debounce(function(){
                this.doPageOne()
                this.doFilter()
            },500),
            getConfigDynamic(master,value){
                let text = ''
                if(value){
                    let index = (master||[]).findIndex(x => x.value == value)
                    if(index !== -1){
                        text = master[index]['text']
                    }
                }
                return text
            },
            voidData(row){
                this.rowEdit = row
                this.openModalVoid = true
            },
            downloadPasienPerawatan(){
                this.$swal.fire({
                    title: 'Processing...',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    didOpen: () => {
                    this.$swal.showLoading()
                    }
                })

                Gen.apiRest(
                    "/do/" + this.modulePage, {
                        data: {
                            type: "download-pasien",
                            id_registrasi: this.$route.params.pageSlug,
                            ...this.filter,
                            dataList: this.dataList
                        },
                        is_blob: 'Y'
                    },
                    "POST"
                )
                    .then(res => {
                        const filename = `Pasien-Dalam-Perawatan-${new Date().getTime()}.pdf`
                        const url = window.URL.createObjectURL(res.data)
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute('download', filename)
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)

                        this.$swal.close()
                    })
                    .catch(err =>{
                        this.$swal.close()
                        this.globalVar.apiGenError( err.response.data )
                    })
            },
            printGelang(row){
                let jk = row.ap_gender == 1 ? "L" : row.ap_gender == 2 ? "P" : "TK"
                let dob = moment(row.ap_dob).format("DD-MM-YYYY")
                let tgl_masuk = moment(row.aranr_reg_date).format("DD-MM-YYYY")
                let nik = row.ap_nik||"-"

                let newWindow = window.open('', '', 'left=0,top=0,width=189,height=57,toolbar=0,scrollbars=0,status=0,addressbar=0'),
                document = newWindow.document.open(),
                pageContent =
                    '<!DOCTYPE html>' +
                    '<html>' +
                    '<head>' +
                    '<meta charset="utf-8" />' +
                    '<title>Inventory</title>' +
                    '<style type="text/css">body {-webkit-print-color-adjust: exact; font-family: Arial; }</style>' +
                    '</head>' +
                    '<body>' +
                    `<div style="position: relative;max-width: 189px;height: 57px;border: 1px solid black;">
                        <div
                        style="height:100%;position: relative;font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;color: #333;line-height: 1.5;font-size: 13px;">
                        <div>
                            <table border="0" style="border-collapse: collapse;width: 100%;">
                            <tr>
                                <td style="text-align: center;width:20%;margin: auto;padding-top: 5px;padding: 3px;padding-top: 8px;">
                                <img src="${this.assetLocal("global_assets/images/logo-rsh-mark.png")}" style="width: 100%;" alt="">
                                </td>
                                <td style="padding:0;">
                                <h6 style="font-size: 6px;margin:0;margin-left: 3px;margin-bottom: 4px;margin-top: 3px;">Rumah Sakit Harapan Magelang
                                </h6>
                                <table>
                                    <tr style="line-height: 4px;">
                                    <td style="font-size: 6px;" colspan="3">${row.ap_fullname} (${jk}) - ${dob}</td>
                                    </tr>
                                    <tr style="line-height: 4px;">
                                    <td style="font-size: 6px;">No. RM</td>
                                    <td style="font-size: 6px;">:</td>
                                    <td style="font-size: 6px;">${row.ap_code}</td>
                                    </tr>
                                    <tr style="line-height: 4px;">
                                    <td style="font-size: 6px;">NIK</td>
                                    <td style="font-size: 6px;">:</td>
                                    <td style="font-size: 6px;">${nik}</td>
                                    </tr>
                                    <tr style="line-height: 4px;">
                                    <td style="font-size: 6px;">Tgl. Masuk</td>
                                    <td style="font-size: 6px;">:</td>
                                    <td style="font-size: 6px;">${tgl_masuk}</td>
                                    </tr>
                                </table>
                                </td>
                            </tr>
                            </table>
                        </div>
                        </div>
                    </div>`
                    +
                    '</body></html>'
                document.write(pageContent)
                document.close()
                newWindow.moveTo(0, 0)
                newWindow.resizeTo(screen.width, screen.height)
                setTimeout(function() {
                    newWindow.print()
                    newWindow.close()
                }, 250)
            },
            printKartuTunggu(row){
                let dob = moment(row.ap_dob).format("DD-MM-YYYY")
                let reg_date = moment(row.aranr_reg_date).format("DD-MM-YYYY")
                let tgl_berlaku = moment().format("DD-MM-YYYY") + ' sd ' + moment().add(2, 'days').format("DD-MM-YYYY")

                let newWindow = window.open('', '', 'left=0,top=0,width=189,height=57,toolbar=0,scrollbars=0,status=0,addressbar=0'),
                document = newWindow.document.open(),
                pageContent =
                    '<!DOCTYPE html>' +
                    '<html>' +
                    '<head>' +
                    '<meta charset="utf-8" />' +
                    '<title>Inventory</title>' +
                    '<style type="text/css">body {-webkit-print-color-adjust: exact; font-family: Arial; }</style>' +
                    '</head>' +
                    '<body>' +
                    `
                    <div style="position: relative;max-width: 500px;  background-image: url(
                    ${this.assetLocal("global_assets/images/watermark-card.png")}); background-repeat: no-repeat; background-position: center;">
                    <div style="position: relative;font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;color: #333;line-height: 1.5;font-size: 13px;">
                        <div>
                        <table border="1" style="border-collapse: collapse;width: 100%;">
                            <tbody><tr>
                            <td style="padding:0;">
                                <div style="background-color:#162c5e; padding:5px; max-width: 70%; border-bottom-right-radius: 25px;"><p style="margin:0; padding:0; color:#fff; font-size: 16px; font-weight: 600;">Kartu Tunggu</p></div>
                            </td>
                            </tr>
                            <tr>
                            <td style="padding:0;">
                                <table style="border-collapse: collapse;width: 100%;">
                                <tbody><tr>
                                    <td style="padding:8px 12px;">
                                    
                                    <div style="text-transform: uppercase;float: left;width: 88%;vertical-align: middle;box-sizing: border-box;padding-left: 8px;padding-right: 12%;text-align: left;">
                                        <h1 style="margin:0;font-size: 18px;line-height: 20px;margin-bottom: 2px;">Rumah Sakit Harapan Magelang</h1>
                                        <p style="margin:0;font-size: 13px;">Jl. P. Senopati No. 11 Magelang 56123<br>Telp. (0293)364033 s/d 364035 Fax. (0293)364037</p>
                                    </div>
                                    <div style="float: left;width: 12%;vertical-align: middle;box-sizing: border-box;padding-right: 8px;">
                                        <img src="${this.assetLocal("global_assets/images/logo-rsh-mark.png")}" style="width: 100%;" alt="">
                                    </div>
                                    <div style="display: table-cell; clear: both;"></div>
                                    </td>
                                </tr>
                                </tbody></table>
                            </td>
                            </tr>
                            <tr>
                            <td style="padding:0;">
                                <table style="border-collapse: collapse;width: 100%;">
                                <tbody>
                                    <tr>
                                    <td style="padding:12px 24px;" colspan="2">
                                        <div style="font-size: 16px;line-height: 26px;">
                                        <table style="width:100%;margin: 0;">
                                            <tbody><tr>
                                            <td width="80">Nama</td>
                                            <td width="20" style="text-align:center;">:</td>
                                            <td style="border-bottom: 1px solid;">${row.ap_fullname}</td>
                                            </tr>
                                            <tr>
                                            <td width="80">No RM</td>
                                            <td width="20" style="text-align:center;">:</td>
                                            <td style="border-bottom: 1px solid;">${row.ap_code}</td>
                                            </tr>
                                            <tr>
                                            <td width="80">Tgl Lahir</td>
                                            <td width="20" style="text-align:center;">:</td>
                                            <td style="border-bottom: 1px solid;">${dob}</td>
                                            </tr>
                                            <tr>
                                            <td width="80">NIK</td>
                                            <td width="20" style="text-align:center;">:</td>
                                            <td style="border-bottom: 1px solid;">${row.ap_nik}</td>
                                            </tr>
                                            <tr>
                                            <td width="80">Tgl Masuk</td>
                                            <td width="20" style="text-align:center;">:</td>
                                            <td style="border-bottom: 1px solid;">${reg_date}</td>
                                            </tr>
                                            <tr>
                                            <td width="80">Ruangan</td>
                                            <td width="20" style="text-align:center;">:</td>
                                            <td style="border-bottom: 1px solid;">${row.mranb_name}</td>
                                            </tr>
                                            <tr>
                                            <td width="80">Tgl Berlaku</td>
                                            <td width="20" style="text-align:center;">:</td>
                                            <td style="border-bottom: 1px solid;">${tgl_berlaku}</td>
                                            </tr>
                                        </tbody></table>
                                        <p style="margin: 0; font-size: 12px; line-height: normal;">- Berlaku untuk 1 orang </p>
                                        <p style="margin: 0; font-size: 12px; line-height: normal;">- Wajib dikenakan pada saat menunggu pasien </p>
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                            </td>
                            </tr>
                        </tbody></table>
                        </div>
                    </div>
                    </div>
                    `
                    +
                    '</body></html>'
                document.write(pageContent)
                document.close()
                newWindow.moveTo(0, 0)
                newWindow.resizeTo(screen.width, screen.height)
                setTimeout(function() {
                    newWindow.print()
                    newWindow.close()
                }, 250)
            }
        },
        filters: {
            date(val) {
                return val ? moment(val).format("D MMM YYYY") : ""
            }
        },
        watch:{
            $route(){
                this.apiGet()
                if(this.isList){
                    this.apiGetResume()
                }
                
                setTimeout(()=>{
                    if(this.isList){
                        this.setSlide()
                    }
                },1000)
            }
        }
    }
</script>