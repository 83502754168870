<template>
    <div class="content-wrapper overflow-visible">
        <div class="page-header page-header-sticky page-header-sticky-open">
            <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">
                <div class="page-header-info flex-1 py-3 text-uppercase">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                                    <span>{{row.ap_fullname||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                        title="No Rekam Medis">{{row.ap_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                        title="No Registrasi">{{row.aur_reg_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                        title="No KTP">{{row.ap_nik||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                        title="No SEP">{{row.noSEP||"-"}}</span>
                                </h5>
                            </div>
                            <div class="phi-sub-info d-flex">
                                <div class="phi-info d-flex flex-column">
                                    <small>Tanggal Lahir</small>
                                    <strong
                                        class="font-weight-semibold">{{row.ap_dob | moment("DD MMM YYYY")}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Usia</small>
                                    <strong
                                        class="font-weight-semibold">{{row.ap_usia_with_ket || "-"}}<br />({{row.ap_gol_usia||"-"}})</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Jenis Kelamin</small>
                                    <strong class="font-weight-semibold">{{row.cg_label}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Opsi Pembayaran</small>
                                    <strong class="font-weight-semibold">{{row.mcp_name || "-"}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Agama</small>
                                    <strong class="font-weight-semibold">{{row.ap_agama_text || "-"}}</strong>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
                <i class="icon-arrow-up5 align-middle"></i>
                <span class="align-middle">Sembunyikan Informasi Pasien</span>
            </a>
        </div>
        
        <div class="content">
            <div class="card">
                <div class="card-header">
                    <div class="d-flex align-items-center">
                        <h6 class="card-title font-weight-semibold mr-auto">Dokumen Pasien Rawat Inap</h6>
                    </div>
                </div>
                <table class="table table-bordered table-sm patient-table">
                    <thead>
                        <tr>
                            <th>Nama Dokumen</th>
                            <th>Status</th>
                            <th>Aksi</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Persetujuan Umum</td>
                        <td>
                            <span v-if="row.aranres_general_consent_status == 'U'" class="badge badge-info">Unggah TTD</span>
                            <span v-else-if="row.aranres_general_consent_status == 'DD'" class="badge badge-success">Dokumen TTD Diunggah</span>
                            <span v-else-if="row.aranres_general_consent_status == 'DT'" class="badge badge-success">Di Tandatangani</span>
                            <span v-else> - </span>
                        </td>
                        <td>
                            <a href="javascript:;" @click="downloadFile('aranres_general_consent_temp',row.aranres_id,'General Consent')" v-if="row.aranres_general_consent_status == 'U' || row.aranres_general_consent_status == 'DD'" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Unduh Dokumen Template"><i class="icon-download"></i></a>

                            <a href="javascript:;" @click="modalUpload('aranres_general_consent_file','aranres_general_consent_status')" v-if="row.aranres_general_consent_status == 'U' || row.aranres_general_consent_status == 'DD'" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right title="Unggah Dokumen TTD"><i class="icon-upload"></i></a>
                            
                            <a href="javascript:;" @click="downloadFile('aranres_general_consent_temp',row.aranres_id,'General Consent')" v-if="row.aranres_general_consent_status == 'DT'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Digital"><i class="icon-download"></i></a>
                            
                            <a :href="$parent.uploader(row.aranres_general_consent_file)" target="_blank"  v-if="row.aranres_general_consent_status == 'DD'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Manual"><i class="icon-download"></i></a>
                        </td>
                    </tr>
                    <tr>
                        <td>Persetujuan Rawat Inap Pasien</td>
                        <td>
                            <span v-if="row.aranres_pd_status == 'U'" class="badge badge-info">Unggah TTD</span>
                            <span v-else-if="row.aranres_pd_status == 'DD'" class="badge badge-success">Dokumen TTD Diunggah</span>
                            <span v-else-if="row.aranres_pd_status == 'DT'" class="badge badge-success">Di Tandatangani</span>
                            <span v-else> - </span>
                        </td>
                        <td>
                            <a href="javascript:;" @click="downloadFile('aranres_pd_temp',row.aranres_id,'Persetujuan Rawat Inap')" v-if="row.aranres_pd_status == 'U' || row.aranres_pd_status == 'DD'" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Unduh Dokumen Template"><i class="icon-download"></i></a>

                            <a href="javascript:;" @click="modalUpload('aranres_pd_file','aranres_pd_status')" v-if="row.aranres_pd_status == 'U' || row.aranres_pd_status == 'DD'" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right title="Unggah Dokumen TTD"><i class="icon-upload"></i></a>
                            
                            <a href="javascript:;" @click="downloadFile('aranres_pd_temp',row.aranres_id,'Persetujuan Rawat Inap')" v-if="row.aranres_pd_status == 'DT'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Digital"><i class="icon-download"></i></a>
                            
                            <a :href="$parent.uploader(row.aranres_pd_file)" target="_blank" v-if="row.aranres_pd_status == 'DD'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Manual"><i class="icon-download"></i></a>
                        </td>
                    </tr>

                    <tr v-if="row.aranres_jaminan == Config.var.isBPJS && row.aranres_is_naik == 'Y'">
                        <td>Pernyataan Naik Kelas <span class="font-weight-semibold text-danger-800">(Perlu Materai)</span></td>
                        <td>
                            <span v-if="row.aranres_pj_status == 'U'" class="badge badge-info">Unggah TTD</span>
                            <span v-else-if="row.aranres_pj_status == 'DD'" class="badge badge-success">Dokumen TTD Diunggah</span>
                            <span v-else-if="row.aranres_pj_status == 'DT'" class="badge badge-success">Di Tandatangani</span>
                            <span v-else> - </span>
                        </td>
                        <td>
                            <a href="javascript:;" @click="downloadFile('aranres_pj_template',row.aranres_id,'Pernyataan Naik Kelas')" v-if="row.aranres_pj_status == 'U' || row.aranres_pj_status == 'DD'" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Unduh Dokumen Template"><i class="icon-download"></i></a>

                            <a href="javascript:;" @click="modalUpload('aranres_pj_file','aranres_pj_status')" v-if="row.aranres_pj_status == 'U' || row.aranres_pj_status == 'DD'" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right title="Unggah Dokumen TTD"><i class="icon-upload"></i></a>
                            
                            <a href="javascript:;" @click="downloadFile('aranres_pj_template',row.aranres_id,'Pernyataan Naik Kelas')" v-if="row.aranres_pj_status == 'DT'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Digital"><i class="icon-download"></i></a>
                            
                            <a :href="$parent.uploader(row.aranres_pj_file)" target="_blank" v-if="row.aranres_pj_status == 'DD'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Manual"><i class="icon-download"></i></a>
                        </td>
                    </tr>

                    <tr v-if="row.aranres_jaminan == Config.var.isBPJS && row.aranres_is_naik == 'N'">
                        <td>Pernyataan Turun Kelas</td>
                        <td>
                            <span v-if="row.aranres_pj_status == 'U'" class="badge badge-info">Unggah TTD</span>
                            <span v-else-if="row.aranres_pj_status == 'DD'" class="badge badge-success">Dokumen TTD Diunggah</span>
                            <span v-else-if="row.aranres_pj_status == 'DT'" class="badge badge-success">Di Tandatangani</span>
                            <span v-else> - </span>
                        </td>
                        <td>
                            <a href="javascript:;" @click="downloadFile('aranres_pj_template',row.aranres_id,'Pernyataan Turun Kelas')" v-if="row.aranres_pj_status == 'U' || row.aranres_pj_status == 'DD'" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Unduh Dokumen Template"><i class="icon-download"></i></a>

                            <a href="javascript:;" @click="modalUpload('aranres_pj_file','aranres_pj_status')" v-if="row.aranres_pj_status == 'U' || row.aranres_pj_status == 'DD'" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right title="Unggah Dokumen TTD"><i class="icon-upload"></i></a>
                            
                            <a href="javascript:;" @click="downloadFile('aranres_pj_template',row.aranres_id,'Pernyataan Turun Kelas')" v-if="row.aranres_pj_status == 'DT'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Digital"><i class="icon-download"></i></a>
                            
                            <a :href="$parent.uploader(row.aranres_pj_file)" target="_blank" v-if="row.aranres_pj_status == 'DD'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Manual"><i class="icon-download"></i></a>
                        </td>
                    </tr>

                    <tr v-if="row.aranres_jaminan == Config.var.isAsuransi">
                        <td>Pernyataan Pasien Asuransi</td>
                        <td>
                            <span v-if="row.aranres_pj_status == 'U'" class="badge badge-info">Unggah TTD</span>
                            <span v-else-if="row.aranres_pj_status == 'DD'" class="badge badge-success">Dokumen TTD Diunggah</span>
                            <span v-else-if="row.aranres_pj_status == 'DT'" class="badge badge-success">Di Tandatangani</span>
                            <span v-else> - </span>
                        </td>
                        <td>
                            <a href="javascript:;" @click="downloadFile('aranres_pj_template',row.aranres_id,'Pernyataan Pasien Asuransi')" v-if="row.aranres_pj_status == 'U' || row.aranres_pj_status == 'DD'" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Unduh Dokumen Template"><i class="icon-download"></i></a>

                            <a href="javascript:;" @click="modalUpload('aranres_pj_file','aranres_pj_status')" v-if="row.aranres_pj_status == 'U' || row.aranres_pj_status == 'DD'" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right title="Unggah Dokumen TTD"><i class="icon-upload"></i></a>
                            
                            <a href="javascript:;" @click="downloadFile('aranres_pj_template',row.aranres_id,'Pernyataan Pasien Asuransi')" v-if="row.aranres_pj_status == 'DT'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Digital"><i class="icon-download"></i></a>
                            
                            <a :href="$parent.uploader(row.aranres_pj_file)" target="_blank" v-if="row.aranres_pj_status == 'DD'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Manual"><i class="icon-download"></i></a>
                        </td>
                    </tr>
                    
                    <tr v-if="row.aranres_jaminan == Config.var.isUmum">
                        <td>Pernyataan Pasien Non-Asuransi</td>
                        <td>
                            <span v-if="row.aranres_pj_status == 'U'" class="badge badge-info">Unggah TTD</span>
                            <span v-else-if="row.aranres_pj_status == 'DD'" class="badge badge-success">Dokumen TTD Diunggah</span>
                            <span v-else-if="row.aranres_pj_status == 'DT'" class="badge badge-success">Di Tandatangani</span>
                            <span v-else> - </span>
                        </td>
                        <td>
                            <a href="javascript:;" @click="downloadFile('aranres_pj_template',row.aranres_id,'Pernyataan Pasien Non-Asuransi')" v-if="row.aranres_pj_status == 'U' || row.aranres_pj_status == 'DD'" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Unduh Dokumen Template"><i class="icon-download"></i></a>

                            <a href="javascript:;" @click="modalUpload('aranres_pj_file','aranres_pj_status')" v-if="row.aranres_pj_status == 'U' || row.aranres_pj_status == 'DD'" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right title="Unggah Dokumen TTD"><i class="icon-upload"></i></a>
                            
                            <a href="javascript:;" @click="downloadFile('aranres_pj_template',row.aranres_id,'Pernyataan Pasien Non-Asuransi')" v-if="row.aranres_pj_status == 'DT'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Digital"><i class="icon-download"></i></a>
                            
                            <a :href="$parent.uploader(row.aranres_pj_file)" target="_blank" v-if="row.aranres_pj_status == 'DD'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Manual"><i class="icon-download"></i></a>
                        </td>
                    </tr>
                    
                    <tr v-if="row.aranres_bm_is == 'Y'">
                        <td>Pernyataan Belum Menikah <span class="font-weight-semibold text-danger-800">(Perlu Materai)</span></td>
                        <td>
                            <span v-if="row.aranres_bm_status == 'U'" class="badge badge-info">Unggah TTD</span>
                            <span v-else-if="row.aranres_bm_status == 'DD'" class="badge badge-success">Dokumen TTD Diunggah</span>
                            <span v-else-if="row.aranres_bm_status == 'DT'" class="badge badge-success">Di Tandatangani</span>
                            <span v-else> - </span>
                        </td>
                        <td>
                            <a href="javascript:;" @click="downloadFile('aranres_bm_temp',row.aranres_id,'Pernyataan Belum Menikah')" v-if="row.aranres_bm_status == 'U' || row.aranres_bm_status == 'DD'" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Unduh Dokumen Template"><i class="icon-download"></i></a>

                            <a href="javascript:;" @click="modalUpload('aranres_bm_file','aranres_bm_status')"  v-if="row.aranres_bm_status == 'U' || row.aranres_bm_status == 'DD'" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right title="Unggah Dokumen TTD"><i class="icon-upload"></i></a>
                            
                            <a href="javascript:;" @click="downloadFile('aranres_bm_temp',row.aranres_id,'Pernyataan Belum Menikah')" v-if="row.aranres_bm_status == 'DT'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Digital"><i class="icon-download"></i></a>
                            
                            <a :href="$parent.uploader(row.aranres_bm_file)" target="_blank" v-if="row.aranres_bm_status == 'DD'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Manual"><i class="icon-download"></i></a>
                        </td>
                    </tr>
                    
                    <tr v-if="row.aranres_kn_is == 'Y'">
                        <td>Keterangan Nikah Secara Agama <span class="font-weight-semibold text-danger-800">(Perlu Materai)</span></td>
                        <td>
                            <span v-if="row.aranres_kn_status == 'U'" class="badge badge-info">Unggah TTD</span>
                            <span v-else-if="row.aranres_kn_status == 'DD'" class="badge badge-success">Dokumen TTD Diunggah</span>
                            <span v-else-if="row.aranres_kn_status == 'DT'" class="badge badge-success">Di Tandatangani</span>
                            <span v-else> - </span>
                        </td>
                        <td>
                            <a href="javascript:;" @click="downloadFile('aranres_kn_temp',row.aranres_id,'Keterangan Nikah Secara Agama')" v-if="row.aranres_kn_status == 'U' || row.aranres_kn_status == 'DD'" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Unduh Dokumen Template"><i class="icon-download"></i></a>

                            <a href="javascript:;" @click="modalUpload('aranres_kn_file','aranres_kn_status')" v-if="row.aranres_kn_status == 'U' || row.aranres_kn_status == 'DD'" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right title="Unggah Dokumen TTD"><i class="icon-upload"></i></a>
                            
                            <a href="javascript:;" @click="downloadFile('aranres_kn_temp',row.aranres_id,'Keterangan Nikah Secara Agama')" v-if="row.aranres_kn_status == 'DT'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Digital"><i class="icon-download"></i></a>
                            
                            <a :href="$parent.uploader(row.aranres_kn_file)" target="_blank" v-if="row.aranres_kn_status == 'DD'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Manual"><i class="icon-download"></i></a>
                        </td>
                    </tr>

                    <tr v-if="row.aranres_kron_is == 'Y'">
                        <td>Pernyataan Kronologi Kejadian <span class="font-weight-semibold text-danger-800">(Perlu Materai)</span></td>
                        <td>
                            <span v-if="row.aranres_kron_status == 'U'" class="badge badge-info">Unggah TTD</span>
                            <span v-else-if="row.aranres_kron_status == 'DD'" class="badge badge-success">Dokumen TTD Diunggah</span>
                            <span v-else-if="row.aranres_kron_status == 'DT'" class="badge badge-success">Di Tandatangani</span>
                            <span v-else> - </span>
                        </td>
                        <td>
                            <a href="javascript:;" @click="downloadFile('aranres_kron_temp',row.aranres_id,'Keterangan Kronologi Kejadian')" v-if="row.aranres_kron_status == 'U' || row.aranres_kron_status == 'DD'" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Unduh Dokumen Template"><i class="icon-download"></i></a>

                            <a href="javascript:;" @click="modalUpload('aranres_kron_file','aranres_kron_status')" v-if="row.aranres_kron_status == 'U' || row.aranres_kron_status == 'DD'" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right title="Unggah Dokumen TTD"><i class="icon-upload"></i></a>
                            
                            <a href="javascript:;" @click="downloadFile('aranres_kron_temp',row.aranres_id,'Keterangan Kronologi Kejadian')" v-if="row.aranres_kron_status == 'DT'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Digital"><i class="icon-download"></i></a>
                            
                            <a :href="$parent.uploader(row.aranres_kron_file)" target="_blank" v-if="row.aranres_kron_status == 'DD'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Manual"><i class="icon-download"></i></a>
                        </td>
                    </tr>

                    <tr v-if="row.surat_pengantar">
                        <td>Surat Pengantar Rawat Inap</td>
                        <td>
                            <span> - </span>
                        </td>
                        <td>
                            <a href="javascript:;" @click="doOpenPengantar()" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Digital"><i class="icon-download"></i></a>
                        </td>
                    </tr>
                    
                    <tr v-if="row.surat_penetapan_dpjp">
                        <td>Surat Penetapan DPJP</td>
                        <td>
                            <span> - </span>
                        </td>
                        <td>
                            <a :href="$parent.uploader(row.surat_penetapan_dpjp)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Digital"><i class="icon-download"></i></a>
                        </td>
                    </tr>

                    
                    </tbody>
                </table>
            </div>
        </div>

        <b-modal v-model="uploadModal" :title="'Tanda tangani Dokumen'" size="md" hide-footer>
            <validation-observer ref="VFormUpload">
                <b-form @submit.prevent="doSubmitUpload">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <div>
                                <Uploader v-model="rowUpload.file" isDocument type="doc_only" />
                                <VValidate 
                                    name="Dokumen" 
                                    v-model="rowUpload.file" 
                                    :rules="{required : 1}"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-12">
                        <div class="text-right">
                        <button type="button" class="btn" @click="uploadModal = false" data-dismiss="modal">Batal</button>
                        <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i> 
                        </button>
                        </div>
                    </div>
                </div>
                </b-form>
            </validation-observer>
            
        </b-modal>

        
        <b-modal v-model="openPengantar" @ok.prevent="doSubmitPengantar" title="Surat Pengantar Rawat Inap" size="lg">
            <div class="card-body">
                <table class="table table-bordered table-sm text-uppercase">
                    <tbody>
                    <tr>
                        <td width="33%">
                        <div class="result_tab">
                            <h4>Nama Pasien</h4>
                            <p>{{pengantarRanap.ap_fullname||"-"}}</p>
                        </div>
                        </td>
                        <td width="33%">
                        <div class="result_tab">
                            <h4>Tanggal Lahir</h4>
                            <p>{{pengantarRanap.ap_dob | moment("DD MMMM YYYY")}}</p>
                        </div>
                        </td>
                        <td width="33%">
                        <div class="result_tab">
                            <h4>Jenis Kelamin </h4>
                            <p>{{pengantarRanap.ap_gender == 1 ? "Laki-Laki":"Perempuan"}}</p>
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <div class="result_tab">
                            <h4>No. Rekam Medis</h4>
                            <p>{{pengantarRanap.ap_code||"-"}}</p>
                        </div>
                        </td>
                        <td>
                        <div class="result_tab">
                            <h4>DR. Jaga/DPJP </h4>
                            <p>{{pengantarRanap.bu_full_name||"-"}}</p>
                        </div>
                        </td>
                        <td>
                        <div class="result_tab">
                            <h4>Diagnosa Awal</h4>
                            <p>{{pengantarRanap.aranspr_diagnosa_awal||"-"}}</p>
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <div class="result_tab">
                                <h4>Tanggal Masuk/Jam</h4>
                                <div class="input-group">
                                    <datepicker input-class="form-control transparent h-38" placeholder="Tanggal"
                                        class="my-datepicker" calendar-class="my-datepicker_calendar"
                                        v-model="pengantarRanap.aranspr_tanggal_masuk">
                                    </datepicker>
                                    <vue-timepicker manual-input style="height: 38px !important;" format="HH:mm" placeholder="Jam" input-class="form-control h-38" v-model="pengantarRanap.aranspr_jam_masuk">
                                    </vue-timepicker>
                                    <div class="input-group-append calendar-group">
                                        <span class="input-group-text" id="basic-addon2"><i
                                            class="icon-calendar"></i></span>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="result_tab">
                                <h4>Tindakan Medis</h4>
                                <b-form-textarea v-model="pengantarRanap.aranspr_tindakan_medis" rows="4" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <div class="result_tab">
                                <h4>Rencana Tanggal Masuk/Jam</h4>
                                <div class="input-group">
                                    <datepicker input-class="form-control transparent h-38" placeholder="Tanggal"
                                        class="my-datepicker" calendar-class="my-datepicker_calendar"
                                        v-model="pengantarRanap.aranspr_rencana_tanggal_masuk">
                                    </datepicker>
                                    <vue-timepicker manual-input style="height: 38px !important;" format="HH:mm" placeholder="Jam" input-class="form-control h-38" v-model="pengantarRanap.aranspr_rencana_jam_masuk">
                                    </vue-timepicker>
                                    <div class="input-group-append calendar-group">
                                        <span class="input-group-text" id="basic-addon2"><i
                                            class="icon-calendar"></i></span>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="result_tab">
                                <h4>Persiapan Pre Operasi</h4>
                                <b-form-textarea v-model="pengantarRanap.aranspr_pre_operasi" rows="4" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <div class="result_tab">
                                <h4>Keterangan</h4>
                                <b-form-checkbox-group
                                    class="checkbox-inline mt-2"
                                    v-model="pengantarRanap.aranspr_keterangan"
                                    :options="Config.mr.keteranganFormulir"
                                    name="Keterangan"
                                ></b-form-checkbox-group>
                                <template v-if="(pengantarRanap.aranspr_keterangan||[]).indexOf('Lainnya') != -1">
                                    <b-form-group>
                                        <b-form-input placeholder="Keterangan Lainnya" type="text" class="form-control" v-model="pengantarRanap.aranspr_keterangan_lain"/>
                                    </b-form-group>
                                </template>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>
    </div>  
</template>


<script>
    import $ from 'jquery'
    const _ = global._
    const moment = require('moment')
    import Gen from '@/libs/Gen.js'
    import Config from '@/libs/Config'
    import Datepicker from 'vuejs-datepicker'
    import VueTimepicker from 'vue2-timepicker'
    
    export default{
        props:{
            row:Object,
            mrValidation:Object
        },
        data() {
            return {
                uploadModal: false,
                rowUpload: {},
                pengantarRanap: {},
                openPengantar: false
            }   
        },
        components: {
            Datepicker,VueTimepicker
        },
        computed: {
            Config() {
                return Config
            },
        },
        methods: {
            doSubmitPengantar(){
                this.loadingOverlay = true                        
                let data = this.pengantarRanap
                data.type = "submit-pengantar",
            
                Gen.apiRest(
                    "/do/"+'UGDAssesment', 
                    {
                        data: data
                    }, "POST"
                ).then(()=>{
                    this.loadingOverlay = false   

                    let self = this
                    let dataPengantar = {
                        exptype: 'pdf',
                        type: "export",
                        id: this.pengantarRanap.aranspr_id
                    }
                    // self.loadingOverlay = true
                    $.ajax({
                        type: "POST",
                        url: process.env.VUE_APP_API_URL + `/report/${'suratPengantarRanap'}?token=IXs1029102asoaksoas102901290`,
                            data: dataPengantar,
                            cache: false,
                            xhrFields: {
                            responseType: 'blob'
                        },
                        success: data => {
                            self.loadingOverlay = false
                            self.openPengantar = false
                            self.openReport = false

                            var link = document.createElement('a')
                            link.href = window.URL.createObjectURL(data)
                            link.download = `${'Surat-Pengantar-Ranap'}-${moment().format("YYYY-MM-DD")}.pdf`
                            link.click()
                        },
                        fail: data => {
                            self.loadingOverlay = false
                            alert('Not downloaded')
                        }
                    })
                })
            },
            doOpenPengantar(){
                this.openPengantar=true
                this.pengantarRanap = this.row.pengantarRanap
            },
            downloadSuratPengantar(){
                let self = this
                let dataPengantar = {
                    exptype: 'pdf',
                    type: "export",
                    id: this.row.surat_pengantar
                }
                self.$parent.loadingOverlay = true
                $.ajax({
                    type: "POST",
                    url: process.env.VUE_APP_API_URL + `/report/${'suratPengantarRanap'}?token=IXs1029102asoaksoas102901290`,
                        data: dataPengantar,
                        cache: false,
                        xhrFields: {
                        responseType: 'blob'
                    },
                    success: data => {
                        self.$parent.loadingOverlay = false

                        var link = document.createElement('a')
                        link.href = window.URL.createObjectURL(data)
                        link.download = `${'Surat-Pengantar-Ranap'}-${moment().format("YYYY-MM-DD")}.pdf`
                        link.click()
                    },
                    fail: data => {
                        self.$parent.loadingOverlay = false
                        alert('Not downloaded')
                    }
                })
            },
            modalUpload(fileField,statusField){
                this.uploadModal = true
                this.rowUpload = {
                    id : this.row.aranres_id,
                    file : this.row[fileField],
                    fileField : fileField,
                    statusField : statusField,
                }
            },
            doSubmitUpload(){
                this.$refs['VFormUpload'].validate().then(success => {
                    if (!success) {
                        return this.$swal({
                            icon: 'error',
                            title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                        }).then(result => {
                            if (result.value) {
                                setTimeout(() => {
                                    let inv = []
                                    let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                                    for (let i = 0; i < (el || []).length; i++) {
                                        if (el[i].style.display !== 'none') {
                                        inv.push(el[i].id)
                                        }
                                    }
                                },250)
                            }
                        })
                    }
                    if (success) {
                        this.$swal({
                            icon: 'warning',
                            title: 'Apakah Anda Yakin akan menyimpan data ini?',
                            showCancelButton: true,
                            confirmButtonText: 'Ya',
                            cancelButtonText: 'Tidak, kembali'
                        }).then(result => {
                            if(result.value){
                                let data = this.rowUpload
                                data.type = "upload-ttd"
                                
                                this.$parent.loadingOverlay = true
                                Gen.apiRest(
                                "/do/" + 'RanapReservasi', {
                                    data: data
                                },
                                "POST"
                                ).then(res => {
                                    this.$parent.loadingOverlay = false
                                    let resp = res.data
                                    resp.statusType = 200
                                    this.$swal({
                                        title: resp.message,
                                        icon: resp.status,
                                        confirmButtonText: 'Ok',
                                        allowOutsideClick: false,
                                        allowEscapeKey: false
                                    }).then(result => {
                                        this.uploadModal = false
                                        this.rowUpload = {}                                    
                                        this.$parent.apiGet()
                                    })
                                }).catch(err => {
                                    this.uploadModal = false
                                    this.rowUpload = {}
                                    this.$parent.loadingOverlay = false
                                    if (err) {
                                        err.statusType = err.status
                                        err.status = "error"
                                        err.title = err.response?.data?.title
                                        err.message = err.response?.data?.message
                                        err.messageError = err.message
                                    }
                                    this.$parent.doSetAlertForm(err)
                                })
                            }
                        })
                    }
                })
            },
            initSticky() {
                const PageHeaderSticky = document.querySelector('.page-header-sticky')
                if (PageHeaderSticky) {
                    const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
                    if ($(".page-header-sticky").length && $(".page-header-sticky-toggle").length) {
                        PageHeaderToggle.addEventListener('click', function (e) {
                            // e.preventDefault()
                            PageHeaderSticky.classList.toggle('page-header-sticky-open')
                            if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                                PageHeaderToggle.querySelector('span').innerText =
                                    'Sembunyikan Informasi Pasien'
                                PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                                PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
                            } else {
                                PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                                PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                                PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
                            }
                        })
                    }
                }
            },
            downloadFile(field,id,val){
                let data = {exptype: 'xlsx', type: "export", id: id, field: field}
                let self = this
                self.$parent.loadingOverlay = true
                $.ajax({
                    type: "POST",
                    url: process.env.VUE_APP_API_URL + `/dokumen-ttd-ranap/${'DokumenTTD'}?token=IXs1029102asoaksoas102901290`,
                    data: data,
                    cache: false,
                    xhrFields:{
                        responseType: 'blob'
                    },
                    success: data => 
                    {
                        self.$parent.loadingOverlay = false
                        var link = document.createElement('a')
                        link.href = window.URL.createObjectURL(data)
                        link.download = `${val}-${moment().format("YYYY-MM-DD")}.pdf`
                        link.click()
                    },
                    fail: data => {
                        self.$parent.loadingOverlay = false
                        alert('Not downloaded')
                    }
                })
            },
            getConfigDynamic(master, value, val = 'text') {
                let text = ''
                if (value) {
                let index = (master || []).findIndex(x => x.value == value)
                if (index !== -1) {
                    text = master[index][val]
                }
                }
                return text
            },
        }, 
        mounted() {
            setTimeout(() => {
                this.initSticky()
            }, 1000)
        },
    }   

</script>