<template>
    <div class="content pt-0 mt-2">
        <div class="card mb-1">
          <div class="card-body">
            <div class="row align-items-center justify-content-between">
              <div class="col-md-4">
                    
                <div class="badge badge-dark mb-1 mr-1" v-if="row.aranr_is_void == 'Y'">
                    VOID
                </div>
                <template v-else>
                    <div v-if="!row.aranr_status" :class="`badge badge bg-warning`">Pendaftaran Baru</div>
                    <div v-else :class="`badge ${row.arans_color}`">{{uppercaseWord(row.arans_name)||'-'}}</div>
                </template>

                <p class="mb-0 mt-1"><span class="text-success font-weight-semibold">{{rowPasien.ap_gol_usia||"-"}}</span></p>
                
                <p  v-if="row.aranr_is_finger_bpjs != 'Y' && (row.aranres_jaminan == 3)" class="mb-0 mt-1"><span class="text-warning font-weight-semibold">Belum Finger Print BPJS</span>
                </p>
              </div>
              <div class="col-md-8">
              

                <router-link href="javascript:;" :to="{name: 
                    $route.name, params: {pageSlug: row.aranres_id}}" 
                    class="btn float-right btn-primary btn-labeled btn-labeled-left">
                    <b><i class="icon-pencil7"></i></b>
                    Edit Data
                </router-link>

                <a href="javascript:;" v-if="row.aranres_jaminan == Config.var.isBPJS && row.showSEP" @click="addSEPDataKontrol" data-popup="tooltip" title="Buat SEP" data-toggle="modal"
                class="btn float-right mr-1 btn-success btn-labeled btn-labeled-left">
                <b><i class="icon-check"></i></b>
                Buat SEP
                </a>
                
                <router-link :to="{name: 'BPJSDataSEP', params: {pageSlug: row.idSEP}, query:{ detail: row.noSEP ? true : ''}}" v-if="row.aranres_jaminan == Config.var.isBPJS && !row.showSEP" class="btn float-right btn-warning mr-1 btn-labeled btn-labeled-left">
                    <b><i class="icon-eye"></i></b>
                    <span v-if="row.noSEP"> Lihat SEP</span>
                    <span v-else>Buat SEP</span>
                </router-link>

                <a href="javascript:;" v-b-tooltip.hover="'Buat Ulang SEP'" @click="deleteSEP()" v-if="row.aranres_jaminan == 3 && !row.showSEP && !row.noSEP" class="btn float-right btn-info mr-1">
                    <b><i class="icon-upload"></i></b>
                    Buat Ulang SEP
                </a>
                
                <router-link :to="{name: $route.name, params: {pageSlug: row.aranres_id}, query: {isDoc: true}}" class="btn float-right btn-info mr-1">
                  <b><i class="icon-file-check"></i></b>
                  Dokumen Persetujuan
                </router-link>

          
                <a href="javascript:;" v-if="row.aranr_is_finger_bpjs != 'Y' && row.aranres_jaminan == 3"
                class="btn float-right btn-warning btn-labeled btn-labeled-left mr-1" @click="openPopupBPJS">
                <b><i class="icon-point-up"></i></b>
                Finger Print BPJS
                </a>

              </div>
            </div>
          </div>
        </div>

        <div class="card mb-1">
          <ul class="nav nav-tabs nav-tabs-highlight nav-justified mb-0">
            <li class="nav-item"><a href="javascript:;" @click="changeTabU(1)" 
            :class="activeTabU == 1 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Informasi Reservasi</a></li>
            <li class="nav-item"><a href="javascript:;" @click="changeTabU(2)" 
            :class="activeTabU == 2 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Informasi Persetujuan</a></li>
          </ul>
          <div class="tab-content">
            <div :class="activeTabU == 1 ? 'tab-pane fade show active' : 'tab-pane fade'">
              <div class="card-header bg-white">
                <h6 class="card-title font-weight-semibold">Data Reservasi {{row.aranr_reg_code||"-"}} - 
                {{rowPasien.ap_fullname||"-"}}</h6>
              </div>
              <div class="card-body">
                <div class="row g-2">
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>No. Rekam Medis</h4>
                      <p>{{rowPasien.ap_code||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>Cara Bayar</h4>
                      <p>{{getConfigDynamic(Config.mr.mJaminan,row.aranres_jaminan) || "-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>Pemeriksaan </h4>
                      <p>Rawat Inap</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>Waktu Masuk Ranap</h4>
                      <p>{{row.aranres_tanggal_periksa | moment("DD MMMM YYYY")}} {{row.aranres_jam_periksa}} WIB</p>
                    </div>
                  </div>
                </div>
                <hr>
                <h6 class="font-weight-semibold">Data Rawat Inap</h6>
                <div class="row g-2">
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>DPJP Utama</h4>
                      <p>{{row.bu_full_name||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>Dokter Rawat Bersama</h4>
                      <p> - </p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>Jenis Rawat Inap</h4>
                      <p>{{row.mranjr_name||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>Unit Pelayanan</h4>
                      <p>{{row.mranu_name||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>Ruangan & Kamar</h4>
                      <p v-if="row.aranres_bed">
                        {{row.mranb_name}} - {{row.mrankel_name}} / {{row.mranbed_name}} / {{row.mrankel_name}}
                      </p>
                      <p v-else> - </p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>Tarif</h4>
                      <p v-if="row.aranres_tarif"> Rp {{$parent.toMoneyValue(row.aranres_tarif)}} </p>
                      <p v-else> - </p>
                    </div>
                  </div>
                </div>
                <hr>
                <h6 class="font-weight-semibold">Edukasi Pasien</h6>
                <h6 class="mt-2">Persiapan Edukasi</h6>
                <div class="row g-2">
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>Bahasa</h4>
                      <p>{{row.bahasa||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>Kebutuhan Penerjemah</h4>
                      <p>{{getConfigDynamic(Config.mr.yesNoOptV2,row.aranres_keb_penerjemah)||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>Baca dan Tulis</h4>
                      <p>{{row.aranres_baca_tulis||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>Tipe Pembelajaran</h4>
                      <p>{{row.tipe_pembelajaran||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>Pendidikan</h4>
                      <p v-if="row.aranres_pendidikan == 99999">{{row.aranres_pendidikan_text||"-"}}</p>
                      <p v-else>{{getConfigDynamic(mPendidikan,row.aranres_pendidikan)||"-"}}</p>
                      
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>Agama</h4>
                      <p v-if="row.aranres_agama == 99999">{{row.aranres_agama_text||"-"}}</p>
                      <p v-else>{{getConfigDynamic(mAgama,row.aranres_agama)||"-"}}</p>

                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>Hambatan Edukasi</h4>
                      <p>{{row.hambatan_edukasi||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>Ketersediaan Menerima Edukasi</h4>
                      <p>{{getConfigDynamic(Config.mr.yesNoOptV2,row.aranres_ketersediaan_edukasi)||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4></h4>
                      <p></p>
                    </div>
                  </div>
                </div>
                
                <h6 class="mt-3">Pemberian Edukasi</h6>
                <div class="row g-2">
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>Tanggal Edukasi</h4>
                      <p v-if="row.aranres_tanggal_edukasi">{{row.aranres_tanggal_edukasi | moment("DD MMMM YYYY")}}</p>
                      <p v-else> - </p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>Durasi Waktu</h4>
                      <p v-if="row.aranres_durasi_waktu">{{row.aranres_durasi_waktu}} menit</p>
                      <p v-else> - </p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>Materi Edukasi</h4>
                      <p>{{row.materi_edukasi||"-"}}</p>

                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>Sasaran </h4>
                      <p>{{row.aranres_sasaran||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>Tingkat Pemahaman Awal</h4>
                      <p>{{row.aranres_tingkat_pemahaman_awal||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>Metode Edukasi</h4>
                      <p>{{row.metode_edukasi||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>Sarana Edukasi</h4>
                      <p>{{row.sarana_edukasi||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>Edukator</h4>
                      <p>{{row.aranres_edukator||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>Evaluasi</h4>
                      <p>{{row.evaluasi||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="result_tab">
                      <h4>Tanggal Re-edukasi</h4>
                      <p v-if="row.aranres_tanggal_re_edukasi">{{row.aranres_tanggal_re_edukasi | moment("DD MMMM YYYY")}}</p>
                      <p v-else> - </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div :class="activeTabU == 2 ? 'tab-pane fade show active' : 'tab-pane fade'">
              <div class="card-body">
                <div class="card">
                  <div class="card-header bg-white">
                    <div class="d-flex align-items-center justify-content-between">
                      <h6 class="card-title font-weight-semibold">Informasi Persetujuan Umum</h6>

                      <a href="javascript:;" v-if="row.aranres_general_consent_status == 'DT'" @click="downloadFile('aranres_general_consent_temp',row.aranres_id,'General Consent')" class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-download"></i></b><span>Unduh Dokumen</span></a>
                      
                      <a :href="$parent.uploader(row.aranres_general_consent_file)" target="_blank" v-if="row.aranres_general_consent_status == 'DD'" class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-download"></i></b><span>Unduh Dokumen</span></a>

                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row g-2">
                      <div class="col-md-4">
                        <div class="result_tab">
                          <h4>Persetujuan Dilakukan Oleh</h4>
                          <p>{{getConfigDynamic(Config.mr.statusPersetujuanPasien,row.aranres_persetujuan_umum)||"-"}}</p>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="result_tab">
                          <h4>Wewenang Pelepasan Informasi Kepada</h4>
                          <p>{{wewenang||"-"}}</p>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="result_tab">
                          <h4>Partisipasi Mahasiswa dalam perawatan pasien</h4>
                          <p>{{getConfigDynamic(Config.mr.pasienSetuju,row.aranres_partisipasi)||"-"}}</p>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="result_tab">
                          <h4>Akses Menengok Pasien</h4>
                          <p>{{getConfigDynamic(Config.mr.izinYesNo,row.aranres_privasi)||"-"}}</p>
                        </div>
                      </div>
                      <div class="col-md-8">
                        <div class="result_tab">
                          <h4>Nama yang tidak diizinkan </h4>
                          <p>{{row.aranres_nama_yang_diizinkan||"-"}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mb-0">
                  <div class="card-header bg-white">
                    <div class="d-flex align-items-center justify-content-between">
                      <h6 class="card-title font-weight-semibold">Informasi Persetujuan Rawat Inap Pasien</h6>
                      
                      <a href="javascript:;" v-if="row.aranres_pd_status == 'DT'" @click="downloadFile('aranres_pd_temp',row.aranres_id,'Persetujuan Rawat Inap')" class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-download"></i></b><span>Unduh Dokumen</span></a>
                      
                      <a :href="$parent.uploader(row.aranres_pd_file)" target="_blank" v-if="row.aranres_pd_status == 'DD'" class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-download"></i></b><span>Unduh Dokumen</span></a>

                    </div>
                  </div>
                  
                  <div class="card-body">
                    <div class="row g-2">
                      <div class="col-md-4">
                        <div class="result_tab">
                          <h4>Nama Penandatangan</h4>
                          <p>{{row.aranres_pd_nama||"-"}}</p>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="result_tab">
                          <h4>No. KTP / NIK</h4>
                          <p>{{row.aranres_pd_no_ktp||"-"}}</p>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="result_tab">
                          <h4>No. Telp.</h4>
                          <p>+62{{row.aranres_pd_no_telp||"-"}}</p>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="result_tab">
                          <h4>Pekerjaan Pasien</h4>
                          <p v-if="row.aranres_pd_pekerjaan != 99999">{{getConfigDynamic(mPekerjaan,row.aranres_pd_pekerjaan)||"-"}}</p>
                          <p v-else>{{row.aranres_pd_pekerjaan_lainnya||"-"}}</p>
                        </div>
                      </div>
                      <div class="col-md-8">
                        <div class="result_tab">
                          <h4>Alamat </h4>
                          <p>{{row.aranres_pd_alamat||"-"}}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>

        
        <div class="card">
            <ul class="nav nav-tabs nav-tabs-highlight nav-justified mb-0">
                <li class="nav-item"><a href="javascript:;" @click="changeTab(1)" data-toggle="tab" data-target="#pasienTabInfo"
                    :class="activeTab == 1 ? 'nav-link active' : 'nav-link'">Informasi Pasien</a></li>
                <li class="nav-item"><a href="javascript:;" @click="changeTab(2)" data-toggle="tab" data-target="#pasienTabPJ"
                    :class="activeTab == 2 ? 'nav-link active' : 'nav-link'">Informasi Penanggung Jawab</a></li>
                <li class="nav-item"><a href="javascript:;" @click="changeTab(3)" data-toggle="tab" data-target="#pasienTabKW"
                    :class="activeTab == 3 ? 'nav-link active' : 'nav-link'">Informasi Kewenangan</a></li>
                <li class="nav-item"><a href="javascript:;" @click="changeTab(4)" data-toggle="tab" data-target="#pasienTabKW"
                    :class="activeTab == 4 ? 'nav-link active' : 'nav-link'">Dokumen</a></li>
            </ul>
            <div class="tab-content">
                <div :class="activeTab == 1 ? 'tab-pane fade show active' : 'tab-pane fade'"  id="pasien">
                    <div class="card-body">
                        <table class="table table-borderless table-sm text-uppercase">
                            <tbody>
                                <tr>
                                    <td width="33%">
                                    <div class="result_tab">
                                        <h4>No. Rekam Medis</h4>
                                        <p>{{rowPasien.ap_code||"-"}}</p>
                                    </div>
                                    </td>
                                    <td width="33%">
                                    <div class="result_tab">
                                        <h4>Nama</h4>
                                        <p>{{rowPasien.ap_fullname||"-"}}</p>
                                    </div>
                                    </td>
                                    <td width="33%">
                                    <div class="result_tab">
                                        <h4>NIK</h4>
                                        <p>{{rowPasien.ap_nik}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Tempat, Tanggal Lahir</h4>
                                        <p>{{rowPasien.ap_pob||"-"}}, {{rowPasien.ap_dob_true | moment("DD MMM YYYY")}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Usia</h4>
                                        <p>{{rowPasien.ap_usia_with_ket||"-"}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Kategori Usia</h4>
                                        <p>{{rowPasien.ap_gol_usia||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Jenis Kelamin</h4>
                                        <p>{{rowPasien.cg_label||"-"}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Agama</h4>
                                        <p v-if="rowPasien.ma_id !== 99999">{{rowPasien.ma_name||"-"}}</p>
                                        <p v-else>{{rowPasien.ap_agama_text||"-"}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Suku</h4>
                                        <p>{{rowPasien.mrsuk_name||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Pekerjaan</h4>
                                        <p v-if="rowPasien.mp_id !== 99999">{{rowPasien.mp_name||"-"}}</p>
                                        <p v-else>{{rowPasien.ap_pekerjaan_text||"-"}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Pendidikan</h4>
                                        <p>{{rowPasien.mpen_name||"-"}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Nama Ibu Kandung</h4>
                                        <p>{{rowPasien.ap_nama_ibu||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <div class="result_tab">
                                        <h4>No. Telpon</h4>
                                        <p>+62{{rowPasien.ap_phone_number||"-"}}</p>
                                    </div>
                                    </td>
                                    <td colspan="2">
                                    <div class="result_tab">
                                        <h4>Email</h4>
                                        <p v-if="rowPasien.ap_email">{{rowPasien.ap_email.trim()||"-"}}</p>
                                        <p v-else> - </p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Status Pernikahan</h4>
                                        <p v-if="rowPasien.ap_status_pernikahan == 1">Belum Kawin</p>
                                        <p v-else-if="rowPasien.ap_status_pernikahan == 2">Kawin</p>
                                        <p v-else-if="rowPasien.ap_status_pernikahan == 3">Cerai Hidup</p>
                                        <p v-else-if="rowPasien.ap_status_pernikahan == 4">Cerai Mati</p>
                                        <p v-else> - </p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Bahasa yang Dikuasai</h4>
                                        <p>{{rowPasien.ap_language||"-"}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Golongan Darah</h4>
                                        <p v-if="rowPasien.ap_gol_darah">{{rowPasien.ap_gol_darah.trim()||"-"}}</p>
                                        <p v-else> - </p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3">
                                    <div class="result_tab">
                                        <h4>Alamat Domisili</h4>
                                        <p>{{domisiliAddr||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3">
                                    <div class="result_tab">
                                        <h4>Alamat Sesuai KTP</h4>
                                        <p>{{ktpAddr||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div :class="activeTab == 2 ? 'tab-pane fade show active' : 'tab-pane fade'" id="penanggung-jawab">
                    <div class="card-body">
                        <table class="table table-borderless table-sm text-uppercase">
                            <tbody>
                            <tr>
                                <td width="33%">
                                <div class="result_tab">
                                    <h4>Hubungan Keluarga</h4>
                                    <p>{{rowPasien.arpj_hubungan_keluarga_text||"-"}}</p>
                                </div>
                                </td>
                                <td width="33%">
                                <div class="result_tab">
                                    <h4>Nama Penanggung Jawab</h4>
                                    <p>{{rowPasien.arpj_fullname||"-"}}</p>
                                </div>
                                </td>
                                <td width="33%">
                                <div class="result_tab">
                                    <h4>Pekerjaan </h4>
                                    <p v-if="rowPasien.arpj_pekerjaan !== 99999">{{rowPasien.pekerjaan_pj||"-"}}</p>
                                    <p v-else>{{rowPasien.arpj_pekerjaan_text||"-"}}</p>
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                <div class="result_tab">
                                    <h4>Jenis Kelamin</h4>
                                    <p>{{rowPasien.gender_pj||"-"}}</p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Agama </h4>
                                    <p v-if="rowPasien.arpj_agama !== 99999">{{rowPasien.agama_pj||"-"}}</p>
                                    <p v-else>{{rowPasien.arpj_agama_text||"-"}}</p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>No Handphone</h4>
                                    <p>+62{{rowPasien.arpj_phone_number}}</p>
                                </div>
                                </td>
                            </tr>

                            <tr>
                                <td colspan="3">
                                <div class="result_tab">
                                    <h4>Alamat Domisili</h4>
                                    <p>{{pjAddr||"-"}}</p>
                                </div>
                                </td>
                            </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                    
                <div :class="activeTab == 3 ? 'tab-pane fade show active' : 'tab-pane fade'" id="informasi-kewenangan">
                    <div class="card-body">
                        <table class="table table-borderless table-sm text-uppercase">
                            <tbody>
                            <tr>
                                <td width="33%">
                                <div class="result_tab">
                                    <h4>Hubungan Keluarga</h4>
                                    <p>{{rowPasien.arki_hubungan_keluarga_text||"-"}}</p>
                                </div>
                                </td>
                                <td width="33%">
                                <div class="result_tab">
                                    <h4>Nama Penanggung Jawab</h4>
                                    <p>{{rowPasien.arki_fullname||"-"}}</p>
                                </div>
                                </td>
                                <td width="33%">
                                <div class="result_tab">
                                    <h4>Pekerjaan </h4>
                                    <p v-if="rowPasien.arki_pekerjaan !== 99999">{{rowPasien.pekerjaan_ki||"-"}}</p>
                                    <p v-else>{{rowPasien.arki_pekerjaan_text||"-"}}</p>
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                <div class="result_tab">
                                    <h4>Jenis Kelamin</h4>
                                    <p>{{rowPasien.gender_ki||"-"}}</p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Agama </h4>
                                    <p v-if="rowPasien.arki_agama !== 99999">{{rowPasien.agama_ki||"-"}}</p>
                                    <p v-else>{{rowPasien.arki_agama_text||"-"}}</p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>No Handphone</h4>
                                    <p>+62{{rowPasien.arki_phone_number}}</p>
                                </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            
                <div :class="activeTab == 4 ? 'tab-pane fade show active' : 'tab-pane fade'" id="dokumen">
                    <div class="card-body">
                        <table class="table table-borderless table-sm text-uppercase">
                        <tbody>
                            <tr v-if="row.aranres_jaminan != 3">
                            <td width="25%">
                                <div class="result_tab">
                                <h4>Foto KTP/KK</h4>
                                <div class="d-flex align-items-center mt-1">
                                    <div>
                                    <a v-if="row.aranres_file_ktp" :href="$parent.uploader(row.aranres_file_ktp)" data-popup="tooltip" title=""
                                        class="btn alpha-primary border-primary text-primary-800 btn-icon"
                                        data-original-title="Download"><i class="icon-download7
                                    "></i></a>
                                    <span v-else> - </span>
                                    </div>
                                </div>
                                </div>
                            </td>
                            <td width="25%">
                                <div class="result_tab">
                                <h4>Foto Pasien</h4>
                                <div class="d-flex align-items-center mt-1">
                                    <div>
                                    <a v-if="row.aranres_file_foto_pasien" :href="$parent.uploader(row.aranres_file_foto_pasien)" data-popup="tooltip" title=""
                                        class="btn alpha-primary border-primary text-primary-800 btn-icon"
                                        data-original-title="Download"><i class="icon-download7
                                    "></i></a>
                                    <span v-else> - </span>
                                    </div>
                                </div>
                                </div>
                            </td>
                            <td width="25%" v-if="row.aranres_jaminan == 2">
                                <div class="result_tab">
                                <h4>Foto Kartu Asuransi</h4>
                                <div class="d-flex align-items-center mt-1">
                                    <div>
                                    <a v-if="row.aranres_file_asuransi" :href="$parent.uploader(row.aranres_file_asuransi)" data-popup="tooltip" title=""
                                        class="btn alpha-primary border-primary text-primary-800 btn-icon"
                                        data-original-title="Download"><i class="icon-download7
                                    "></i></a>
                                    <span v-else> - </span>
                                    </div>
                                </div>
                                </div>
                            </td>
                            <td width="25%">
                                <div class="result_tab">
                                <h4>Foto Surat Rujukan</h4>
                                <div class="d-flex align-items-center mt-1">
                                    <div>
                                    <a v-if="row.aranres_surat_rujukan" :href="$parent.uploader(row.aranres_surat_rujukan)" data-popup="tooltip" title=""
                                        class="btn alpha-primary border-primary text-primary-800 btn-icon"
                                        data-original-title="Download"><i class="icon-download7
                                    "></i></a>
                                    <span v-else> - </span>
                                    </div>
                                </div>
                                </div>
                            </td>
                            </tr>
                            <tr v-else>
                            <td width="25%">
                                <div class="result_tab">
                                <h4>Foto Kartu BPJS</h4>
                                <div class="d-flex align-items-center mt-1">
                                    <div>
                                    <a v-if="row.aranres_kartu_bpjs" :href="$parent.uploader(row.aranres_kartu_bpjs)" data-popup="tooltip" title=""
                                        class="btn alpha-primary border-primary text-primary-800 btn-icon"
                                        data-original-title="Download"><i class="icon-download7
                                    "></i></a>
                                    <span v-else> - </span>
                                    </div>
                                </div>
                                </div>
                            </td>
                            <td width="25%">
                                <div class="result_tab">
                                <h4>Foto Surat Rujukan</h4>
                                <div class="d-flex align-items-center mt-1">
                                    <div>
                                    <a v-if="row.aranres_surat_rujukan" :href="$parent.uploader(row.aranres_surat_rujukan)" data-popup="tooltip" title=""
                                        class="btn alpha-primary border-primary text-primary-800 btn-icon"
                                        data-original-title="Download"><i class="icon-download7
                                    "></i></a>
                                    <span v-else> - </span>
                                    </div>
                                </div>
                                </div>
                            </td>
                            <td width="25%">
                                <div class="result_tab">
                                <h4>Foto Surat Kontrol</h4>
                                <div class="d-flex align-items-center mt-1">
                                    <div>
                                    <a v-if="row.aranres_surat_kontrol" :href="$parent.uploader(row.aranres_surat_kontrol)" data-popup="tooltip" title=""
                                        class="btn alpha-primary border-primary text-primary-800 btn-icon"
                                        data-original-title="Download"><i class="icon-download7
                                    "></i></a>
                                    <span v-else> - </span>
                                    </div>
                                </div>
                                </div>
                            </td>
                            </tr>
                            
                            <tr v-if="row.aranres_jaminan == 2">
                            <td width="33%">
                                <div class="result_tab">
                                <h4>No Polis</h4>
                                <span>{{row.aranres_no_polis||"-"}}</span>
                                </div>
                            </td>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>No Kartu Asuransi</h4>
                                <span>{{row.aranres_no_kartu_asuransi||"-"}}</span>                        
                                </div>
                            </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        
        <validation-observer
            ref="VFormKontrol"
        >
            <b-modal title="Buat SEP" size="sm" v-model="modalSEPKontrol" @ok.prevent="addSEPData">
                <div class="form-group">
                    <label for="">Pilih Tipe SEP</label>
                    <v-select disabled placeholder="Pilih Jenis Tipe SEP" v-model="isType"
                        :options="Config.mr.mTypeSEPRanap" label="text" :clearable="true"
                        :reduce="v=>v.value">
                    </v-select>
                    <VValidate  
                        name="Pengajuan SEP" 
                        v-model="isType" 
                        :rules="{required: 1}">
                    </VValidate>
                </div>
            </b-modal>
        </validation-observer>

        
        <validation-observer
            ref="VFormFinger"
        >
            <b-modal title="Pengajuan SEP Fingerprint" size="sm" v-model="openBPJSModal" @ok.prevent="addBPJSFinger">
                <div class="form-group">
                    <label for="">Catatan<strong class="txt_mandatory">*</strong></label>
                    <b-form-textarea v-model="catatanBPJS" :formatter="$parent.normalText"
                        name="perRiwayat" id="perRiwayat" rows="2" class="form-control"
                        placeholder="Keluhan Utama dari pasien">
                    </b-form-textarea>

                    <VValidate name="Catatan BPJS" v-model="catatanBPJS" :rules="{required : 1, min : 8}" />
                </div>
            </b-modal>
        </validation-observer>
    </div>
</template>


<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
import Config from '@/libs/Config'
const moment = require('moment')

export default{
    data(){
        return {
            activeTabU: 1,
            activeTab: 1,
            rowPasien: {},
            isType : "R",

            mPasien: [],
            mAsuransi: [],
            mDPJPUtama: [],
            mDPJPRaber: [],
            mJenisRanap: [],
            mUnitLayan: [],
            mRanapBangsal: [],
            mPendidikan: [],
            mAgama: [],
            mPekerjaan: [],

            modalSEPKontrol: false,
            waktu : null,
            
            openBPJSModal: false,
            catatanBPJS: ""
        }
    },
    props:{
        row:Object,
        mrValidation:Object
    },
    methods: {
        
        uppercaseWord(e=""){
            if(e){
                const words = String(e)
                return words.toUpperCase()
            }else{
                return null
            }
        },

        changeTabU(tab){
            this.activeTabU = tab
        },
        changeTab(e){
            this.activeTab = e
        },
        apiGetPasien(){
            this.$parent.loadingOverlay = true            
            Gen.apiRest(
                "/get/"+"RoPasienNew"+'/'+this.row.aranres_ap_id 
            ).then(res=>{
                this.$parent.loadingOverlay = false
                this.rowPasien = res.data.row
            })
        },
        
        apiGetMaster(){
            let data = {
                poli_id : this.row.aranres_poli,
            }
            Gen.apiRest(
                "/get/"+this.$parent.modulePage+'/master', 
                {
                    params: data
                }
            ).then(res=>{
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
            })
        },
        
        getConfigDynamic(master, value, val = 'text') {
            let text = ''
            if (value) {
                let index = (master||[]).findIndex(x => x.value == value)
                if (index !== -1) {
                text = master[index][val]
                }
            }
            return text
        },
        
        downloadFile(field,id,val){
            let data = {exptype: 'xlsx', type: "export", id: id, field: field}
            let self = this
            self.$parent.loadingOverlay = true
            $.ajax({
                type: "POST",
                url: process.env.VUE_APP_API_URL + `/dokumen-ttd-ranap/${'DokumenTTD'}?token=IXs1029102asoaksoas102901290`,
                data: data,
                cache: false,
                xhrFields:{
                    responseType: 'blob'
                },
                success: data => 
                {
                    self.$parent.loadingOverlay = false
                    var link = document.createElement('a')
                    link.href = window.URL.createObjectURL(data)
                    link.download = `${val}-${moment().format("YYYY-MM-DD")}.pdf`
                    link.click()
                },
                fail: data => {
                    self.$parent.loadingOverlay = false
                    alert('Not downloaded')
                }
            })
        },
        
        addSEPDataKontrol(){
          this.modalSEPKontrol = true
        },
        deleteSEP(){
            let data = {
                type : 'delete-sep',
                regId : this.row.aranr_id,
            }
            this.$parent.loadingOverlay = true
            Gen.apiRest(
                "/do/" + this.$parent.modulePage, {
                    data: data
                },
            "POST"
            ).then(res => {
                this.$parent.apiGet()
                this.$parent.loadingOverlay = false
                this.addSEPDataKontrol()
            })
        },
        addSEPData(){
          let data = {
              type : 'add-sep',
              regId : this.row.aranr_id,
              pasienId : this.row.aranr_ap_id,
              resId : this.row.aranres_id,
              waktu : this.waktu,
              isType : this.isType
          }
          this.$parent.loadingOverlay = true
          this.modalSEPKontrol = false
          Gen.apiRest(
              "/do/" + this.$parent.modulePage, {
                  data: data
              },
          "POST"
          ).then(res => {
              let resp = res.data
              this.$parent.loadingOverlay = false
              return this.$swal({
                  title: resp.message,
                  confirmButtonText: 'Lihat SEP',
                  icon: 'success',
              }).then(()=>{
                  this.$router.push({name : 'BPJSDataSEP', params:{pageSlug : res.data.data.id}}).catch(()=>{})
              })
          }).catch(err=>{ 
              this.$parent.loadingOverlay = false 
              if(err){
                  err.statusType = err.status
                  err.status = "error"
                  err.message = err.response.data.message
                  err.title = err.response.data.title
                  err.messageError = err.message
              }
              this.$parent.doSetAlertForm(err)
          })
        },

        openPopupBPJS(){
          this.$parent.$swal({
              icon: 'warning',
              title: "Silakan lakukan scan Sidik Jari di aplikasi BPJS.",
          }).then(res1=>{
              if(res1.value){
                  this.$parent.$swal({
                      icon: 'warning',
                      title: "Apakah Pasien berhasil melakukan scan Sidik Jari?",
                      showCancelButton: true,
                      confirmButtonText: 'Sidik Jari Tidak Terbaca',
                      cancelButtonText: 'Berhasil',
                      cancelButtonColor: '#78D96A'
                  }).then(res2=>{
                      if(res2.isConfirmed){
                          this.openBPJSModal = true
                      }else{
                          this.$parent.loadingOverlay = true
                      
                          let data = {
                              type : 'update-finger-bpjs',
                              id : this.row.aranr_id
                          }

                          Gen.apiRest(
                              "/do/"+this.$parent.modulePage,
                              {data: data}, 
                              "POST"
                          ).then(res=>{
                              this.$swal({
                                  title: `Data Finger Print Success`,
                                  icon: 'success',
                              })
                              this.$parent.apiGet()
                          })
                      }
                  })     
              }
          })
        },
        addBPJSFinger(){
          this.$parent.loadingOverlay = true
          let data = {
              type : 'update-finger-print-sep',
              nobpjs : this.row.aranres_no_bpjs,
              catatan : this.catatanBPJS,
              id : this.row.aranr_id
          }
          Gen.apiRest(
              "/do/"+this.$parent.modulePage,
              {data: data}, 
              "POST"
          ).then(res=>{
              this.$parent.loadingOverlay = false
              let resp = res.data
              resp.statusType = 200
              this.$parent.$swal({
                  title: resp.message,
                  icon: resp.status,
                  confirmButtonText: 'Ok',
                  allowOutsideClick: false,
                  allowEscapeKey: false
              })
              this.openBPJSModal = false
              this.$parent.apiGet()
          }).catch(err => {
              this.$parent.loadingOverlay = false
              if (err) {
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title
                  err.message = err.response?.data?.message
                  err.messageError = err.message
              }
              this.$parent.doSetAlertForm(err)
              this.openBPJSModal = false
              this.$parent.apiGet()
          })
        },
    },
    computed: {    
        Config(){ return Config },
        
        wewenang(){
            let datawewenang = []
            for(let i = 0; i < (this.row.aranres_pelepasan_informasi||[]).length; i++){
                datawewenang.push(
                    this.row.aranres_pelepasan_informasi[i].nama + " (" +this.row.aranres_pelepasan_informasi[i].hub +"," + this.row.aranres_pelepasan_informasi[i].tel + ")"
                )
            }
            return (datawewenang).join(", ")
        },

        ktpAddr(){
            if(this.rowPasien.ap_address && this.rowPasien.ap_rt && this.rowPasien.ap_rw && this.rowPasien.ap_kelurahan && this.rowPasien.ap_kecamatan && this.rowPasien.ap_kota){
                return (this.rowPasien.ap_address||"-") + ", RT."+(this.rowPasien.ap_rt||"-")+"/"+(this.rowPasien.ap_rw||"-")+", DESA "+
                (this.rowPasien.ap_kelurahan||"-")+", KEC."+(this.rowPasien.ap_kecamatan||"-")+ ", KAB/KOTA"+(this.rowPasien.ap_kota||"-")
            }else{
                return "-"
            }
        },

        domisiliAddr(){
            if(this.rowPasien.ap_dom_address && this.rowPasien.ap_dom_rt && this.rowPasien.ap_dom_rw && this.rowPasien.ap_dom_kelurahan && this.rowPasien.ap_dom_kecamatan && this.rowPasien.ap_dom_kota){
                return (this.rowPasien.ap_dom_address||"-") + ", RT."+(this.rowPasien.ap_dom_rt||"-")+"/"+(this.rowPasien.ap_dom_rw||"-")+", DESA "+
                (this.rowPasien.ap_dom_kelurahan||"-")+", KEC."+(this.rowPasien.ap_dom_kecamatan||"-")+ ", KAB/KOTA"+(this.rowPasien.ap_dom_kota||"-")
            }else{
                return "-"
            }
        },
        
        pjAddr(){
            if(this.rowPasien.arpj_address && this.rowPasien.arpj_rt && this.rowPasien.arpj_rw && this.rowPasien.arpj_kelurahan && this.rowPasien.arpj_kecamatan && this.rowPasien.arpj_kota){
                return (this.rowPasien.arpj_address||"-") + ", RT."+(this.rowPasien.arpj_rt||"-")+"/"+(this.rowPasien.arpj_rw||"-")+", DESA "+
                (this.rowPasien.arpj_kelurahan||"-")+", KEC."+(this.rowPasien.arpj_kecamatan||"-")+ ", KAB/KOTA"+(this.rowPasien.ap_dom_kota||"-")
            }else{
                return "-"
            }
        },
    },
    mounted() {
        window.scrollTo(0,0)
        setTimeout(()=>{
            this.apiGetMaster()
            this.apiGetPasien()
        },2500)
    },
    watch:{
      'row.aranres_ap_id'(v, old){
          this.apiGetPasien()
      }
    }
}

</script>