<template>
    <fieldset>
        <div class="wrap_line_heading">
            <h6 class="font-weight-semibold">Data Cara Bayar</h6>
            <div class="data_prev_cont">            
                <template v-if="Object.keys(rowPasien||{}).length">
                    <div class="card mb-3">
                        <div class="card-body bg_card_blue">
                            <div class="row">
                            <div class="col-md-9">
                                <table class="table table-borderless table-sm text-uppercase">
                                <tbody>
                                    <tr>
                                    <td width="33%">
                                        <div class="result_tab">
                                        <h4>No. Rekam Medis</h4>
                                        <p>{{rowPasien.ap_code||"-"}}</p>
                                        </div>
                                    </td>
                                    <td width="33%">
                                        <div class="result_tab">
                                        <h4>Nama Pasien</h4>
                                        <p>{{rowPasien.ap_fullname||"-"}}</p>
                                        </div>
                                    </td>
                                    </tr>
                                    <tr>
                                    <td width="33%">
                                        <div class="result_tab">
                                        <h4>Tempat, Tanggal Lahir </h4>
                                        <p>{{rowPasien.ap_pob||"-"}}, {{rowPasien.ap_dob | moment("DD MMMM YYYY")}}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <h4>Usia</h4>
                                        <p>{{rowPasien.ap_usia_with_ket||"-"}}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <h4>Jenis Kelamin</h4>
                                        <p>{{rowPasien.cg_label||"-"}}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <h4>Alamat</h4>
                                        <p>{{rowPasien.ap_dom_address||"-"}}</p>
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                    </div>
                </template>

                <div class="row">
                    <div class="form-group col-md-3">
                        <label>Jaminan <small class="txt_mandatory">*</small></label>
                        <v-select :disabled="!isParent.row.aranres_ap_id?true:false" @input="changeJaminan();isParent.row.aranres_asuransi = null" placeholder=" -- Pilih Jaminan -- " v-model="isParent.row.aranres_jaminan"
                            :options="Config.mr.mJaminan" label="text" :clearable="true"
                            :reduce="v=>v.value">
                        </v-select>
                        <VValidate 
                            name="Jaminan" 
                            v-model="isParent.row.aranres_jaminan" 
                            :rules="toValidate(isParent.mrValidation.aranres_jaminan)"
                        />
                    </div>
                    
                    <div class="form-group col-md-3">
                        <label>Tanggal Rawat Inap <small class="txt_mandatory">*</small></label>
                        <div class="input-group">
                            <datepicker input-class="form-control transparent"
                                placeholder="Tanggal Rawat Inap" class="my-datepicker"
                                calendar-class="my-datepicker_calendar"
                                v-model="isParent.row.aranres_tanggal_periksa"
                                >
                            </datepicker>
                            <div class="input-group-append calendar-group">
                                <span class="input-group-text" id="basic-addon2"><i
                                        class="icon-calendar"></i></span>
                            </div>
                        </div>
                        <VValidate 
                            name="Tanggal Rawat Inap" 
                            v-model="isParent.row.aranres_tanggal_periksa" 
                            :rules="toValidate(isParent.mrValidation.aranres_tanggal_periksa)"
                        />
                    </div>
                    

                    <div class="form-group col-md-2">
                      <label>Waktu Pendaftaran <small class="txt_mandatory">*</small></label>

                      <vue-timepicker manual-input v-model="isParent.row.aranres_jam_periksa" format="HH:mm"  input-class="form-control"></vue-timepicker>

                      <VValidate 
                          name="Waktu Pendaftaran" 
                          v-model="isParent.row.aranres_jam_periksa" 
                          :rules="{required: 1}"
                      />
                    </div>

                    <div class="form-group col-md-3">
						<div class="form-check form-check p-1 mt-3">
							<label class="form-check-label d-flex">
                            <b-form-checkbox value="Y" unchecked-value="N" v-model="isParent.row.aranres_is_cara_bayar_bermasalah" class="form-check-input-styled"
                                name="radio-inline-left" />
                            Jaminan Bermasalah
                        	</label>
						</div>
                    </div>


                    

                    <template v-if="!errData">
                    <div class="col-12" v-if="isParent.row.aranres_jaminan == 3">
                        <div class="row">
                        <div class="form-group col-md-3">
                            <label>Nomor Peserta BPJS <small class="txt_mandatory">*</small></label>
                            <b-form-input disabled v-model="isParent.row.aranres_no_bpjs" :formatter="isParent.$parent.number" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="Nomor Peserta BPJS" />
                            <VValidate 
                                name="Nomor Peserta BPJS" 
                                v-model="isParent.row.aranres_no_bpjs" 
                                :rules="toValidate(isParent.mrValidation.aranres_no_bpjs)"
                            />
                        </div>
                        
                        <!--
                        <div class="form-group col-md-3">
                            <label>Nomor Rujukan</label>
                            <b-form-input v-model="isParent.row.aranres_no_rujukan" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="Nomor Rujukan" />
                        </div>
                        -->
                        
                        <div class="form-group col-md-3">
                            <label>Hak Kelas</label>
                            <b-form-input disabled v-model="isParent.row.aranres_hak_kelas_text" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="Hak Kelas" />
                        </div>
                       
                        <div class="col-md-12">
                            <div class="table-responsive mb-1">
                            <table class="table table-bordered mb-0">
                                <thead>
                                <tr>
                                    <th width="180">Keterangan </th>
                                    <th class="bg-primary">Data RS</th>
                                    <th class="bg-success">Data BPJS</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th class="table-secondary">
                                    <div class="wrap_preview_data">
                                        <span class="font-weight-semibold">Nama Pasien</span>
                                    </div>
                                    </th>
                                    <td>
                                    <div class="wrap_preview_data">
                                        <p>{{isParent.row.ap_fullname||"-"}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="wrap_preview_data">
                                        <p>{{isParent.row.apbd_fullname||"-"}}
                                            <span id="bpjs_status" class="badge badge-info">{{isParent.row.apbd_status}}</span>
                                        </p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="table-secondary">
                                    <div class="wrap_preview_data">
                                        <span class="font-weight-semibold">NIK KTP</span>
                                    </div>
                                    </th>
                                    <td>
                                    <div class="wrap_preview_data">
                                        <p>{{isParent.row.ap_nik||"-"}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="wrap_preview_data">
                                        <p>{{isParent.row.apbd_nik||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="table-secondary">
                                    <div class="wrap_preview_data">
                                        <span class="font-weight-semibold">Jenis Kelamin</span>
                                    </div>
                                    </th>
                                    <td>
                                    <div class="wrap_preview_data">
                                        <p>{{getConfigDynamic(Config.mr.StatusKelamin,isParent.row.ap_gender)||"-"}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="wrap_preview_data">
                                        <p>{{getConfigDynamic(Config.mr.StatusKelamin,isParent.row.apbd_gender)||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="table-secondary">
                                    <div class="wrap_preview_data">
                                        <span class="font-weight-semibold">Tanggal Lahir</span>
                                    </div>
                                    </th>
                                    <td>
                                    <div class="wrap_preview_data">
                                        <p v-if="isParent.row.ap_dob">{{ isParent.row.ap_dob | moment("DD MMMM YYYY")}}</p>
                                        <p v-else> - </p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="wrap_preview_data">
                                        <p v-if="isParent.row.apbd_dob">{{ isParent.row.apbd_dob | moment("DD MMMM YYYY")}}</p>
                                        <p v-else> - </p>
                                    </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                        </div>
                    </div>
                    </template>

                    <div class="form-group col-md-4" v-if="isParent.row.aranres_jaminan == 2">
                        <label>Pilihan Asuransi <small class="txt_mandatory">*</small></label>
                        <v-select @input="isParent.row.aranres_file_asuransi = null" :disabled="!isParent.row.aranres_ap_id?true:false" placeholder="Pilih Jenis Asuransi" v-model="isParent.row.aranres_asuransi"
                            :options="isParent.mAsuransi" label="text" :clearable="true"
                            :reduce="v=>v.value">
                        </v-select>
                        <VValidate 
                            name="Asuransi" 
                            v-model="isParent.row.aranres_asuransi" 
                            :rules="toValidate(isParent.mrValidation.aranres_asuransi)"
                        />
                        
                    </div>
                    
                    <div class="form-group col-md-4" v-if="isParent.row.aranres_jaminan == 2">
                        <label>No Kartu Asuransi <small class="txt_mandatory">*</small></label>
                        <b-form-input v-model="isParent.row.aranres_no_kartu_asuransi" :formatter="$parent.normalText" type="text" class="form-control"/>
                        <VValidate 
                            name="No. Kartu Asuransi" 
                            v-model="isParent.row.aranres_no_kartu_asuransi" 
                            :rules="{required: 1}"
                        />
                    </div>
                    
                    <div class="form-group col-md-4" v-if="isParent.row.aranres_jaminan == 2">
                        <label>No Polis</label>
                        <b-form-input v-model="isParent.row.aranres_no_polis" :formatter="$parent.normalText" type="text" class="form-control"/>
                    </div>

                    <template v-if="errData">
                        <div class="col-md-12">
                            <div class="alert alert-danger">
                            <h6 class="mb-0">{{errData||"-"}}</h6>
                            </div>
                        </div>
                    </template>

                    
                    <template v-if="errDataV2">
                        <div class="col-md-12">
                            <div class="alert alert-danger">
                            <h6 class="mb-0">{{errDataV2||"-"}}</h6>
                            </div>
                        </div>
                    </template>

                </div>
            </div>
            
            <hr>

            <div class="wrap_line_heading mb-4" v-if="!errData">
                <h6 class="font-weight-semibold">Dokumen Pendukung</h6>
                <p>Mohon gambar/foto yang di upload bisa terbaca dengan jelas</p>

                <div class="form-row" id="docUmum" v-if="isParent.row.aranres_jaminan == 1 || isParent.row.aranres_jaminan == 2">
                    <div class="col-md-3" v-if="isParent.row.aranres_jaminan == 1 || isParent.row.aranres_jaminan == 2">
                    <div class="form-group">
                        <label for="">KTP/Kartu Keluarga</label>
                        <Uploader isDocument v-model="isParent.row.aranres_file_ktp" type="docimage"/>  
                    </div>
                    </div>

                    <div class="col-md-3" v-if="isParent.row.aranres_jaminan == 1 || isParent.row.aranres_jaminan == 2">
                    <div class="form-group">
                        <label for="">Foto Pasien</label>
                        <Uploader isDocument v-model="isParent.row.aranres_file_foto_pasien" type="image"/>  
                    </div>
                    </div>
                    
                    <div class="col-md-3" v-if="isParent.row.aranres_jaminan == 2">
                    <div class="form-group">
                        <label for="">Kartu Asuransi</label>
                        <Uploader isDocument v-model="isParent.row.aranres_file_asuransi" type="docimage"/>  
                    </div>
                    </div>
                </div>
                
                <div class="form-row"  v-if="isParent.row.aranres_jaminan == 3">
                    <div class="col-md-3">
                    <div class="form-group">
                        <label for="">Kartu BPJS</label>
                        <Uploader isDocument v-model="isParent.row.aranres_kartu_bpjs" type="docimage"/>  
                    </div>
                    </div>

                    <div class="col-md-3">
                    <div class="form-group">
                        <label for="">Surat Rujukan</label>
                        <Uploader isDocument v-model="isParent.row.aranres_surat_rujukan" type="docimage"/>  
                    </div>
                    </div>

                    <div class="col-md-3" v-if="isParent.row.aranres_no_skdp">
                    <div class="form-group">
                        <label for="">Surat Kontrol</label>
                        <Uploader isDocument v-model="isParent.row.aranres_surat_kontrol" type="docimage"/>  
                    </div>
                    </div>
                </div>
            </div>
        </div>

    </fieldset>
</template>

<script>
import Config from '@/libs/Config'
import Gen from '@/libs/Gen.js'
const _ = global._
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
    computed: {    
        Config(){ return Config },
        isParent(){
            return this.$parent.$parent.$parent.$parent
        }
    }, 
    data() {
        return {
            searchData: null,
            errData: "",
            errDataV2: "",
            rowPasien: {},
            modalGenerate: false,
            nomorGenerate: null,
            
            nama_pasien: null,
            no_rm: null,
            alamat: null,
            ttl: null,
        }
    },
    components: {
      Datepicker, VueTimepicker
    },
    mounted(){
        setTimeout(()=>{
            this.rowPasien = this.isParent.rowPasien  
        },1000)
        
        setTimeout(()=>{
            this.changeJaminan(true)
        },1500)
    },
    methods: {
        changeJaminan(isLoad=false){
            this.errData = ""
            this.errDataV2 = ""

            if(this.isParent.row.aranres_jaminan == 3){
                
                if((this.isParent.row.ap_nik).length < 16){
                    return this.isParent.$swal({
                        title: `Nomor NIK tidak Valid`,
                        text: 'Silakan update Nomor NIK di menu pasien',
                        icon: 'error',
                    })
                }
                
                let data = {
                    ap_id : this.isParent.row.aranres_ap_id,
                    nik : this.isParent.row.ap_nik,
                    type : 'select-bpjs-value'
                }

                this.isParent.$parent.loadingOverlay = true
                
                Gen.apiRest(
                    "/do/"+this.isParent.$parent.modulePage, 
                    {
                        data: data
                    }, "POST"
                ).then(res=>{
                    let data = res.data.data
                    
                    if(res.data.status == "fail"){
                        this.isParent.isBPJS = false
                        this.isParent.$parent.loadingOverlay = false
                        this.errData = "NIK atau nomor kartu anda tidak ditemukan pada data BPJS. Silakan periksa NIK atau nomor kartu anda."
                        return
                    }

                    this.isParent.isBPJS = true
                    
                    this.isParent.row.apbd_fullname = data.apbd_fullname
                    this.isParent.row.apbd_nik = data.apbd_nik
                    this.isParent.row.apbd_gender = data.apbd_gender
                    this.isParent.row.apbd_dob = data.apbd_dob
                    this.isParent.row.apbd_status = data.apbd_status
                    this.isParent.row.aranres_hak_kelas = data.apbd_hak_kelas
                    this.isParent.row.aranres_hak_kelas_text = data.apbd_hak_kelas_text
                    this.isParent.row.aranres_hak_kelas_id = data.apbd_hak_kelas_id
                    this.isParent.row.aranres_no_bpjs = data.apbd_no_bpjs


                    let statusBPJS = data.apbd_status
                    if(statusBPJS == 'MENINGGAL' || statusBPJS == 'KELUAR KEMAUAN SENDIRI' || statusBPJS == 'TIDAK DITANGGUNG' || statusBPJS == 'PENANGGUHAN PESERTA' || statusBPJS == 'NON AKTIF DIAKHIR BULAN' || statusBPJS == 'PENANGGUHAN PEMBAYARAN' || statusBPJS == 'NON AKTIF KARNA DATA GANDA' || statusBPJS == 'USIA ANAK PPU > 21 TH DAN 25 TH' || statusBPJS == 'NON AKTIF KARNA PREMI'){
                        this.errDataV2 = data.apbd_status
                    }else{
                        this.errDataV2 = ""
                    }
                    this.isParent.$parent.loadingOverlay = false
                }).catch(err=>{
                    this.isParent.$parent.loadingOverlay = false
                })
            }


        },
        toValidate(val){
            return {...val}
        },
        getConfigDynamic(master, value, val = 'text') {
            let text = ''
            if (value) {
                let index = (master||[]).findIndex(x => x.value == value)
                if (index !== -1) {
                text = master[index][val]
                }
            }
            return text
        },
    },
    watch:{
        '$route.params.pageSlug':function(){
            this.$set(this,'filter',{})
        }
    }
}

</script>