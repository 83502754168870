<template>
  <div>
    <div class="wrap_line" v-if="(isParent.row.aranres_jaminan == Config.var.isBPJS && isParent.row.aranres_is_naik) || (isParent.row.aranres_jaminan == Config.var.isAsuransi) || (isParent.row.aranres_jaminan == Config.var.isUmum)">
      
      <template v-if="isParent.row.aranres_jaminan == Config.var.isBPJS">
        <h3>{{isParent.row.aranres_is_naik == 'Y' ? 'Pernyataan Naik Kelas' : 'Pernyataan Turun Kelas'}}</h3>
      </template>

      <template v-if="isParent.row.aranres_jaminan == Config.var.isAsuransi">
        <h3>Pernyataan Pasien Asuransi</h3>
      </template>

      <template v-if="isParent.row.aranres_jaminan == Config.var.isUmum">
        <h3>Pernyataan Pasien Non-Asuransi</h3>
      </template>
      <div>
        <div class="font-weight-semibold text-blue-800 mb-2">Informasi Penanggungjawab</div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="naikKelasNama">Nama <strong class="text-danger">*</strong></label>
              <b-form-input :formatter="isParent.$parent.normalText" v-model="isParent.row.aranres_pj_nama" type="text"
                class="form-control" placeholder="Nama Penanggung Jawab" />
              <VValidate name="Nama Penanggung Jawab" v-model="isParent.row.aranres_pj_nama"
                :rules="toValidate(isParent.mrValidation.aranres_pj_nama)" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="naikKelasTel">No. Telp <strong class="text-danger">*</strong></label>
              <div class="input-group">
                <div class="input-group-prepend"><span class="input-group-text">+62</span></div>
                <b-form-input :formatter="isParent.$parent.normalText" v-model="isParent.row.aranres_pj_telp"
                  type="text" class="form-control" placeholder="No. Telp" />
              </div>
              <VValidate name="No. Telp" v-model="isParent.row.aranres_pj_telp"
                :rules="toValidate(isParent.mrValidation.aranres_pj_telp)" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="naikKelAlamat">Alamat <strong class="text-danger">*</strong></label>
              <b-textarea v-model="isParent.row.aranres_pj_alamat" name="patientNotPresent" id="patientNotPresent"
                rows="3" class="form-control" placeholder="cth. pasien tidak hadir setelah dipanggil 3x"></b-textarea>
              <VValidate name="Alamat" v-model="isParent.row.aranres_pj_alamat"
                :rules="toValidate(isParent.mrValidation.aranres_pj_alamat)" />
            </div>
          </div>
        </div>
      </div>

      <template v-if="isParent.row.aranres_jaminan == Config.var.isBPJS && isParent.row.aranres_is_naik == 'Y'">
        <div class="alert alert-info mb-0">
          <div class="d-flex">
            <i class="icon-info22"></i>
            <span class="ml-1">Dokumen memerlukan materai, sehingga hanya bisa ditandatangani manual</span>
          </div>
        </div>
      </template>

      <template v-else>
      
        <div class="form-group">
          <label for="">Penandatanganan Dokumen <strong class="text-danger">*</strong></label>
          <div>
            <b-form-radio-group :options="Config.mr.ttdCreatedBy" @change="isParent.autoSave(isParent.row,true)" v-model="isParent.row.aranres_pj_is_ttd" />
            <VValidate name="Penandatanganan Dokumen" v-model="isParent.row.aranres_pj_is_ttd"
            :rules="toValidate(isParent.mrValidation.aranres_pj_is_ttd)" />
          </div>
        </div>

        <div v-if="isParent.row.aranres_pj_is_ttd == 'Digital'" class="turunKelasBlock mb-2">
          <a href="javascript:;" @click="openPertanggungJawaban" class="btn btn-labeled btn-labeled-left btn-warning" data-toggle="modal">
            <b><i class="icon-pencil5"></i></b>
            <span>Tandatangani Dokumen</span>
          </a>
        </div>

        <div v-if="isParent.row.aranres_pj_is_ttd == 'Manual'" id="genManualSigning" class="genConsentBlock">
            <div class="alert alert-info d-flex align-items-center">
                <i class="icon-info22"></i>
                <span class="ml-1">Simpan hasil reservasi, lalu unduh, cetak dan tandatangani dokumen. Unggah kembali dokumen yang telah ditandatangani.</span>
            </div>
        </div>


      </template>

    </div>

    <div class="wrap_line" v-if="isParent.rowPasien.ap_gender == 2">
      <h3>Pernyataan Belum Menikah</h3>
      <div class="row gx-4">
        <b-col lg="6">
          <b-form-group>
            <b-form-radio-group :options="Config.mr.yesNoOpt" v-model="isParent.row.aranres_bm_is" />

            <VValidate name="Pernyataan Belum Menikah" v-model="isParent.row.aranres_bm_is"
              :rules="toValidate(isParent.mrValidation.aranres_bm_is)" />
          </b-form-group>
        </b-col>
      </div>

      <template v-if="isParent.row.aranres_bm_is == 'Y'">
        <div class="row gx-4">
          <div class="col-md-6">
            <div>
              <div class="font-weight-semibold text-blue-800 mb-2">Informasi Ayah</div>
              <div class="row">
                <div class="col-md-8">
                  <div class="form-group">
                    <label for="naikKelasNama">Nama <strong class="text-danger">*</strong></label>
                    <b-form-input :formatter="isParent.$parent.normalText" v-model="isParent.row.aranres_bm_ayah_nama" type="text"
                      class="form-control" placeholder="Nama Ayah" />
                    <VValidate name="Nama Ayah" v-model="isParent.row.aranres_bm_ayah_nama"
                      :rules="toValidate(isParent.mrValidation.aranres_bm_ayah_nama)" />
                  </div>
                </div>
                <div class="col-md-10">
                  <div class="form-group">
                    <label for="ayahTTL">Tempat, Tanggal Lahir <strong class="text-danger">*</strong></label>
                    <div class="input-group">
                      <b-form-input :formatter="isParent.$parent.normalText" v-model="isParent.row.aranres_bm_ayah_pob" type="text"
                      class="form-control" placeholder="Tempat Tanggal Lahir" />
                      <datepicker input-class="form-control transparent"
                      placeholder="Pilih Tanggal" class="my-datepicker"
                      calendar-class="my-datepicker_calendar" v-model="isParent.row.aranres_bm_ayah_dob">
                      </datepicker>
                    </div>
                  
                    <VValidate name="Tempat Lahir Ayah" v-model="isParent.row.aranres_bm_ayah_pob"
                      :rules="toValidate(isParent.mrValidation.aranres_bm_ayah_pob)" />
                  
                    <VValidate name="Tanggal Lahir Ayah" v-model="isParent.row.aranres_bm_ayah_dob"
                      :rules="toValidate(isParent.mrValidation.aranres_bm_ayah_dob)" />
                  
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="ayahAlamat">Alamat <strong class="text-danger">*</strong></label>
                    <b-textarea :formatter="isParent.$parent.normalText" v-model="isParent.row.aranres_bm_ayah_address"  name="ayahAlamat" id="ayahAlamat" rows="3" class="form-control"></b-textarea>
                    <VValidate name="Alamat Ayah" v-model="isParent.row.aranres_bm_ayah_address"
                      :rules="toValidate(isParent.mrValidation.aranres_bm_ayah_address)" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 border-left">
            <div>
              <div class="font-weight-semibold text-blue-800 mb-2">Informasi Ibu</div>
              <div class="row">
                <div class="col-md-8">
                  <div class="form-group">
                    <label for="naikKelasNama">Nama <strong class="text-danger">*</strong></label>
                    <b-form-input :formatter="isParent.$parent.normalText" v-model="isParent.row.aranres_bm_ibu_nama" type="text"
                      class="form-control" placeholder="Nama Ibu" />
                    <VValidate name="Nama Ibu" v-model="isParent.row.aranres_bm_ibu_nama"
                      :rules="toValidate(isParent.mrValidation.aranres_bm_ibu_nama)" />
                  </div>
                </div>
                <div class="col-md-10">
                  <div class="form-group">
                    <label for="IbuTTL">Tempat, Tanggal Lahir <strong class="text-danger">*</strong></label>
                    <div class="input-group">
                      <b-form-input :formatter="isParent.$parent.normalText" v-model="isParent.row.aranres_bm_ibu_pob" type="text"
                      class="form-control" placeholder="Tempat Tanggal Lahir" />
                      <datepicker input-class="form-control transparent"
                      placeholder="Pilih Tanggal" class="my-datepicker"
                      calendar-class="my-datepicker_calendar" v-model="isParent.row.aranres_bm_ibu_dob">
                      </datepicker>
                    </div>
                  
                    <VValidate name="Tempat Lahir Ibu" v-model="isParent.row.aranres_bm_ibu_pob"
                      :rules="toValidate(isParent.mrValidation.aranres_bm_ibu_pob)" />
                  
                    <VValidate name="Tanggal Lahir Ibu" v-model="isParent.row.aranres_bm_ibu_dob"
                      :rules="toValidate(isParent.mrValidation.aranres_bm_ibu_dob)" />
                  
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="IbuAlamat">Alamat <strong class="text-danger">*</strong></label>
                    <b-textarea :formatter="isParent.$parent.normalText" v-model="isParent.row.aranres_bm_ibu_address"  name="IbuAlamat" id="IbuAlamat" rows="3" class="form-control"></b-textarea>
                    <VValidate name="Alamat Ibu" v-model="isParent.row.aranres_bm_ibu_address"
                      :rules="toValidate(isParent.mrValidation.aranres_bm_ibu_address)" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="font-weight-semibold text-blue-800 mb-2">Informasi Pasangan Laki-laki</div>
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="pasLakiNama">Nama Pasangan Laki-laki <strong class="text-danger">*</strong></label>
                    <b-form-input :formatter="isParent.$parent.normalText" v-model="isParent.row.aranres_bm_pas_laki_nama" type="text" class="form-control" placeholder="Nama Pasangan Laki-laki" />
                    <VValidate name="Nama Pasangan Laki-laki" v-model="isParent.row.aranres_bm_pas_laki_nama"
                      :rules="toValidate(isParent.mrValidation.aranres_bm_pas_laki_nama)" />
                  </div>
                </div>
                <div class="col-md-6">                
                  <div class="form-group">
                    <label for="pasLakiTel">No. Telp Pasangan Laki-laki</label>
                    <div class="input-group">
                      <div class="input-group-prepend"><span class="input-group-text">+62</span></div>
                      <b-form-input :formatter="isParent.$parent.numberOnly" v-model="isParent.row.aranres_bm_pas_laki_no_telp"
                        type="text" class="form-control" placeholder="No. Telp" />
                    </div>
                    <VValidate name="No. Telp Pasangan Laki-laki" v-model="isParent.row.aranres_bm_pas_laki_no_telp"
                      :rules="toValidate(isParent.mrValidation.aranres_bm_pas_laki_no_telp)" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-10">
                  <div class="form-group">
                    <label for="IbuTTL">Tempat, Tanggal Lahir Pasangan<strong class="text-danger">*</strong></label>
                    <div class="input-group">
                      <b-form-input :formatter="isParent.$parent.normalText" v-model="isParent.row.aranres_bm_pas_laki_pob" type="text"
                      class="form-control" placeholder="Tempat Tanggal Lahir" />
                      <datepicker input-class="form-control transparent"
                      placeholder="Pilih Tanggal" class="my-datepicker"
                      calendar-class="my-datepicker_calendar" v-model="isParent.row.aranres_bm_pas_laki_dob">
                      </datepicker>
                    </div>
                    <VValidate name="Tempat Lahir Pasangan Laki-Laki" v-model="isParent.row.aranres_bm_pas_laki_pob"
                      :rules="toValidate(isParent.mrValidation.aranres_bm_pas_laki_pob)" />
                  
                    <VValidate name="Tanggal Lahir Pasangan Laki-Laki" v-model="isParent.row.aranres_bm_pas_laki_dob"
                      :rules="toValidate(isParent.mrValidation.aranres_bm_pas_laki_dob)" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="pasLakiAlamat">Alamat Pasangan Laki-laki <strong class="text-danger">*</strong></label>
                <b-textarea v-model="isParent.row.aranres_bm_pas_laki_address" name="pasLakiAlamat" id="pasLakiAlamat" rows="3" class="form-control"></b-textarea>
                
                <VValidate name="Alamat Pasangan Laki-laki" v-model="isParent.row.aranres_bm_pas_laki_address"
                  :rules="toValidate(isParent.mrValidation.aranres_bm_pas_laki_address)" />
              </div>
            </div>
          </div>
        </div>
        <div class="alert alert-info mb-0">
          <div class="d-flex">
            <i class="icon-info22"></i>
            <span class="ml-1">Dokumen memerlukan materai, sehingga hanya bisa ditandatangani manual</span>
          </div>
        </div>
      </template>
    </div>

    <div class="wrap_line" v-if="isParent.rowPasien.ap_gender == 2">
      <h3>Keterangan Nikah Secara Agama</h3>
      <div class="row gx-4">
        <b-col lg="6">
          <b-form-group>
            <b-form-radio-group :options="Config.mr.yesNoOpt" v-model="isParent.row.aranres_kn_is" />

            <VValidate name="Keterangan Nikah Secara Agama" v-model="isParent.row.aranres_kn_is"
              :rules="toValidate(isParent.mrValidation.aranres_kn_is)" />
          </b-form-group>
        </b-col>
      </div>
      
      <template v-if="isParent.row.aranres_kn_is == 'Y'">
        <div class="row">
          <!--
          <div class="col-md-6">
            <div class="form-group">
              <label for="pasangan1Nama">Pernikahan Dilakukan Secara Agama<strong class="text-danger">*</strong></label>                <b-form-input :formatter="isParent.$parent.normalText" v-model="isParent.row.aranres_kn_dilakukan_secara"
                type="text" class="form-control" placeholder="Pernikahan Dilakukan Secara" />
              <VValidate name="Pernikahan Dilakukan Secara" v-model="isParent.row.aranres_kn_dilakukan_secara"
                :rules="toValidate(isParent.mrValidation.aranres_kn_dilakukan_secara)" />
            </div>
          </div>
          -->
          <div class="col-md-6">
            <div class="form-group">
              <label for="pasangan1Nama">Tanggal Pernikahan <strong class="text-danger">*</strong></label>                
              <div class="input-group mb-3">
                <datepicker input-class="form-control transparent"
                  placeholder="Pilih Tanggal" class="my-datepicker"
                  calendar-class="my-datepicker_calendar" v-model="isParent.row.aranres_kn_tgl_nikah">
                </datepicker>
                <div class="input-group-append calendar-group">
                  <span class="input-group-text" id="basic-addon2"><i
                      class="icon-calendar"></i></span>
                </div>
              </div>
              <VValidate name="Tanggal Pernikahan" v-model="isParent.row.aranres_kn_tgl_nikah"
                :rules="toValidate(isParent.mrValidation.aranres_kn_tgl_nikah)" />
            </div>
          </div>
        </div>

        <div>
          <div class="row gx-4">
            <div class="col-md-6">
              <div class="text-blue-800 font-weight-semibold mb-2">Informasi Pasangan Laki-laki</div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="pasangan1Nama">Nama <strong class="text-danger">*</strong></label>                    
                    <b-form-input :formatter="isParent.$parent.normalText" v-model="isParent.row.aranres_kn_ayah_nama"
                      type="text" class="form-control" placeholder="Nama" />
                    <VValidate name="Nama Pas. Laki-Laki" v-model="isParent.row.aranres_kn_ayah_nama"
                      :rules="toValidate(isParent.mrValidation.aranres_kn_ayah_nama)" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="pasangan1Tel">No. Telp <strong class="text-danger">*</strong></label>
                    <div class="input-group">
                      <div class="input-group-prepend"><span class="input-group-text">+62</span></div>
                      <b-form-input :formatter="isParent.$parent.numberOnly" v-model="isParent.row.aranres_kn_ayah_telp"
                        type="text" class="form-control" placeholder="No. Telp" />
                    </div>
                    <VValidate name="No. Telp Pas. Laki-laki" v-model="isParent.row.aranres_kn_ayah_telp"
                      :rules="toValidate(isParent.mrValidation.aranres_kn_ayah_telp)" />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label for="IbuTTL">Tempat, Tanggal Lahir <strong class="text-danger">*</strong></label>
                    <div class="input-group">
                      <b-form-input :formatter="isParent.$parent.normalText" v-model="isParent.row.aranres_kn_ayah_pob" type="text"
                      class="form-control" placeholder="Tempat Tanggal Lahir" />
                      <datepicker input-class="form-control transparent"
                      placeholder="Pilih Tanggal" class="my-datepicker"
                      calendar-class="my-datepicker_calendar" v-model="isParent.row.aranres_kn_ayah_dob">
                      </datepicker>
                    </div>
                  
                    <VValidate name="Tempat Lahir Pas. Laki-laki" v-model="isParent.row.aranres_kn_ayah_pob"
                      :rules="toValidate(isParent.mrValidation.aranres_kn_ayah_pob)" />
                  
                    <VValidate name="Tanggal Lahir Pas. Laki-laki" v-model="isParent.row.aranres_kn_ayah_dob"
                      :rules="toValidate(isParent.mrValidation.aranres_kn_ayah_dob)" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="pasLakiAlamat">Alamat<strong class="text-danger">*</strong></label>
                    <b-textarea v-model="isParent.row.aranres_kn_ayah_alamat" name="pasLakiAlamat" id="pasLakiAlamat" rows="3" class="form-control"></b-textarea>
                    <VValidate name="Alamat Pas. Laki-laki" v-model="isParent.row.aranres_kn_ayah_alamat"
                      :rules="toValidate(isParent.mrValidation.aranres_kn_ayah_alamat)" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 border-left">
              <div class="text-blue-800 font-weight-semibold mb-2">Informasi Wali Nikah</div>
               <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="pasangan1Nama">Nama</label>                    
                    <b-form-input :formatter="isParent.$parent.normalText" v-model="isParent.row.aranres_kn_wali_nama"
                      type="text" class="form-control" placeholder="Nama" />
                    <VValidate name="Nama Wali" v-model="isParent.row.aranres_kn_wali_nama"
                      :rules="toValidate(isParent.mrValidation.aranres_kn_wali_nama)" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="pasangan1Tel">No. Telp</label>
                    <div class="input-group">
                      <div class="input-group-prepend"><span class="input-group-text">+62</span></div>
                      <b-form-input :formatter="isParent.$parent.numberOnly" v-model="isParent.row.aranres_kn_wali_telp"
                        type="text" class="form-control" placeholder="No. Telp" />
                    </div>
                    <VValidate name="No. Telp Wali" v-model="isParent.row.aranres_kn_wali_telp"
                      :rules="toValidate(isParent.mrValidation.aranres_kn_wali_telp)" />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label for="IbuTTL">Tempat, Tanggal Lahir</label>
                    <div class="input-group">
                      <b-form-input :formatter="isParent.$parent.normalText" v-model="isParent.row.aranres_kn_wali_pob" type="text"
                      class="form-control" placeholder="Tempat Tanggal Lahir" />
                      <datepicker input-class="form-control transparent"
                      placeholder="Pilih Tanggal" class="my-datepicker"
                      calendar-class="my-datepicker_calendar" v-model="isParent.row.aranres_kn_wali_dob">
                      </datepicker>
                    </div>
                  
                    <VValidate name="Tempat Lahir Wali" v-model="isParent.row.aranres_kn_wali_pob"
                      :rules="toValidate(isParent.mrValidation.aranres_kn_wali_pob)" />
                  
                    <VValidate name="Tanggal Lahir Wali" v-model="isParent.row.aranres_kn_wali_dob"
                      :rules="toValidate(isParent.mrValidation.aranres_kn_wali_dob)" />
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="pasLakiGender">Jenis Kelamin</label>
                    <b-form-radio-group :options="Config.mr.genderText" v-model="isParent.row.aranres_kn_wali_gender" />
                    <VValidate name="Jenis Kelamin Wali" v-model="isParent.row.aranres_kn_wali_gender"
                      :rules="toValidate(isParent.mrValidation.aranres_kn_wali_gender)" />
                  </div>
                </div>
                
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="pasLakiAlamat">Alamat</label>
                    <b-textarea v-model="isParent.row.aranres_kn_wali_alamat" name="pasLakiAlamat" id="pasLakiAlamat" rows="3" class="form-control"></b-textarea>
                    <VValidate name="Alamat Wali" v-model="isParent.row.aranres_kn_wali_alamat"
                      :rules="toValidate(isParent.mrValidation.aranres_kn_wali_alamat)" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <div class="text-blue-800 font-weight-semibold mb-2">Informasi Saksi</div>
          <div class="form-row">
            <div class="col-md-3">
              <div><label for="saksi1">Saksi 1</label>
                 <b-form-input :formatter="isParent.$parent.normalText" v-model="isParent.row.aranres_kn_saksi1"
                  type="text" class="form-control" placeholder="Saksi 1" />
              </div>
            </div>
            <div class="col-md-3">
              <div><label for="saksi2">Saksi 2</label>
                 <b-form-input :formatter="isParent.$parent.normalText" v-model="isParent.row.aranres_kn_saksi2"
                  type="text" class="form-control" placeholder="Saksi 2" />
              </div>
            </div>
            <div class="col-md-3">
              <div><label for="saksi3">Saksi 3</label>
                 <b-form-input :formatter="isParent.$parent.normalText" v-model="isParent.row.aranres_kn_saksi3"
                  type="text" class="form-control" placeholder="Saksi 3" />
              </div>
            </div>
            <div class="col-md-3">
              <div><label for="saksi4">Saksi 4</label>
                 <b-form-input :formatter="isParent.$parent.normalText" v-model="isParent.row.aranres_kn_saksi4"
                  type="text" class="form-control" placeholder="Saksi 4" />
              </div>
            </div>
          </div>
        </div>
        <div class="alert alert-info mb-0">
          <div class="d-flex">
            <i class="icon-info22"></i>
            <span class="ml-1">Dokumen memerlukan materai, sehingga hanya bisa ditandatangani manual</span>
          </div>
        </div>
      </template>
    </div>
    
    <div class="wrap_line">
      <h3>Pernyataan Kronologi Kejadian</h3>

      <div class="row gx-4">
        <b-col lg="6">
          <b-form-group>
            <b-form-radio-group :options="Config.mr.yesNoOpt" v-model="isParent.row.aranres_kron_is" />

            <VValidate name="Pernyataan Kronologi Kejadian" v-model="isParent.row.aranres_kron_is"
              :rules="toValidate(isParent.mrValidation.aranres_kron_is)" />
          </b-form-group>
        </b-col>
      </div>

      <template v-if="isParent.row.aranres_kron_is == 'Y'">
        <div class="mb-2">
          <div class="text-blue-800 font-weight-semibold mb-2">Informasi Pembuat Pernyataan</div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="pembuatPernyataanNama">Nama <strong class="text-danger">*</strong></label>
                <b-form-input :formatter="$parent.normalText" placeholder="Nama" type="text" class="form-control" v-model="isParent.row.aranres_kron_nama"/>
                <VValidate 
                  name="Nama Pembuatan Pernyataan" 
                  v-model="isParent.row.aranres_kron_nama" 
                  :rules="toValidate(isParent.mrValidation.aranres_kron_nama)"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="pembuatPernyataanTel">No. Telp <strong class="text-danger">*</strong></label>
                <div class="input-group">
                  <div class="input-group-prepend"><span class="input-group-text">+62</span></div>
                  <b-form-input :formatter="isParent.$parent.numberOnly" v-model="isParent.row.aranres_kron_no_telp"
                    type="text" class="form-control" placeholder="No. Telp" />
                </div>
                <VValidate name="No. Telp Pembuat Pernyataan" v-model="isParent.row.aranres_kron_no_telp"
                  :rules="toValidate(isParent.mrValidation.aranres_kron_no_telp)" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="pembuatPernyataanAlamat">Alamat <strong class="text-danger">*</strong></label>
                <b-textarea name="pembuatPernyataanAlamat" v-model="isParent.row.aranres_kron_alamat" id="pembuatPernyataanAlamat" rows="3"
                  class="form-control"></b-textarea>
                <VValidate name="Alamat Pernyataan" v-model="isParent.row.aranres_kron_alamat"
                  :rules="toValidate(isParent.mrValidation.aranres_kron_alamat)" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="text-blue-800 font-weight-semibold mb-2">Informasi Kronologi</div>
          <div class="row">
            <div class="col-md-8">
              <div class="form-group">
                <label for="mengalamiKejadian">Mengalami <strong class="text-danger">*</strong></label>
                <b-form-input :formatter="$parent.normalText" placeholder="Mengalami" type="text" class="form-control" v-model="isParent.row.aranres_kron_mengalami"/>
                <VValidate 
                  name="Mengalami" 
                  v-model="isParent.row.aranres_kron_mengalami" 
                  :rules="toValidate(isParent.mrValidation.aranres_kron_mengalami)"
                />
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group">
                <label for="tempatKejadian">Tempat Kejadian <strong class="text-danger">*</strong></label>
                <b-form-input :formatter="$parent.normalText" placeholder="Tempat Kejadian" type="text" class="form-control" v-model="isParent.row.aranres_kron_tempat_kejadian"/>
                <VValidate 
                  name="Mengalami" 
                  v-model="isParent.row.aranres_kron_tempat_kejadian" 
                  :rules="toValidate(isParent.mrValidation.aranres_kron_tempat_kejadian)"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="waktuKejadian">Tanggal Kejadian <strong class="text-danger">*</strong></label>
                
                <div class="input-group mb-3">
                  <datepicker input-class="form-control transparent"
                    placeholder="Pilih Tanggal" class="my-datepicker"
                    calendar-class="my-datepicker_calendar" v-model="isParent.row.aranres_kron_tgl_kejadian">
                  </datepicker>
                  <div class="input-group-append calendar-group">
                    <span class="input-group-text" id="basic-addon2"><i
                        class="icon-calendar"></i></span>
                  </div>
                </div>
                <VValidate name="Tanggal Kejadian" v-model="isParent.row.aranres_kron_tgl_kejadian"
                  :rules="toValidate(isParent.mrValidation.aranres_kron_tgl_kejadian)" />

              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="waktuKejadian">Waktu Kejadian <strong class="text-danger">*</strong></label>
                <div class="input-group">
                  <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                  <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="isParent.row.aranres_kron_waktu_kejadian" >
                  </vue-timepicker>
                </div>
                <VValidate 
                  name="Waktu Kejadian" 
                  v-model="isParent.row.aranres_kron_waktu_kejadian" 
                  :rules="toValidate(isParent.mrValidation.aranres_kron_waktu_kejadian)"
                />
              </div>
            </div>
            <div class="col-md-8">
              <div class="form-group">
                <label for="kronologiKejadian">Kronologi Kejadian <strong class="text-danger">*</strong></label>
                
                <b-textarea name="pembuatPernyataanAlamat" v-model="isParent.row.aranres_kron_kejadian" id="pembuatPernyataanAlamat" rows="3"
                  class="form-control"></b-textarea>
                <VValidate name="Kronologi Kejadian" v-model="isParent.row.aranres_kron_kejadian"
                  :rules="toValidate(isParent.mrValidation.aranres_kron_kejadian)" />
              </div>
            </div>
          </div>
        </div>
        <div class="alert alert-info mb-0">
          <div class="d-flex">
            <i class="icon-info22"></i>
            <span class="ml-1">Dokumen memerlukan materai, sehingga hanya bisa ditandatangani manual</span>
          </div>
        </div>
      </template>
    </div>

    <b-modal v-model="modalPertanggungJawaban" :title="'Tanda tangani Dokumen'" size="xl" hide-footer>
      <validation-observer ref="VFormTTDPertanggungJawaban">
        <b-form @submit.prevent="doSaveTTDPertanggungJawaban">            
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                <div class="signing-element mt-1" data-target-id="ttdPembuatPernyataan">
                    <VueSignaturePad
                        class="sign-container"
                        width="100%"
                        height="160px"
                        ref="ttdPembuatPernyataan"
                    />
                    <div class="mt-2">
                    <button type="button" @click="undoTTD('ttdPembuatPernyataan','aranres_pj_ttd')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                        <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                    </button>
                    
                    <button type="button" @click="output('ttdPembuatPernyataan','aranres_pj_ttd')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                    <span>Simpan</span>
                    </button>
                    </div>
                    
                    <VValidate name="Tanda Tangan Pembuat Pernyataan" v-model="isParent.row.aranres_pj_ttd"
                    :rules="{required:1}" />
                </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="document-framer">
                <div class="df-content" v-html="isParent.row.aranres_pj_template">
                </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12">
                <div class="text-right">
                <button type="button" class="btn" @click="modalPersetujuanUmum = false" data-dismiss="modal">Batal</button>
                <button type="submit" class="btn btn-primary">Simpan <i class="icon-paperplane ml-2"></i></button>
                </div>
            </div>
        </div>
        </b-form>
    </validation-observer>
    </b-modal>
  </div>
</template>

<script>
  import Config from '@/libs/Config'
  import Gen from '@/libs/Gen.js'
  const _ = global._
  const moment = require('moment')
  import Datepicker from 'vuejs-datepicker'  
  import VueTimepicker from 'vue2-timepicker'
  import 'vue2-timepicker/dist/VueTimepicker.css'
  import $ from 'jquery'

  export default {
    components: {
      Datepicker,VueTimepicker
    },
    computed: {
      Config() {
        return Config
      },
      isParent() {
        return this.$parent.$parent.$parent.$parent
      },
      isNaik() {
        return true
      }
    },
    data() {
      return {
        modalPertanggungJawaban: false,
        // modalBelumMenikah: false,
        // modalPernikahan: false,
        // modalKronologi: false,
      }
    },
    methods: {
      
      openPertanggungJawaban(){
        this.modalPertanggungJawaban = true            
        setTimeout(()=>{
            $(".sign-container canvas").attr("width",400)
            $(".sign-container canvas").attr("height",160)

            if(this.$refs['ttdPembuatPernyataan']){
              this.$refs['ttdPembuatPernyataan'].fromDataURL(this.isParent.row.aranres_pj_ttd)
            } 

            this.isParent.autoSave(this.isParent.row,true)
        },200)
      },
      getConfigDynamic(master, value, val = 'text') {
        let text = ''
        if (value) {
          let index = (master || []).findIndex(x => x.value == value)
          if (index !== -1) {
            text = master[index][val]
          }
        }
        return text
      },
      toValidate(val) {
        return {
          ...val
        }
      },
      assetLocal(img){
        let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
        img = def+img
        return img
      },
      undoTTD(refs,field){
        this.$refs[refs].clearSignature()
        this.isParent.row[field] = null
        setTimeout(()=>{
          this.isParent.autoSave(this.isParent.row,true)
        },100)
      },
      output(refs,field) {
        let { isEmpty, data } = this.$refs[refs].saveSignature()
        if(!isEmpty){
          this.isParent.row[field] = data
          setTimeout(()=>{
            this.isParent.autoSave(this.isParent.row,true)
          },100)
        }
      },
      doSaveTTDPertanggungJawaban(){
        this.$refs['VFormTTDPertanggungJawaban'].validate().then(success => {
            if(!success){
                return this.$swal({
                    icon: 'error',
                    title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                })
            }else{
                this.isParent.autoSave(this.isParent.row,true)
                this.modalPertanggungJawaban = false
            }
        })
      }
    }
  }
</script>