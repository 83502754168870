<template>
    <fieldset>
        <h6 class="font-weight-semibold">Data Rawat Inap</h6>
        <div class="wrap_line_heading">
        <div class="data_prev_cont">
            
            <div class="form-row">
                <div class="form-group col-md-8">
                    <div v-if="canEditJenis" class="alert alert-primary">
                        <h6 class="font-weight-semibold">Terdapat Permintaan Perubahan Pindah Ruangan</h6>
                        <p class="font-weight-semibold">Jenis Rawat Inap : {{getConfigDynamic(mJenisRawatInap, rowPindah.aranpr_jenis_ranap)||"-"}}</p>
                        <p class="font-weight-semibold">Bangsal  : {{getConfigDynamic(isParent.mRanapBangsal,rowPindah.aranpr_bangsal)||"-"}}</p> 
                        
                        <p class="font-weight-semibold">Kelas  : {{rowPindah.kelas||"-"}}</p> 
                    </div>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-md-4">
                    <label>DPJP Utama <small class="txt_mandatory">*</small></label>
                    <v-select placeholder="Pilih DPJP Utama" v-model="isParent.row.aranres_dpjp_utama"
                    :options="isParent.mDPJPUtama" label="text" :clearable="true"
                    :reduce="v=>v.value">
                    </v-select>
                    
                    <VValidate 
                        name="DPJP Utama" 
                        v-model="isParent.row.aranres_dpjp_utama" 
                        :rules="toValidate(isParent.mrValidation.aranres_dpjp_utama)"
                    />
                </div>
                <div class="form-group col-md-4">
                    <label>Dokter Rawat Bersama </label>
                    <v-select placeholder="Pilih Dokter Rawat Bersama" v-model="isParent.row.aranres_dpjp_raber"
                    :options="isParent.mDPJPRaber" label="text" :clearable="true"
                    :reduce="v=>v.value">
                    </v-select>
                </div>
            </div>

            <div class="row">
            <div class="col-md-9">
                <div class="form-row">
                <div class="form-group col-md-4">
                    <label>Jenis Rawat Inap <small class="txt_mandatory">*</small></label>
                    <v-select :disabled="((isParent.row.aranr_ppjp && !canEditJenis)?true:false) || (isParent.row.aranres_cara_masuk=='BBL' && !canEditJenis)" placeholder="Pilih Jenis Rawat Inap" @input="getBangsalInfo()" v-model="isParent.row.aranres_jenis_ranap"
                    :options="isParent.row.aranres_cara_masuk!='BBL'?isParent.mJenisRanapNormal :isParent.mJenisRanap" label="text" :clearable="true"
                    :reduce="v=>v.value">
                    </v-select>
                    
                    <VValidate 
                        name="Jenis Rawat Inap" 
                        v-model="isParent.row.aranres_jenis_ranap" 
                        :rules="toValidate(isParent.mrValidation.aranres_jenis_ranap)"
                    />
                </div>
                <div class="form-group col-md-4">
                    <label>Unit Pelayanan<small class="txt_mandatory">*</small></label>
                    
                    <v-select placeholder="Pilih Unit Pelayanan" @input="getBangsalInfo()" v-model="isParent.row.aranres_unit_layanan"
                    :options="isParent.mUnitLayan" label="text" :clearable="true"
                    :reduce="v=>v.value">
                    </v-select>
                    
                    <VValidate 
                        name="Unit Pelayanan" 
                        v-model="isParent.row.aranres_unit_layanan" 
                        :rules="toValidate(isParent.mrValidation.aranres_unit_layanan)"
                    />

                </div>

                <div class="form-group col-md-4">
                    <label>Bangsal<small class="txt_mandatory">*</small></label>
                    
                    <v-select :disabled="isParent.row.aranres_bed_is_same == 'Y'" placeholder="Pilih Bangsal" @input="getBangsalInfo()" v-model="isParent.row.aranres_bangsal"
                    :options="isParent.mRanapBangsal" label="text" :clearable="true"
                    :reduce="v=>v.value">
                    </v-select>
                    
                    <VValidate 
                        name="Bangsal" 
                        v-model="isParent.row.aranres_bangsal" 
                        :rules="toValidate(isParent.mrValidation.aranres_bangsal)"
                    />
                </div>
                </div>

                <div class="table-responsive mb-2">
                <table class="table table-bordered mb-0">
                    <thead>
                    <tr >
                        <th class="bg-success">Kamar</th>
                        <th class="bg-success">Kelas</th>
                        <th class="bg-success">Nama Bed</th>
                        <th class="bg-success">Tarif (Rp)</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="isParent.row.aranres_bed">
                        <td>{{isParent.row.mrank_name||"-"}}</td>
                        <td>{{isParent.row.mrankel_name||"-"}}</td>
                        <td>{{isParent.row.mranbed_name||"-"}}</td>
                        <td>
                            <span v-if="isParent.row.aranres_tarif">
                                {{isParent.$parent.toMoneyValue(isParent.row.aranres_tarif)||"-"}}
                            </span>
                            <span v-else> - </span>
                        </td>
                    </tr>
                    <tr v-else>
                        <td colspan="4" class="text-center">Belum ada Kamar Dipilih</td>
                    </tr>
                    <tr v-if="isParent.isShowBangsal && isParent.row.aranres_bed_is_same != 'Y'">
                        <td colspan="4" class="text-center">
                        <a href="javascript:;" @click="isShowKatalog = true" data-toggle="modal"
                            class="btn btn-labeled btn-labeled-left bg-warning-400">
                            <b><i class="icon-bed2"></i></b>
                            Pilih Ruangan
                        </a>
                        </td>
                    </tr>
                    </tbody>
                </table>

                
                <div class="mt-1" v-if="isParent.row.aranres_cara_masuk == 'BBL'">
                    <b-form-checkbox v-model="isParent.row.aranres_bed_is_same"
                    value="Y"
                    unchecked-value="N"  
                    @input="checkIsSame($event)" class="form-check-input-styled" 
                    name="bbl">
                    Ruangan Pasien Sama Dengan Ruang Ibu
                    </b-form-checkbox>
                </div>

                </div>
            </div>
            <div class="col-md-3" v-if="isParent.isShowBangsal">
                <div class="alert alert-info">
                <p><strong>Informasi Kuota Bangsal:</strong></p>
                <ul class="pl-3">
                    <li v-for="(v,k) in (isParent.infoBangsal||[])" :key="k">{{v.text}}: 
                    <span v-if="v.jumlah_available">tersisa {{v.jumlah_available}}</span>
                    <span v-else> <span class="font-weight-semibold">Kosong</span> </span>
                    </li>
                </ul>
                </div>
            </div>

            </div>
        </div>
        </div>

        <b-modal v-model="isShowKatalog" :title="'Katalog Bangsal - '+getConfigDynamic(isParent.mRanapBangsal,isParent.row.aranres_bangsal)" size="lg" @ok.prevent="submitKatalog">
            <div class="row mb-2">
                <div class="col-md-4">
                    <v-select placeholder="Pilih Kelas Rawat Inap" @input="changeFilterKatalog($event)" v-model="filterKatalog"
                    :options="isParent.infoBangsal" label="text" :clearable="true"
                    :reduce="v=>v.value">
                    </v-select>
                </div>
            </div>
            
            <div class="row">
                <div class="col-md-12">
                    <div class="table-responsive mb-2">
                    <table class="table table-sm table-bordered mb-0">
                        <thead>
                        <tr>
                            <th class="bg-success">Aksi</th>
                            <th class="bg-success">Kode </th>
                            <th class="bg-success">Kamar </th>
                            <th class="bg-success">Kelas</th>
                            <th class="bg-success">Nama Bed</th>
                            <th class="bg-success">Tarif (Rp)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(v,k) in (isParent.katalogBangsal||[])" :key="k" :class="filterKatalog ? (filterKatalog == v.mrankel_id ? '' : 'd-none') : ''">
                            <td>
                                <p v-if="v.mranbed_is_used == 'Y' && isParent.row.aranres_bed_old != v.mranbed_id" class="text-danger text-center mb-0">Sudah Terisi</p>
                                <template v-else>
                                    <a href="javascript:;" v-if="v.selected" class="btn btn-labeled btn-labeled-left bg-warning-400 disabled">
                                        <b><i class="icon-check"></i></b> Dipilih
                                    </a>
                                    <a href="javascript:;" v-else @click="selectItem(v,k)" class="btn btn-labeled btn-labeled-left bg-warning-400">
                                        <b><i class="icon-check"></i></b> Pilih
                                    </a>
                                </template>
                            </td>
                            <td>{{v.mrank_code||"-"}}</td>
                            <td>{{v.mrank_name||"-"}}</td>
                            <td>{{v.mrankel_name||"-"}}</td>
                            <td>{{v.mranbed_name||"-"}}</td>
                            <td>Rp. {{isParent.$parent.toMoneyValue(v.mrank_tarif)}}</td>
                        </tr>
                        <tr v-if="!(isParent.katalogBangsal||[]).length || !isShowData">
                            <td colspan="99" class="text-center">Tidak ada data</td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </b-modal>

    </fieldset>
</template>


<script>
import Config from '@/libs/Config'
import Gen from '@/libs/Gen.js'
const _ = global._
const moment = require('moment')


export default {
    computed: {    
        Config(){ return Config },
        isParent(){
            return this.$parent.$parent.$parent.$parent
        },
        isShowData(){
            let total = 0
            if(!this.filterKatalog){
                total = 1
            }
            for(let i = 0; i < (this.isParent.katalogBangsal||[]).length; i++){
                if(this.filterKatalog == this.isParent.katalogBangsal[i].mrankel_id){
                    total += 1
                }            
            }
            return total
        },
        canEditJenis(){
            return (this.isParent.row.aranr_is_pindah_ruang == 'Y' && this.isParent.row.aranr_is_pindah_by == 'ADMISI' && this.isParent.$parent.user.levelId == 18) || 
            (this.isParent.row.aranr_is_pindah_ruang == 'Y' && this.isParent.row.aranr_is_pindah_by == 'ADMINRUANG' && this.isParent.$parent.user.levelId == 32)  
            || (this.isParent.row.aranr_is_pindah_ruang == 'Y' && this.isParent.$parent.user.levelId == 1)  
        }
    }, 
    mounted() {
        this.getPindahRuang()
    },
    data() {
        return {
            isShowKatalog: false,
            filterKatalog: null,
            rowPindah: {},
            mJenisRawatInap: [
                {
                "text": "Obsgyn",
                "value": 1
                },
                {
                "text": "Umum",
                "value": 3
                },
                {
                "text": "Perawatan Kritis",
                "value": 4
                }
            ],
        }
    },
    methods: {
        getPindahRuang(){
            if(this.isParent.row.aranr_id){
                let data = {
                    id: this.isParent.row.aranr_id,
                    type: "get-data-pindah-ruang"
                }
                Gen.apiRest(
                    "/do/"+this.isParent.$parent.modulePage, 
                    {
                        data: data
                    }, "POST"
                ).then(res=>{
                    this.rowPindah = res.data.row  
                })
            }
        },
        selectItem(v,k){
            this.isParent.row.aranres_bed = null
            this.isParent.row.aranres_kamar = null
            this.isParent.row.aranres_kelas = null
            this.isParent.row.aranres_tarif = null

            for(let i = 0; i < (this.isParent.katalogBangsal||[]).length; i++){
                this.isParent.katalogBangsal[i].selected = false
            }

            this.isParent.row.aranres_bed = v.mranbed_id
            this.isParent.row.aranres_kamar = v.mrank_id
            this.isParent.row.aranres_kelas = v.mrankel_id
            this.isParent.row.aranres_tarif = v.mrank_tarif


            this.isParent.katalogBangsal[k].selected = true
        },
        changeFilterKatalog(e){
            if(e){
                let total = 0
                for(let i = 0; i < (this.isParent.katalogBangsal||[]).length; i++){
                    if(this.filterKatalog == this.isParent.katalogBangsal[i].mrankel_id){
                        total += 1
                    }            
                }
            }
        },
        submitKatalog(){
            let data = {
                type : 'selected-ruang-ranap',
                id: this.isParent.row.aranres_id,
                aranres_bed : this.isParent.row.aranres_bed,
                aranres_bed_old : this.isParent.row.aranres_bed_old
            }
            this.isParent.$parent.loadingOverlay = true
            Gen.apiRest(
                "/do/"+this.isParent.$parent.modulePage, 
                {
                    data: data
                }, "POST"
            ).then(res=>{
                this.isParent.row.aranres_bed_is_same = "N"
                
                this.isParent.autoSave(this.isParent.row,true)

                setTimeout(()=>{
                    this.isShowKatalog = false    
                    this.isParent.$parent.apiGet()
                    this.isParent.$parent.loadingOverlay = false
                },2000)
            })


        },
        toValidate(val){
            return {...val}
        },
        getBangsalInfo(){
            this.isParent.row.aranres_bed = null                
            this.isParent.isShowBangsal = false 

            if(this.isParent.row.aranres_bangsal && this.isParent.row.aranres_unit_layanan && this.isParent.row.aranres_jenis_ranap){
                this.isParent.isShowBangsal = true   
                
                let data = {
                    type : 'select-ruang-ranap',
                    aranres_bangsal : this.isParent.row.aranres_bangsal,
                    aranres_unit_layanan : this.isParent.row.aranres_unit_layanan,
                    aranres_jenis_ranap : this.isParent.row.aranres_jenis_ranap
                }

                this.isParent.$parent.loadingOverlay = true
                Gen.apiRest(
                    "/do/"+this.isParent.$parent.modulePage, 
                    {
                        data: data
                    }, "POST"
                ).then(res=>{
                    this.isParent.isShowBangsal = res.data.isShowBangsal,
                    this.isParent.infoBangsal = res.data.infoBangsal,
                    this.isParent.katalogBangsal = res.data.katalogBangsal

                    this.isParent.$parent.loadingOverlay = false
                })

            }
        },
        getConfigDynamic(master, value, val = 'text') {
            let text = ''
            if (value) {
                let index = (master||[]).findIndex(x => x.value == value)
                if (index !== -1) {
                text = master[index][val]
                }
            }
            return text
        },
        checkIsSame(e){
            
            if(e == 'Y'){
                let data = {
                    type : 'auto-fill-ruang-ranap-ibu',
                    id: this.isParent.row.aranres_aranr_ref_id,
                    id_res: this.isParent.row.aranres_id
                }

                this.isParent.$parent.loadingOverlay = true
                Gen.apiRest(
                    "/do/"+this.isParent.$parent.modulePage, 
                    {
                        data: data
                    }, "POST"
                ).then(res=>{
                    let resp = res.data
                    this.isParent.row.aranres_bangsal = resp.aranres_bangsal
                    this.isParent.row.aranres_kamar = resp.aranres_kamar
                    this.isParent.row.aranres_kelas = resp.aranres_kelas
                    this.isParent.row.aranres_bed = resp.aranres_bed
                    this.isParent.row.aranres_tarif = resp.aranres_tarif
                    
                    setTimeout(()=>{
                        this.isShowKatalog = false    
                        this.isParent.$parent.apiGet()
                        this.isParent.$parent.loadingOverlay = false
                    },1500)

                    // this.isParent.autoSave(this.isParent.row,true)
                })   
            }
        }
    },
}

</script>